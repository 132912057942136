<template>
  <div>
    <v-breadcrumbs
      class="breadcrumbsmain"
      :items="itemsbr"
      divider=">"
      large
    ></v-breadcrumbs>

    <v-data-table
      :headers="headers"
      :items="dataobject"
      :search="search"
      class="rounded elevation-6 mx-3 pa-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">Edit Item</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="4" md="4">
                          <div>
                            <h5>Kode Buku</h5>
                            <h2 class="ml-2">{{ defaultItem.codeBuku }}</h2>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="8" md="8">
                          <div>
                            <h5>Nama Buku</h5>
                            <h2 class="ml-2">{{ defaultItem.judulBuku }}</h2>
                          </div>
                        </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="defaultItem.hpp"
                        label="Masukkan HPP"
                        type="number"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="defaultItem.stockSolo"
                        label="Jumlah Stock Solo"
                        type="number"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="defaultItem.stockJakarta"
                        label="Jumlah Stock Jakarta"
                        type="number"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" @click="editItem(item)" color="warning">
          mdi-pencil
        </v-icon>
        <!-- <v-icon @click="deleteItem(item)" color="red"> mdi-delete </v-icon> -->
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PenyesuaianPersediaan",
  data: () => ({
    itemsbr: [
      {
        text: "Persediaan",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Penyesuaian Persediaan",
        disabled: true,
        href: "breadcrumbs_link_1",
      },
    ],
    dialog: false,

    search: "",
    authtoken: "",
    BaseUrlGet: "",
    headers: [
      {
        text: "Kode Buku",
        value: "codeBuku",
      },
      { text: "Nama Buku", value: "judulBuku" },
      
      { text: "Hpp", value: "hpp" },
      { text: "Stock Solo", value: "stockSolo" },
      { text: "Stock Jakarta", value: "stockJakarta" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    dataobject: [],

    defaultItem: { 
        codeBuku: "", 
        judulBuku: "",
         hpp: "", 
         stockSolo: "", 
         stockJakarta: "" 
    },

    username:"",

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.username = this.User.username;
    }
    this.initialize();
  },

  methods: {
    async initialize() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetPenyesuaianPersediaan?limit=all",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataobject = [];
        }
      }
    },

    async updateData() {
      const datapost = {
        codeBuku: this.defaultItem.codeBuku,
        hpp: this.defaultItem.hpp,
        stockSolo: this.defaultItem.stockSolo,
        stockJakarta: this.defaultItem.stockJakarta,
        username: this.username,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "UpdatePenyesuaian",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses ubah data";
          this.initialize();
        } else {
          this.dialog = false;
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          this.dialog = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },

    editItem(item){
        this.defaultItem = Object.assign({}, item);
        this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    save() {
      if (this.defaultItem.hpp.length != 0 && this.defaultItem.stockSolo.length != 0 && this.defaultItem.stockJakarta.length != 0) {
        console.log("edit");
        this.updateData();
        
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Gagal Menambah, Kolom tidak boleh ada yang kosong";
      }
    }

  },
};
</script>