<template>
  <div>
    <v-breadcrumbs
      class="breadcrumbsmain fontall"
      :items="itemsbr"
      large
      color="black"
      style="font-weight: bold"
      ><template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template></v-breadcrumbs
    >

    <v-data-table
      :headers="headers"
      :items="dataobject"
      :search="search"
      class="rounded elevation-6 mx-3 pa-1 fontall"
    >
      <template v-slot:item.typeTransaksi="{ item }">
        <v-chip :color="getColorStatus(item.typeTransaksi)" dark>
          {{ item.typeTransaksi }}
        </v-chip>
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip
          outlined
          :color="getColorStatusKonfirmasiAdmin(item.code_status)"
          dark
        >
          <v-icon
            medium
            class="mr-1"
            :color="getColorStatusKonfirmasiAdmin(item.code_status)"
          >
            {{ getIconStatusAdmin(item.code_status) }}
          </v-icon>
          {{ getStatusKonfirmasiAdmin(item.code_status) }}
        </v-chip>
        <!-- <tr>
          <td>
            <v-icon medium class="mr-1" color="primary">
              {{ getIconStatus(item.code_status) }}
            </v-icon>
          </td>
          <td>
            <h4>
              {{ item.status }}
            </h4>
          </td>
        </tr> -->
      </template>

      <template v-slot:item.updated_at="{ item }">
        <tr>
          {{
            gettanggal(item.updated_at)
          }}
        </tr>
        <tr>
          {{
            getjam(item.updated_at)
          }}
        </tr>
      </template>

      <template v-slot:item.total="{ item }">
        {{ getRupiah(item.total) }}
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <!-- Modal Detail -->
          <v-dialog v-model="dialogDetail" max-width="800px">
            <v-card>
              <v-card-title class="mb-1 headermodalstyle">
                <span>Detail Transaksi</span>
              </v-card-title>

              <v-card-text class="fontall pa-1">
                <v-container class="pa-1">
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 150px; font-size: 14px"
                          >
                            No Invoice
                          </th>
                          <td class="text-left" style="font-size: 14px">
                            {{ defaultItem.NoInvoice }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Customer
                          </th>
                          <td class="text-left" style="font-size: 14px">
                            {{ defaultItem.Customer }} ({{
                              defaultItem.TelpCust
                            }})
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Data Pengirim
                          </th>
                          <td class="text-left" style="font-size: 14px">
                            {{ defaultItem.Pengirim }} ({{
                              defaultItem.NoPengirim
                            }})
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Data Tujuan
                          </th>
                          <td class="text-left" style="font-size: 14px">
                            {{ defaultItem.NamaPenerima }} ({{
                              defaultItem.NoPenerima
                            }})
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Alamat Tujuan
                          </th>
                          <td class="text-left" style="font-size: 14px">
                            <tr>
                              {{
                                defaultItem.Alamat
                              }}
                            </tr>
                            <tr>
                              Kec.
                              {{
                                defaultItem.kecamatan
                              }}, Kab.
                              {{
                                defaultItem.kab_kota
                              }},
                              {{
                                defaultItem.propinsi
                              }}
                            </tr>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <v-data-table
                    :headers="headersBarang"
                    :items="defaultItem.listOrder"
                    :search="search"
                    class="rounded elevation-1 mx-1 pa-1 my-2"
                    hide-default-footer
                    disable-pagination
                  >
                    <template v-slot:item.namaBarang="{ item }">
                      <tr>
                        {{
                          item.namaBarang
                        }}
                      </tr>
                      <tr>
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody
                              v-for="item in item.listbuku"
                              :key="item.judul_buku"
                            >
                              <tr>
                                <td class="text-left" style="font-size: 14px">
                                  - {{ item.judul_buku }}
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </tr>
                    </template>
                    <template v-slot:item.subtotal="{ item }">
                      {{ getRupiah(item.subtotal) }}
                    </template>
                  </v-data-table>
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Sub Total
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ getRupiah(defaultItem.SubTotal) }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Diskon
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ getRupiah(defaultItem.Diskon) }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Diskon Kode Unik
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ getRupiah(defaultItem.DiskonKodeUnik) }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Expedisi ({{ defaultItem.Expedisi }})
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ getRupiah(defaultItem.BiayaExpedisi) }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Total Harga
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ getRupiah(defaultItem.TotalHarga) }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Type Transaksi
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.typeTransaksi }}
                          </th>
                        </tr>
                        <tr v-if="dateTempo == true">
                          <th class="text-left" style="font-size: 14px">
                            Jatuh Tempo
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.tanggal_tempo }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Bank
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.Bank }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Komentar
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.Komentar }}
                          </th>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-container>
                    <v-row justify="center">
                      <div v-if="selectedImage" max-width="85vw">
                        <img
                          :src="selectedImage"
                          alt=""
                          width="100%"
                          @click.stop="selectedImage = null"
                        />
                        <hr />
                      </div>
                      <v-col v-if="selectedImage == null" cols="12" md="12">
                        <v-card
                          class="d-flex align-center"
                          elevation="2"
                          height="450"
                          width="450"
                        >
                          <v-img
                            v-bind:src="defaultItem.Gambar"
                            class="my-2 mx-1 mb-4"
                            max-height="450"
                            contain
                            v-on:click="zoom(defaultItem.Gambar)"
                          ></v-img>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue" elevation="1" outlined @click="print">
                  <v-icon left> mdi-printer-check </v-icon>
                  Cetak Faktur
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Modal Preview -->
          <v-dialog v-model="dialogPreview" min-width="800px" max-width="900px">
            <v-card min-width="800px" max-width="900px">
              <v-card-title class="headermodalstyle">
                <span>Preview Invoie</span>
              </v-card-title>
              <div class="text-center fontprint" style="">
                <h1 id="cap1" class="h1 d-none">INVOICE AQWAM</h1>
                <div
                  id="cap2"
                  class="container mb-5 d-none"
                  style="width: 100%"
                >
                  <input
                    type="button"
                    class="btn btn-success float-right mx-2"
                    onclick="printDiv('capture')"
                    value="Cetak Invoice PDF"
                  />
                  <button
                    id="download"
                    class="btn btn-success float-right mx-2"
                  >
                    Cetak Invoice PNG
                  </button>
                  <br style="clear: both" />
                  <br style="clear: both" />
                  <br style="clear: both" />
                </div>
                <div
                  class="container-fluid pa-2 mt-5 mr-2"
                  style="overflow: auto"
                >
                  <div style="width: 100%" id="canvas" />
                </div>
              </div>
              <div style="overflow: auto" class="mr-2">
                <div
                  id="capture"
                  class="pa-2 ma-2"
                  style="transform: scale(0.85)"
                >
                  <div class="printtable mr-2" style="padding: 1px !important">
                    <table
                      style="width: 100% !important"
                      class="fontprint mb-1"
                    >
                      <tr>
                        <!-- nama store -->
                        <td rowspan="3">
                          <img
                            src="/images/aqwamhitam.png"
                            class="mr-auto"
                            style="margin-left: 10px !important; width: 150px"
                          />
                        </td>
                        <!-- Tanggal -->
                        <td>
                          <p style="margin: 5px 0px 2px"><b>Tanggal</b></p>
                          <p style="margin-bottom: 10px">
                            {{ defaultItem.Tanggal }}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <!-- No Invoice -->
                        <td>
                          <p style="margin: 1px 0px 2px">
                            <b>Nomor Invoice</b>
                          </p>
                          <p style="margin-bottom: 10px">
                            {{ defaultItem.NoInvoice }}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <!-- nama admin -->
                          <p style="margin: 1px 0px 2px"><b>Nama Admin</b></p>
                          <p style="margin-bottom: 10px">
                            {{ defaultItem.Admin }}
                          </p>
                        </td>
                      </tr>
                      <tr class="fontprint">
                        <td>
                          <p style="margin: 1px 0px 1px 10px !important">
                            Assalamu'alaikum Warahmatullahi Wabarakatuh
                          </p>
                          <!-- nama pembeli -->
                          <p style="margin: 1px 0px 1px 10px !important">
                            <b>Kepada {{ defaultItem.Customer }}</b>
                          </p>
                          <!-- Terimakasih -->
                          <p style="margin: 1px 0px 1px 10px !important">
                            Terimakasih sudah berbelanja di AQWAM STORE, Berikut
                            rincian dari orderan anda :
                          </p>
                        </td>
                        <!-- keterangan bayar -->
                        <td><h3 style="color: green">LUNAS</h3></td>
                      </tr>
                    </table>
                    <table
                      class="table margininvoicebot2"
                      style="
                        margin: 5px 0px 0px 0px !important;
                        padding: 0px 1px 0px 2px !important;
                      "
                    >
                      <tr>
                        <th class="font-weight-bold">Nama Produk</th>
                        <th class="font-weight-bold text-center">Jumlah</th>
                        <th class="font-weight-bold text-center">Berat</th>
                        <th class="font-weight-bold text-center">Harga</th>
                        <th class="font-weight-bold text-center">Diskon</th>
                        <th class="font-weight-bold text-center">SubTotal</th>
                      </tr>
                      <!-- <tr>
                        <td>Fikih Empat Mazhab Jilid II</td>
                        <td class="text-center">1</td>
                        <td class="text-center">1kg</td>
                        <td class="text-right">Rp 179.000</td>
                        <td class="text-center">0%</td>
                        <td class="text-right">Rp 179.000</td>
                      </tr> -->
                      <tr
                        v-for="vallist in defaultItem.listOrder"
                        :key="vallist.codeBarang"
                      >
                        <td>
                          <h4>
                            {{ vallist.namaBarang }}
                          </h4>
                          <li
                            v-for="item in vallist.listbuku"
                            :key="item.judul_buku"
                          >
                            {{ item.judul_buku }}
                          </li>
                        </td>
                        <td class="text-center">{{ vallist.jumlah }}</td>
                        <td class="text-center">{{ vallist.berat }} kg</td>
                        <td class="text-right">
                          {{ getRupiah(vallist.harga) }}
                        </td>
                        <td class="text-center">{{ vallist.diskon }}%</td>
                        <td class="text-right">
                          {{ getRupiah(vallist.subtotal) }}
                        </td>
                      </tr>
                      <tr class="border-top">
                        <td
                          class="font-weight-bold text-weight-bold"
                          colspan="2"
                        >
                          Jumlah
                        </td>
                        <td class="text-center"></td>
                        <td class="text-right"></td>
                        <td class="text-right"></td>
                        <td class="text-right">
                          {{ getRupiah(defaultItem.SubTotal) }}
                        </td>
                      </tr>
                      <!-- ekspedisi -->
                      <tr>
                        <td class="font-weight-bold" colspan="5">
                          Kurir - {{ defaultItem.Expedisi }}
                        </td>
                        <td class="text-right">
                          {{ getRupiah(defaultItem.BiayaExpedisi) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold" colspan="5">Diskon</td>
                        <td class="text-right">
                          {{ getRupiah(defaultItem.Diskon) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold" colspan="5">
                          Diskon Kode Unik
                        </td>
                        <td class="text-right">
                          {{ getRupiah(defaultItem.DiskonKodeUnik) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">TOTAL</td>
                        <td class="font-weight-bold"></td>
                        <td class="font-weight-bold"></td>
                        <td class="font-weight-bold"></td>
                        <td class="font-weight-bold"></td>
                        <td class="font-weight-bold text-right">
                          {{ getRupiah(defaultItem.TotalHarga) }}
                        </td>
                      </tr>
                    </table>
                    <hr class="border-top m-2" />
                    <table class="table table-sm-responsive table-borderless">
                      <tr>
                        <td
                          class="font-weight-bold margininvoicebot1"
                          style="width: 350px"
                        >
                          <p class="margininvoicebot1">Rekening Pembayaran</p>
                          <p class="margininvoicebot1">
                            - BCA 7850875230 an Ismail Huda
                          </p>
                          <p class="margininvoicebot1">
                            - BNI 1122644123 an Ismail Huda
                          </p>
                          <p class="margininvoicebot1">
                            - Mandiri 1380018169503 an Ismail Huda
                          </p>
                          <p class="margininvoicebot1">
                            - BRI 033401087104503 an Ismail Huda
                          </p>
                          <p>Semoga Allah Memberi Keberkahan</p>
                          <p
                            style="border: 2px solid red; text-align: justify"
                            class="notedprint h5 rounded"
                          >
                            <span class="font-weight-bolder">NOTE :</span> Untuk
                            mendukung kecepatan dan ketepatan pengiriman order,
                            harap transfer
                            <span class="font-weight-bolder"
                              >sesuai dengan nominal invoice</span
                            >, melalui
                            <span class="font-weight-bolder"
                              >satu rekening bank</span
                            >
                            yang dipilih.
                          </p>
                        </td>
                        <!-- </tr>
          <tr> -->
                        <td class="font-weight-bold" style="width: 120px">
                          Detail Pengiriman
                        </td>
                        <td>
                          <p class="font-weight-bold margininvoicebot1">
                            #Pengirim'
                          </p>
                          <p class="margininvoicebot2">
                            {{ defaultItem.NamaPenerima }} ({{
                              defaultItem.NoPengirim
                            }})
                          </p>
                          <p class="font-weight-bold margininvoicebot1">
                            #Tujuan '
                          </p>
                          <p class="margininvoicebot1">
                            {{ defaultItem.NamaPenerima }} ({{
                              defaultItem.NoPenerima
                            }})
                          </p>
                          <p class="margininvoicebot1">
                            {{ defaultItem.Alamat }}
                          </p>
                          <p class="margininvoicebot1">
                            Kec. {{ defaultItem.kecamatan }}, Kab.
                            {{ defaultItem.kab_kota }},
                            {{ defaultItem.propinsi }}
                          </p>
                          <!-- <p>{{ defaultItem.Alamat }}</p> -->
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <!-- </v-container> -->
              <v-card-actions>
                <v-btn
                  class="d-sm-none"
                  color="blue"
                  elevation="1"
                  outlined
                  @click="printPdf"
                >
                  <v-icon left> mdi-printer-check </v-icon>
                  Cetak PDF
                </v-btn>
                <v-btn
                  class="d-sm-none"
                  color="blue"
                  elevation="1"
                  outlined
                  @click="printImage"
                >
                  <v-icon left> mdi-printer-check </v-icon>
                  Cetak Image
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  class="d-none d-sm-block"
                  color="blue"
                  elevation="1"
                  outlined
                  @click="printPdf"
                >
                  <v-icon left> mdi-printer-check </v-icon>
                  Cetak PDF
                </v-btn>
                <v-btn
                  class="d-none d-sm-block"
                  color="blue"
                  elevation="1"
                  outlined
                  @click="printImage"
                >
                  <v-icon left> mdi-printer-check </v-icon>
                  Cetak Image
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Modal Trace -->
          <v-dialog v-model="dialogTrace" max-width="800px" class="pa-1">
            <v-card>
              <v-card-title class="mb-1 headermodalstyle">
                <span>Trace Order</span>
              </v-card-title>

              <v-card-text class="fontall pa-1">
                <v-container class="pa-1">
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 150px !important; font-size: 14px"
                          >
                            No Invoice
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.NoInvoice }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Ekspedisi
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.Kurir }} ({{ defaultItem.Service }})
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            No Resi
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.Resi }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Keterangan
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.keterangantrace }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <v-divider
                    style="border-color: black !important"
                    class="mx-1 mb-2"
                  ></v-divider>

                  <v-row v-if="manifestShow == true">
                    <v-col cols="12" sm="4" md="4" class="pa-1">
                      <v-card elevation="9" class="ma-2 pt-1">
                        <div
                          class="font-weight-bold mb-2"
                          style="text-align: center !important"
                        >
                          <h3 class="mt-2">Detail Kirim</h3>
                        </div>
                        <v-divider
                          style="border-color: black !important"
                          class="mx-1"
                        ></v-divider>
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr>
                                <th
                                  class="text-left"
                                  style="width: 90px; font-size: 14px"
                                >
                                  Tujuan
                                </th>
                                <th class="text-left" style="font-size: 14px">
                                  {{ defaultItem.receiver_name }}
                                </th>
                              </tr>
                              <tr>
                                <th class="text-left" style="font-size: 14px">
                                  Alamat
                                </th>
                                <th class="text-left" style="font-size: 14px">
                                  {{ defaultItem.destination }}
                                </th>
                              </tr>
                              <tr>
                                <th class="text-left" style="font-size: 14px">
                                  Tanggal Kirim
                                </th>
                                <th class="text-left" style="font-size: 14px">
                                  {{ defaultItem.waybill_date }}
                                </th>
                              </tr>
                              <tr>
                                <th class="text-left" style="font-size: 14px">
                                  Status
                                </th>
                                <th class="text-left" style="font-size: 14px">
                                  {{ defaultItem.statuskirim }}
                                </th>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="8" md="8" class="pa-1">
                      <v-card elevation="9" class="ma-2 pt-1">
                        <div
                          class="font-weight-bold mb-2"
                          style="text-align: center !important"
                        >
                          <h3 class="mt-2">Manifest</h3>
                        </div>
                        <v-divider
                          style="border-color: black !important"
                          class="mx-1"
                        ></v-divider>
                        <v-timeline dense class="px-2 pt-3">
                          <v-timeline-item
                            fill-dot
                            class="pb-3"
                            v-for="item in manifest"
                            :key="item.id"
                          >
                            <template v-slot:icon>
                              <v-avatar>
                                <v-icon color="white">
                                  mdi-truck-delivery
                                </v-icon>
                              </v-avatar>
                            </template>
                            <!-- <span slot="opposite">Tus eu perfecto</span> -->
                            <v-card class="elevation-2">
                              <v-card-title
                                style="bold !important; font-size:14px!important"
                                class="px-2 pt-2 pb-0"
                              >
                                {{ item.manifest_description }}
                              </v-card-title>
                              <v-card-text
                                style="font-size: 10px !important"
                                class="px-2 pb-2 pt-0"
                              >
                                {{ item.manifest_date }}
                                {{ item.manifest_time }}
                              </v-card-text>
                            </v-card>
                          </v-timeline-item>
                        </v-timeline>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-1" @click="detailItem(item)" color="primary">
          mdi-information
        </v-icon>
        <v-icon class="mr-1" @click="traceItem(item)" color="green">
          mdi-cube-send
        </v-icon>
        <!-- <v-icon class="mr-1" @click="sendEmail(item)" color="red">
          mdi-email-send
        </v-icon> -->
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
      class="fontall"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import html2canvas from "@/js/html2canvas";

export default {
  name: "History",
  data: () => ({
    itemsbr: [
      {
        text: "History",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "History",
        disabled: true,
        href: "breadcrumbs_link_1",
      },
    ],

    formTitle: "Add Item",
    textheader: "",
    value: "add",
    dialog: false,
    dialogDelete: false,
    dialogDetail: false,
    dialogTrace: false,
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    headers: [
      { text: "No Inv", value: "id", width: "13%" },
      { text: "Tipe Trx", value: "typeTransaksi" },
      { text: "Pembeli", value: "nama" },
      { text: "Penerima", value: "nama_penerima" },
      { text: "Status", value: "status" },
      { text: "Tgl Update", value: "updated_at", width: "13%" },
      //   { text: "User ID", value: "username" },
      { text: "Actions", value: "actions", sortable: false, width: "13%" },
    ],
    headersAll: [
      { text: "No Inv", value: "id", width: "13%" },
      { text: "Tipe Trx", value: "typeTransaksi" },
      { text: "Pembeli", value: "nama" },
      { text: "Penerima", value: "nama_penerima" },
      { text: "Status", value: "status" },
      { text: "Tgl Update", value: "updated_at", width: "13%" },
      //   { text: "User ID", value: "username" },
      { text: "Actions", value: "actions", sortable: false, width: "13%" },
    ],
    headersSales: [
      { text: "No Inv", value: "id", width: "13%" },
      { text: "Tipe Trx", value: "typeTransaksi" },
      { text: "Pembeli", value: "nama" },
      { text: "Penerima", value: "nama_penerima" },
      { text: "No Resi", value: "no_resi" },
      { text: "Uang", value: "total" },
      { text: "Tgl Update", value: "updated_at", width: "13%" },
      //   { text: "User ID", value: "username" },
      { text: "Actions", value: "actions", sortable: false, width: "10%" },
    ],
    headersBarang: [
      { text: "Nama Buku", value: "namaBarang" },
      { text: "Jumlah", value: "jumlah" },
      { text: "Diskon", value: "diskon" },
      { text: "Sub total", value: "subtotal", width: "25%" },
    ],
    headerListBuku: [
      { text: "Nama Buku", value: "namaBarang", width: "35%" },
      { text: "Berat", value: "berat", width: "12%" },
      { text: "Harga", value: "harga", width: "18%" },
      { text: "Diskon", value: "diskon", width: "12%" },
      { text: "Jumlah", value: "jumlah", width: "13%" },
      { text: "Actions", value: "actions", sortable: false, width: "10%" },
    ],
    dataobject: [],

    defaultItem: {
      id: "",
      nama: "",
      nama_penerima: "",

      gambar: "",

      Admin: "",
      Alamat: "",
      BiayaExpedisi: "",
      typeTransaksi: "",
      customerCombobox: "",
      kabROCombobox: "",
      id_alamatkabROCombobox: "",
      textkabROCombobox: "",
      kab_kota_ro_kode: "",
      Customer: "",
      diskonCustomer: "",
      Diskon: "",
      DiskonKodeUnik: "",
      Expedisi: "",
      NamaPenerima: "",
      NoInvoice: "",
      NoPenerima: "",
      NoPengirim: "",
      Pengirim: "",
      SubTotal: "",
      Tanggal: "",
      TelpCust: "",
      TotalHarga: "",
      codeGudang: "",
      itemsTypePromo: "",
      Bank: "",
      Komentar: "",
      Gambar: "",
      listOrder: [],
      tanggal_tempo: "",

      Kurir: "",
      Service: "",
      Resi: "",
      keterangantrace: "",
      ResultTrace: [],

      receiver_name: "",
      destination: "",
      waybill_date: "",
      statuskirim: "",
    },

    itemsStatus: [
      { text: "Reseller", value: "1" },
      { text: "Cust Reguler", value: "0" },
    ],
    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,
    verifikasi: false,

    selectedImage: null,
    dateTempo: false,

    diskonReset: "",
    jenisResellerReset: "",
    kodeAdminTrx: "",

    dialogPreview: false,
    manifestShow: false,
    manifest: [],
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.kodeAdminTrx = this.User.kodeAdminTrx;
      if (this.User.code_jabatan == 2) {
        this.headers = this.headersSales;
      }
    }
    this.initialize();
  },

  methods: {
    async initialize() {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetHistoryBosman?kodeAdminTrx=" +
            this.kodeAdminTrx,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataobject = [];
        }
      }
    },
    async detailData(val) {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetTransaksiBosmanDetail?idOrder=" +
            this.defaultItem.id,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.defaultItem = response.data.data.result;
          if (val == "Edit") {
            this.defaultItem.customerCombobox = {
              idCust: response.data.data.result.idCust,
              nama: response.data.data.result.Customer,
            };
            this.discSelectedCustomer =
              response.data.data.result.diskonCustomer;
            this.nameTextKabROSelected =
              response.data.data.result.kecamatan +
              "," +
              response.data.data.result.kab_kota +
              "," +
              response.data.data.result.propinsi;
            if (response.data.data.result.kab_kota_ro_kode == "-") {
              await this.getKabKotaROSelected();
              this.defaultItem.kabROCombobox = {
                id_alamat: this.defaultItem.id_alamatkabROCombobox,
                text: this.defaultItem.textkabROCombobox,
              };
            } else {
              this.defaultItem.kabROCombobox = {
                id_alamat: response.data.data.result.kab_kota_ro_kode,
                text: this.nameTextKabROSelected,
              };
            }
          }
          if (response.data.data.result.typeTransaksi == "tempo") {
            this.dateTempo = true;
          } else {
            this.dateTempo = false;
          }
        } else {
          console.log("Kosong");
          //   this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          //   this.dataobject = [];
        }
      }
    },
    async traceApi() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "cektrace?NoInvoice=" + this.defaultItem.id,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.defaultItem = response.data.data.result;
          if (response.data.data.result.keterangantrace == "success") {
            this.manifestShow = true;
            this.defaultItem.statuskirim =
              response.data.data.result.ResultTrace.summary.status;
            this.defaultItem.receiver_name =
              response.data.data.result.ResultTrace.summary.receiver_name;
            this.defaultItem.destination =
              response.data.data.result.ResultTrace.summary.destination;
            this.defaultItem.waybill_date =
              response.data.data.result.ResultTrace.summary.waybill_date;
            var objarray = response.data.data.result.Manifest;
            this.manifest = objarray.reverse();
          } else {
            this.manifestShow = false;
          }
        } else {
          console.log("Kosong");
          //   this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          //   this.dataobject = [];
        }
      }
    },
    async sendEmailApi() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "KirimEmailTrx?idOrder=" + this.defaultItem.id,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.dialogOngkir = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses Kirim email";
          // this.defaultItem = response.data.data.result;
          // if (response.data.data.result.keterangantrace == "success") {
          //   this.manifestShow = true;
          //   this.defaultItem.statuskirim =
          //     response.data.data.result.ResultTrace.summary.status;
          //   this.defaultItem.receiver_name =
          //     response.data.data.result.ResultTrace.summary.receiver_name;
          //   this.defaultItem.destination =
          //     response.data.data.result.ResultTrace.summary.destination;
          //   this.defaultItem.waybill_date =
          //     response.data.data.result.ResultTrace.summary.waybill_date;
          //   var objarray = response.data.data.result.ResultTrace.manifest;
          //   this.manifest = objarray.reverse();
          // } else {
          //   this.manifestShow = false;
          // }
        } else {
          console.log("Kosong");
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
          //   this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
          //   this.dataobject = [];
        }
      }
    },
    async apiVerifikasi(val) {
      const datapost = {
        status: val,
        kodeAdminTrx: this.kodeAdminTrx,
        no_invoice: this.defaultItem.NoInvoice,
        keterangan: this.defaultItem.Komentar,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "VerifikasiAdmin",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogDetail = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses ubah data";
          this.initialize();
        } else {
          console.log("Kosong");
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },

    detailItem(item) {
      this.defaultItem = Object.assign({}, item);
      //   this.verifikasi = this.verifikasiButton(item.code_status);
      this.dialogDetail = true;
      this.detailData("Detail");
    },
    traceItem(item) {
      this.defaultItem = Object.assign({}, item);
      console.log(item);
      this.traceApi();
      this.dialogTrace = true;
    },
    sendEmail(item) {
      this.defaultItem = Object.assign({}, item);
      console.log(item);
      this.sendEmailApi();
    },

    close() {
      this.dialogDetail = false;
    },
    verifikasiTrx() {
      console.log("test");
      console.log(this.defaultItem.NoInvoice);
      this.apiVerifikasi("Approve");
    },

    hold() {
      this.apiVerifikasi("Hold");
    },

    zoom(url) {
      // console.log("zooom");
      console.log("Zoom", url);
      this.selectedImage = url;
    },

    createCanvas() {
      html2canvas(document.querySelector("#capture")).then((canvas) => {
        document.getElementById("cap2").className = "d-none";
        document.getElementById("cap1").className = "d-none";
        document.getElementById("canvas").appendChild(canvas);
        // document.getElementById("capture").className = "d-none";
        document.getElementById("cap1").className = "h1";
        document.getElementById("cap2").className = "container mb-5";
      });
    },

    print() {
      // this.createCanvas();
      this.dialogDetail = false;
      this.dialogPreview = true;
      // this.createCanvas();
    },

    printImage() {
      document.getElementById("capture").className = "pa-1 ma-1 invGmbr";
      document.getElementById("canvas").className = "d-none";

      html2canvas(document.querySelector("#capture"), {
        useCORS: true,
        allowTaint: true,
      }).then(function (canvas) {
        console.log(canvas);
        var uri = canvas.toDataURL();
        var filename = "file-name.png";
        var link = document.createElement("a");
        if (typeof link.download === "string") {
          link.href = uri;
          link.download = filename;
          //Firefox requires the link to be in the body
          document.body.appendChild(link);
          //simulate click
          link.click();
          //remove the link when done
          document.body.removeChild(link);
        } else {
          window.open(uri);
        }
        // this.saveAs(canvas.toDataURL(), "file-name.png");
      });
      // document.getElementById("capture").className = "d-none";
      // document.getElementById("canvas").className = "";

      this.dialogPreview = false;
    },

    printPdf() {
      var printContents = document.getElementById("capture").innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      location.reload();
    },

    saveAs(uri, filename) {
      var link = document.createElement("a");
      if (typeof link.download === "string") {
        link.href = uri;
        link.download = filename;
        //Firefox requires the link to be in the body
        document.body.appendChild(link);
        //simulate click
        link.click();
        //remove the link when done
        document.body.removeChild(link);
      } else {
        window.open(uri);
      }
    },

    verifikasiButton(status) {
      if (status == "4") return false;
      else if (status == "5") return true;
      else if (status == "6") return false;
      else if (status == "7") return false;
      else return false;
    },

    getStatus(status) {
      if (status == "0") return "Customer Reg";
      else return "Reseller";
    },
    getColorStatus(status) {
      if (status == "regular") return "green";
      else if (status == "preOrder") return "blue";
      else if (status == "tempo") return "orange";
      else return "red";
    },
    getColorStatusKonfirmasi(status) {
      if (status == "regular") return "green";
      else if (status == "preOrder") return "blue";
      else if (status == "tempo") return "orange";
      else return "red";
    },
    getColorStatusKonfirmasiAdmin(status) {
      if (status == "4") return "orange";
      else if (status == "5") return "red";
      else if (status == "6") return "purple";
      else if (status == "7") return "purple";
      else if (status == "9") return "blue";
      else return "green";
    },
    getIconStatusAdmin(status) {
      if (status == "4") return "mdi-text-box-search-outline";
      else if (status == "5") return "mdi-text-box-check-outline";
      else if (status == "6") return "mdi-table-search";
      else if (status == "7") return "mdi-package-variant";
      else if (status == "9") return "mdi-check-underline-circle";
      else return "mdi-cube-send";
    },
    getStatusKonfirmasiAdmin(status) {
      if (status == "4") return "Menunggu Keuangan";
      else if (status == "5") return "Konfirmasi Keuangan";
      else if (status == "6") return "Menunggu Gudang";
      else if (status == "7") return "Proses Pengemasan";
      else if (status == "9") return "Selesai Pengemasan";
      else return "Di kirim Kurir";
    },
    getTotalHarga(a, b, c, d) {
      var result = 0;
      result = parseInt(a) - parseInt(b) + parseInt(c) - parseInt(d);

      return this.getRupiah(result);
      //
    },

    getRupiah(val) {
      var bilangan = val;

      if (bilangan) {
        var number_string = bilangan.toString(),
          sisa = number_string.length % 3,
          rupiah = number_string.substr(0, sisa),
          ribuan = number_string.substr(sisa).match(/\d{3}/g);

        if (ribuan) {
          var separator = sisa ? "." : "";
          rupiah += separator + ribuan.join(".");
        }

        return rupiah;
      }
    },

    gettanggal(val) {
      var bulanIndo = [
        "",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Juni",
        "Juli",
        "Agust",
        "Sept",
        "Okt",
        "Nov",
        "Des",
      ];

      var date = val.split(" ")[0];

      var tanggal = date.split("-")[2];
      var bulan = date.split("-")[1];
      var tahun = date.split("-")[0];

      return tanggal + " " + bulanIndo[Math.abs(bulan)] + " " + tahun;
    },
    getjam(val) {
      var time = val.split(" ")[1];

      var jam = time.split(":")[0];
      var menit = time.split(":")[1];

      return jam + ":" + menit;
    },
  },
};
</script>



