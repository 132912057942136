<template>
  <div>
    <v-breadcrumbs
      class="breadcrumbsmain fontall"
      :items="itemsbr"
      large
      color="black"
      style="font-weight: bold"
      ><template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template></v-breadcrumbs
    >
    <v-card elevation="5" class="pt-4 pb-0 px-2 mx-3">
      <v-card-text>
        <v-row>
          <v-col class="px-1 py-0" cols="12" sm="5" md="5">
            <v-menu v-model="menu1" transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datepicker1"
                  slot="activator"
                  label="Tanggal Awal"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[(v) => !!v || 'Field is required']"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="datepicker1"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col class="px-1 py-0" cols="12" sm="5" md="5">
            <v-menu v-model="menu2" transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datepicker2"
                  slot="activator"
                  label="Tanggal Akhir"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[(v) => !!v || 'Field is required']"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="datepicker2"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col class="px-1 py-0 mb-1" cols="12" sm="2" md="2">
            <v-btn
              large
              block
              color="primary"
              elevation="1"
              @click="searchPerfroma"
            >
              <v-icon left> mdi-magnify </v-icon>
              Search
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card elevation="5" class="mt-4 pb-0 px-2 mx-3">
      <v-card-text
        style="text-align: center; font-weight: bold; font-size: 20px"
      >
        Performa Staff Gudang</v-card-text
      >
    </v-card>
    <v-row class="mt-2 mx-1 mb-2">
      <v-col class="px-2 mt-1" cols="12" sm="4" md="4">
        <v-card elevation="5" class="mt-1 mx-auto">
          <v-card-text
            style="text-align: center; font-weight: bold; font-size: 17px"
          >
            Penjualan per tipe Transaksi
          </v-card-text>
          <v-divider style="background-color: black !important"></v-divider>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Nama</th>
                  <th class="text-left">Buku</th>
                  <th class="text-left">Inv/Faktur</th>
                  <th class="text-left">Nominal</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listTypePenjualan" :key="item.kodeAdminTrx">
                  <td
                    @click="showDetailTypePenjualan(item.kodeAdminTrx)"
                    class="pl-3 pr-0"
                  >
                    {{ item.admin }}
                  </td>
                  <td
                    @click="showDetailTypePenjualan(item.kodeAdminTrx)"
                    class="pl-3 pr-0"
                  >
                    {{ item.total_barang }}
                  </td>
                  <td
                    @click="showDetailTypePenjualan(item.kodeAdminTrx)"
                    class="pl-3 pr-0"
                  >
                    {{ item.order }}
                  </td>
                  <td
                    @click="showDetailTypePenjualan(item.kodeAdminTrx)"
                    class="pl-3 pr-0"
                  >
                    {{ getRupiah(item.total_uang) }}
                  </td>
                </tr>
                <tr style="text-align: center">
                  <td colspan="3">Klik di Baris Untuk Melihat Detail</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col class="px-2 mt-1" cols="12" sm="8" md="8">
        <v-card elevation="5" class="mt-4 mx-auto">
          <v-sheet
            class="v-sheet--offset mx-auto mt-7"
            color="cyan"
            elevation="12"
            max-width="calc(100% - 30px)"
          >
            <v-sparkline
              :labels="labelsSparkline"
              :value="valueSparkline"
              max-height="80px"
              min-height="70px"
              line-width="2"
              padding="16"
              color="white"
            ></v-sparkline>
          </v-sheet>

          <v-card-text class="pt-0">
            <div class="text-h6 font-weight-light mb-2">
              <v-btn
                @click="showPenjualan()"
                block
                text
                small
                class="grey--text"
                >Penjualan 1 Tahun</v-btn
              >
            </div>

            <!-- <div class="subheading font-weight-light grey--text">
              Last Campaign Performance
            </div> -->
            <v-divider class="my-2"></v-divider>
            <v-icon class="mr-2" small> mdi-clock </v-icon>
            <span class="text-caption grey--text font-weight-light"
              >Performa {{ namaAdmin }} 1 Tahun
            </span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogDetailYears" max-width="400px">
      <v-card>
        <v-card-title class="mb-1 headermodalstyle">
          <span>Detail Penjualan Tahun</span>
        </v-card-title>

        <v-card-text class="fontall pa-1">
          <v-container class="pa-1">
            <v-data-table
              :headers="headersPenjualan"
              :items="listPenjualan"
              class="rounded elevation-1 mx-1 pa-1 my-2"
              hide-default-footer
              disable-pagination
            >
              <template v-slot:item.order="{ item }">
                {{ item.order }}
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDetailType" max-width="900px">
      <v-card>
        <v-card-title class="mb-1 headermodalstyle">
          <span>Detail Banyak Pengemasan</span>
        </v-card-title>
        <div style="text-align: center">
          <v-btn color="black" elevation="1" outlined @click="download()">
            <v-icon left> mdi-download-circle-outline </v-icon>
            Download
          </v-btn>
        </div>
        <v-card-text class="fontall pa-1">
          <v-container class="pa-1">
            <v-data-table
              :headers="headersDetailPenjualanType"
              :items="listDetailPenjualanType"
              class="rounded elevation-1 mx-1 pa-1 my-2"
              hide-default-footer
              disable-pagination
            >
              <template v-slot:item.total="{ item }">
                {{ getRupiah(item.total) }}
              </template>
              <template v-slot:item.diskon="{ item }">
                {{ getRupiah(item.diskon) }}
              </template>
              <template v-slot:item.created_at="{ item }">
                {{ gettanggal(item.created_at) }}
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
      class="fontall"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PerformaSales",
  data: () => ({
    itemsbr: [
      {
        text: "Report",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Performa Sales",
        disabled: true,
        href: "breadcrumbs_link_1",
      },
    ],

    formTitle: "Add Item",
    value: "add",
    dialog: false,
    dialogDelete: false,
    dialogReset: false,
    dialogDetailYears: false,
    dialogDetailType: false,
    validateddisable: false,
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    headers: [
      {
        text: "Kode Cust",
        value: "idCust",
      },
      { text: "Nama", value: "nama", width: "25%" },
      { text: "No Telp", value: "telephone" },
      { text: "Type Cust", value: "jenis_reseller" },
      { text: "Diskon", value: "diskon" },
      //   { text: "User ID", value: "username" },
      { text: "Actions", value: "actions", sortable: false, width: "15%" },
    ],
    headersPenjualan: [
      { text: "Bulan", value: "bulan" },
      { text: "Penjualan", value: "order" },
    ],
    headersDetailPenjualanType: [
      { text: "Code Order", value: "code_order" },
      { text: "Type", value: "tipe" },
      { text: "Total Buku", value: "total_buku" },
      { text: "Total Harga", value: "total_harga" },
      { text: "Diskon", value: "totalDiskon" },
      { text: "Diskon Unik", value: "diskonKodeUnik" },
      { text: "Tangal Submit", value: "updated_at" },
    ],
    listPenjualan: [],
    listTypePenjualan: [],
    listPembelianCust: [],
    listPenjualanBuku: [],
    listDetailPenjualanType: [],
    listDetailPenjualanBuku: [],
    listDetailPembelianCust: [],
    dataobject: [],
    itemsAdmin: [],

    defaultItem: {
      id: "",
    },

    admindropdown: "",

    labelsSparkline: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Agust",
      "Sept",
      "Okt",
      "Nop",
      "Dec",
    ],
    valueSparkline: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],

    datepicker1: new Date().toISOString().substr(0, 10),
    datepicker2: new Date().toISOString().substr(0, 10),
    menu1: "",
    menu2: "",
    namaAdmin: "",

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,

    diskonReset: "",
    jenisResellerReset: "",
    kodeAdminTrx: "",
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.kodeAdminTrx = this.User.kodeAdminTrx;
      if (this.User.status_jabatan == "Staff") {
        this.admindropdown = this.kodeAdminTrx;
        this.validateddisable = true;
      } else {
        this.validateddisable = false;
      }
    }
  },

  methods: {
    async getPerforma() {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetAllPerformaGudangAdmin?fromDate=" +
            this.datepicker1 +
            "&toDate=" +
            this.datepicker2,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          // this.dataobject = response.data.data.result.data;
          this.labelsSparkline =
            response.data.data.result.PerformaGudangYear.listbulan;
          this.valueSparkline =
            response.data.data.result.PerformaGudangYear.listorder;
          this.listPenjualan =
            response.data.data.result.PerformaGudangYear.listorderadmin;
          // this.namaAdmin = response.data.data.result.namaadmin;

          this.listTypePenjualan =
            response.data.data.result.PerformaGudangAll.listorderadmin;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.dataobject = [];
        }
      }
    },
    async getDetailPerforma(val) {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "PerformaGudangAllDetail?kodeAdminTrx=" +
            val +
            "&fromDate=" +
            this.datepicker1 +
            "&toDate=" +
            this.datepicker2,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          // this.dataobject = response.data.data.result.data;

          this.listDetailPenjualanType = response.data.data.result;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.dataobject = [];
        }
      }
    },

    searchPerfroma() {
      console.log(this.datepicker1);
      console.log(this.datepicker2);
      console.log(this.admindropdown);

      if (this.admindropdown.length != 0) {
        this.getPerforma();
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Gagal Mencari, Kolom tidak boleh kosong";
      }
    },
    showPenjualan() {
      this.dialogDetailYears = true;
    },

    showDetailTypePenjualan(val) {
      console.log(val);
      this.getDetailPerforma(val);
      this.dialogDetailType = true;
    },

    getRupiah(val) {
      var bilangan = val;

      if (bilangan) {
        var number_string = bilangan.toString(),
          sisa = number_string.length % 3,
          rupiah = number_string.substr(0, sisa),
          ribuan = number_string.substr(sisa).match(/\d{3}/g);

        if (ribuan) {
          var separator = sisa ? "." : "";
          rupiah += separator + ribuan.join(".");
        }

        return "Rp. " + rupiah;
      }
    },
    gettanggal(val) {
      var bulanIndo = [
        "",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Juni",
        "Juli",
        "Agust",
        "Sept",
        "Okt",
        "Nov",
        "Des",
      ];

      var date = val.split(" ")[0];

      var tanggal = date.split("-")[2];
      var bulan = date.split("-")[1];
      var tahun = date.split("-")[0];

      return tanggal + " " + bulanIndo[Math.abs(bulan)] + " " + tahun;
    },
  },
};
</script>



