<template>
  <div>
    <v-breadcrumbs
      class="breadcrumbsmain fontall"
      :items="itemsbr"
      large
      color="black"
      style="font-weight: bold"
      ><template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template></v-breadcrumbs
    >

    <v-data-table
      :headers="headers"
      :items="dataobject"
      :search="search"
      class="rounded elevation-6 mx-3 pa-1 fontall"
    >
      <template v-slot:item.statustrx="{ item }">
        <v-chip
          outlined
          :color="getColorStatusKonfirmasiAdmin(item.status)"
          dark
        >
          <v-icon
            medium
            class="mr-1"
            :color="getColorStatusKonfirmasiAdmin(item.status)"
          >
            {{ getIconStatusAdmin(item.status) }}
          </v-icon>
          {{ getStatusKonfirmasiAdmin(item.status) }}
        </v-chip>
        <!-- <tr>
          <td>
            <v-icon medium class="mr-1" color="primary">
              {{ getIconStatus(item.code_status) }}
            </v-icon>
          </td>
          <td>
            <h4>
              {{ item.status }}
            </h4>
          </td>
        </tr> -->
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn dark class="mb-2" @click="showAddModal()" color="green">
            <v-icon small>mdi-plus</v-icon> Add Item
          </v-btn>

          <!-- Modal Detail -->
          <v-dialog v-model="dialogDetail" max-width="700px">
            <v-card>
              <v-card-title class="headermodalstyle">
                <span>Detail Invoice</span>
              </v-card-title>

              <v-card-text class="fontall pa-1">
                <v-container>
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 150px; font-size: 14px"
                          >
                            No Kode Pindah Gudang
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.code_transaksi_pg }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Gudang Tujuan
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.nama_gudang }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Alamat Tujuan
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.AlamatGudang }}
                          </th>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <v-data-table
                    :headers="headersBarang"
                    :items="defaultItem.listOrder"
                    :search="search"
                    class="rounded elevation-1 mx-1 pa-1 my-2"
                    hide-default-footer
                    disable-pagination
                  >
                    <template v-slot:item.subtotal="{ item }">
                      {{ getRupiah(item.subtotal) }}
                    </template>
                  </v-data-table>
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Sub Total
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ getRupiah(defaultItem.SubTotal) }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Diskon
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ getRupiah(defaultItem.total_diskon) }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Total Harga
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ getRupiah(defaultItem.TotalHarga) }}
                          </th>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-container>
              </v-card-text>
              <v-divider class="mx-4"></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue" elevation="1" outlined @click="print">
                  <v-icon left> mdi-printer-check </v-icon>
                  Cetak Invoice
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Modal Preview -->
          <v-dialog v-model="dialogPreview" max-width="900px">
            <v-card>
              <v-card-title class="headermodalstyle">
                <span>Preview Invoie</span>
              </v-card-title>
              <div class="text-center fontprint" style="">
                <h1 id="cap1" class="h1 d-none">INVOICE AQWAM</h1>
                <div
                  id="cap2"
                  class="container mb-5 d-none"
                  style="width: 100%"
                >
                  <input
                    type="button"
                    class="btn btn-success float-right mx-2"
                    onclick="printDiv('capture')"
                    value="Cetak Invoice PDF"
                  />
                  <button
                    id="download"
                    class="btn btn-success float-right mx-2"
                  >
                    Cetak Invoice PNG
                  </button>
                  <br style="clear: both" />
                  <br style="clear: both" />
                  <br style="clear: both" />
                </div>
                <div
                  class="container-fluid pa-2 mt-5 mr-2"
                  style="overflow: auto"
                >
                  <div style="width: 100%" id="canvas" />
                </div>
              </div>
              <div style="overflow: auto" class="mr-2">
                <div
                  id="capture"
                  class="pa-2 ma-2"
                  style="transform: scale(0.85)"
                >
                  <div class="printtable mr-2" style="padding: 1px !important">
                    <table
                      style="width: 100% !important"
                      class="fontprint mb-1"
                    >
                      <tr>
                        <!-- nama store -->
                        <td rowspan="3">
                          <img
                            src="/images/aqwamhitam.png"
                            class="mr-auto"
                            style="margin-left: 10px !important; width: 150px"
                          />
                        </td>
                        <!-- Tanggal -->
                        <td>
                          <p style="margin: 5px 0px 2px"><b>Tanggal</b></p>
                          <p style="margin-bottom: 10px">
                            {{ defaultItem.Tanggal }}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <!-- No Invoice -->
                        <td>
                          <p style="margin: 1px 0px 2px">
                            <b>Nomor Invoice</b>
                          </p>
                          <p style="margin-bottom: 10px">
                            {{ defaultItem.code_transaksi_pg }}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <!-- nama admin -->
                          <p style="margin: 1px 0px 2px"><b>Nama Admin</b></p>
                          <p style="margin-bottom: 10px">
                            {{ defaultItem.Admin }}
                          </p>
                        </td>
                      </tr>
                      <tr class="fontprint">
                        <td>
                          <p style="margin: 1px 0px 1px 10px !important">
                            Assalamu'alaikum Warahmatullahi Wabarakatuh
                          </p>
                          <!-- nama pembeli -->
                          <p style="margin: 1px 0px 1px 10px !important">
                            <b>Kepada {{ defaultItem.nama_gudang }}</b>
                          </p>
                          <!-- Terimakasih -->
                          <p style="margin: 1px 0px 1px 10px !important">
                            Terimakasih sudah berbelanja di AQWAM STORE, Berikut
                            rincian dari orderan anda :
                          </p>
                        </td>
                        <!-- keterangan bayar -->
                        <td><h3 style="color: red">BELUM DIBAYAR</h3></td>
                      </tr>
                    </table>
                    <table
                      class="table margininvoicebot2"
                      style="
                        margin: 5px 0px 0px 0px !important;
                        padding: 0px 1px 0px 2px !important;
                      "
                    >
                      <tr>
                        <th class="font-weight-bold">Nama Produk</th>
                        <th class="font-weight-bold text-center"></th>
                        <th class="font-weight-bold text-center"></th>
                        <th class="font-weight-bold text-center">Jumlah</th>
                        <th class="font-weight-bold text-center">Diskon</th>
                        <th class="font-weight-bold text-center">Harga</th>
                      </tr>
                      <!-- <tr>
                        <td>Fikih Empat Mazhab Jilid II</td>
                        <td class="text-center">1</td>
                        <td class="text-center">1kg</td>
                        <td class="text-right">Rp 179.000</td>
                        <td class="text-center">0%</td>
                        <td class="text-right">Rp 179.000</td>
                      </tr> -->
                      <tr
                        v-for="vallist in defaultItem.listOrder"
                        :key="vallist.codeBarang"
                      >
                        <td>
                          <h4>
                            {{ vallist.namaBarang }}
                          </h4>
                          <li
                            v-for="item in vallist.listbuku"
                            :key="item.judul_buku"
                          >
                            {{ item.judul_buku }}
                          </li>
                        </td>
                        <td class="text-center"></td>
                        <td class="text-center"></td>
                        <td class="text-center">{{ vallist.jumlah }}</td>
                        <td class="text-center">{{ vallist.diskon }}%</td>
                        <td class="text-right">
                          {{ getRupiah(vallist.harga) }}
                        </td>
                      </tr>
                      <tr class="border-top">
                        <td
                          class="font-weight-bold text-weight-bold"
                          colspan="2"
                        >
                          SubTotal
                        </td>
                        <td class="text-center"></td>
                        <td class="text-right"></td>
                        <td class="text-right"></td>
                        <td class="text-right">
                          {{ getRupiah(defaultItem.SubTotal) }}
                        </td>
                      </tr>
                      <tr class="border-top">
                        <td
                          class="font-weight-bold text-weight-bold"
                          colspan="2"
                        >
                          Diskon
                        </td>
                        <td class="text-center"></td>
                        <td class="text-right"></td>
                        <td class="text-right"></td>
                        <td class="text-right">
                          {{ getRupiah(defaultItem.total_diskon) }}
                        </td>
                      </tr>
                      <!-- ekspedisi -->
                      <tr>
                        <td class="font-weight-bold">TOTAL</td>
                        <td class="font-weight-bold"></td>
                        <td class="font-weight-bold"></td>
                        <td class="font-weight-bold"></td>
                        <td class="font-weight-bold"></td>
                        <td class="font-weight-bold text-right">
                          {{ getRupiah(defaultItem.TotalHarga) }}
                        </td>
                      </tr>
                    </table>
                    <hr class="border-top m-2" />
                    <table class="table table-sm-responsive table-borderless">
                      <tr>
                        <td
                          class="font-weight-bold margininvoicebot1"
                          style="width: 350px"
                        >
                          <p class="margininvoicebot1">Rekening Pembayaran</p>
                          <p class="margininvoicebot1">
                            - BCA 3930782024 an Fahrul Fahrizal A
                          </p>
                          <p class="margininvoicebot1">
                            - BNI 1174135748 an Fahrul Fahrizal A
                          </p>
                          <p class="margininvoicebot1">
                            - Mandiri 1380018288444 an Fahrul Fahrizal A
                          </p>
                          <p class="margininvoicebot1">
                            - BRI 018201050711500 an Fahrul Fahrizal A
                          </p>
                          <p>Semoga Allah Memberi Keberkahan</p>
                          <p
                            style="border: 2px solid red; text-align: justify"
                            class="notedprint h5 rounded"
                          >
                            <span class="font-weight-bolder">NOTE :</span> Untuk
                            mendukung kecepatan dan ketepatan pengiriman order,
                            harap transfer
                            <span class="font-weight-bolder"
                              >sesuai dengan nominal invoice</span
                            >, melalui
                            <span class="font-weight-bolder"
                              >satu rekening bank</span
                            >
                            yang dipilih.
                          </p>
                        </td>
                        <!-- </tr>
          <tr> -->
                        <td class="font-weight-bold" style="width: 120px">
                          Detail Pengiriman
                        </td>
                        <td>
                          <p class="font-weight-bold margininvoicebot1">
                            #Tujuan '
                          </p>
                          <p class="margininvoicebot1">
                            {{ defaultItem.nama_gudang }} ({{
                              defaultItem.TelephoneTujuan
                            }})
                          </p>
                          <p class="margininvoicebot1">
                            {{ defaultItem.AlamatGudang }}
                          </p>
                          <!-- <p>{{ defaultItem.Alamat }}</p> -->
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <!-- </v-container> -->
              <v-card-actions>
                <v-btn
                  class="d-sm-none"
                  color="blue"
                  elevation="1"
                  outlined
                  @click="printPdf"
                >
                  <v-icon left> mdi-printer-check </v-icon>
                  Cetak PDF
                </v-btn>
                <v-btn
                  class="d-sm-none"
                  color="blue"
                  elevation="1"
                  outlined
                  @click="printImage"
                >
                  <v-icon left> mdi-printer-check </v-icon>
                  Cetak Image
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  class="d-none d-sm-block"
                  color="blue"
                  elevation="1"
                  outlined
                  @click="printPdf"
                >
                  <v-icon left> mdi-printer-check </v-icon>
                  Cetak PDF
                </v-btn>
                <v-btn
                  class="d-none d-sm-block"
                  color="blue"
                  elevation="1"
                  outlined
                  @click="printImage"
                >
                  <v-icon left> mdi-printer-check </v-icon>
                  Cetak Image
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Modal Edit Dialog -->
          <!-- <v-dialog v-model="dialogShowEdit" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >What you want to edit?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn outlined color="blue" @click="showEditModal"
                  >Edit Invoice</v-btn
                >
                <v-btn outlined color="green" @click="showEditVerifikasi"
                  >Verifikasi</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog> -->

          <!-- Modal Add/ Edit -->
          <v-dialog v-model="dialog" max-width="800px">
            <v-card>
              <v-card-title class="mb-1 headermodalstyle">
                <span>{{ formTitle }}</span>
              </v-card-title>
              <v-card-text class="pa-0 fontall">
                <v-stepper v-model="e1">
                  <v-stepper-header>
                    <v-stepper-step editable :complete="e1 > 1" step="1">
                      Main Data
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step editable step="2">
                      List Buku
                    </v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-items>
                    <v-stepper-content class="pa-3" step="1">
                      <v-container class="mb-2">
                        <v-row class="mb-3">
                          <h4>Pilih Gudang</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row>
                          <v-col class="px-1 py-0" cols="12" sm="12" md="12">
                            <v-combobox
                              v-model="defaultItem.gudangTujuanCombobox"
                              :items="itemsGudangTujuan"
                              item-value="codeGudang"
                              item-text="namaGudang"
                              label="Pilih Gudang Tujuan"
                              v-on:change="gudangCombobox"
                              outlined
                              :rules="[(v) => !!v || 'Field is required']"
                            ></v-combobox>
                          </v-col>
                          <v-col
                            v-if="showAlamatForm == true"
                            class="pa-1"
                            cols="12"
                            sm="12"
                            md="12"
                          >
                            <v-textarea
                              v-model="defaultItem.AlamatGudang"
                              label="Alamat"
                              rows="3"
                              outlined
                              disabled
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-container>

                      <v-row class="ma-2">
                        <v-spacer></v-spacer>
                        <v-btn
                          class="mr-1"
                          color="red"
                          elevation="1"
                          @click="close"
                          outlined
                        >
                          <v-icon left> mdi-close-circle-outline </v-icon>
                          Cancel
                        </v-btn>
                        <v-btn
                          elevation="1"
                          outlined
                          color="primary"
                          @click="e1 = 2"
                        >
                          <v-icon left>
                            mdi-chevron-right-circle-outline
                          </v-icon>
                          Next
                        </v-btn>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-content class="pa-3" step="2">
                      <v-container class="mb-2">
                        <v-row v-if="valueAddForm == true" class="mb-3">
                          <h4>Pilih Gudang Asal</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row v-if="valueAddForm == true">
                          <v-col class="px-1 py-0" cols="12" sm="12" md="12">
                            <v-select
                              v-model="defaultItem.itemsTypeGudang"
                              :items="itemsTypeGudang"
                              item-value="codeGudang"
                              item-text="text"
                              label="Pilih Gudang"
                              v-on:change="changeGudangSelected"
                              clearable
                              outlined
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row v-if="valueAddForm == false" class="mb-3">
                          <h4>Pilih Buku</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                          <v-btn
                            v-if="valueResetBukuForm == true"
                            color="red"
                            @click="resetbuku"
                            outlined
                          >
                            <v-icon left> mdi-close-circle-outline </v-icon>
                            Reset
                          </v-btn>
                        </v-row>
                        <v-row v-if="valueAddForm == false">
                          <v-col class="px-1 py-0" cols="12" sm="12" md="12">
                            <v-combobox
                              v-model="defaultItem.itemsBuku"
                              :items="itemsBuku"
                              item-value="id"
                              item-text="judul_buku"
                              label="Pilih Buku"
                              v-on:change="changeBukuSelected"
                              outlined
                              clearable
                            ></v-combobox>
                          </v-col>
                        </v-row>
                        <v-row class="mb-3">
                          <h4>List Buku</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-data-table
                              :headers="headerListBuku"
                              :items="defaultItem.listOrder"
                              class="elevation-3"
                              append-icon="mdi-magnify"
                              :items-per-page="5"
                            >
                              <template v-slot:item.actions="{ item }">
                                <v-icon
                                  class="mr-2"
                                  @click="deletelistitembuku(item)"
                                  color="red"
                                >
                                  mdi-delete
                                </v-icon>
                                <!-- <v-icon @click="deleteItem(item)" color="red"> mdi-delete </v-icon> -->
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-container>

                      <v-row class="ma-2">
                        <v-spacer></v-spacer>
                        <v-btn
                          class="mr-1"
                          color="red"
                          elevation="1"
                          @click="close"
                          outlined
                        >
                          <v-icon left> mdi-close-circle-outline </v-icon>
                          Cancel
                        </v-btn>
                        <v-btn
                          color="success"
                          elevation="1"
                          outlined
                          @click="saveInvoice"
                        >
                          <v-icon left>
                            mdi-checkbox-marked-circle-outline
                          </v-icon>
                          Save
                        </v-btn>
                      </v-row>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-card-text>
              <!-- <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn color="success" elevation="1" outlined @click="save">
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  Save
                </v-btn>
              </v-card-actions> -->
            </v-card>
          </v-dialog>

          <!-- Dialog Loader -->
          <v-dialog v-model="loading" fullscreen full-width>
            <v-container
              fluid
              fill-height
              style="background-color: rgba(255, 255, 255, 0.5)"
            >
              <v-layout justify-center align-center>
                <v-progress-circular indeterminate color="primary">
                </v-progress-circular>
              </v-layout>
            </v-container>
          </v-dialog>

          <!-- Modal Add Buku -->
          <v-dialog v-model="dialogAddBuku" max-width="400px">
            <v-card>
              <v-card-text class="pa-1 fontall">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <div>
                        <h5>Nama Buku</h5>
                        <h3 class="ml-2">
                          {{ judulBukuTemp }}
                        </h3>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <div>
                        <h5>Harga</h5>
                        <h3 class="ml-2">
                          {{ hargaBukuTemp }}
                        </h3>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <div>
                        <h5>Berat</h5>
                        <h3 class="ml-2">{{ beratBukuTemp }} gram</h3>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <div>
                        <h5>Stock Tersedia</h5>
                        <h3 class="ml-2">{{ totalBukuTemp }}</h3>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-1" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="jumlahBukuTemp"
                        label="Jumlah"
                        outlined
                        type="number"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-1" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="diskonBukuTemp"
                        label="Diskon"
                        required
                        type="number"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="closeAddBuku">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn color="success" elevation="1" outlined @click="addBuku">
                  <v-icon left> mdi-plus-circle-outline </v-icon>
                  Add
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Modal Show Verifikasi Dialog -->
          <v-dialog v-model="dialogShowVerifikasi" max-width="600px">
            <v-card>
              <v-card-title v-if="verifikasi == false" class="headline"
                >Tidak Bisa Verifikasi, Status Masih Menunggu!</v-card-title
              >
              <v-card-title v-if="verifikasi == true" class="headline"
                >Pilih Verifikasi Pindah Gudang</v-card-title
              >
              <v-card-text v-if="verifikasi == true" class="fontall py-1 px-5">
                <v-row>
                  <v-col class="pb-1" cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="defaultItem.Komentar"
                      label="Komentar"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="mx-4"></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="verifikasi == true"
                  color="red"
                  elevation="1"
                  outlined
                  @click="hold()"
                >
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Hold
                </v-btn>
                <v-btn
                  v-if="verifikasi == true"
                  color="green"
                  elevation="1"
                  outlined
                  @click="verifikasiTrx()"
                >
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  Verifikasi
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Modal Delete -->
          <v-dialog v-model="dialogDelete" max-width="600px">
            <v-card>
              <v-card-title v-if="verifikasi == false" class="headline"
                >Tidak Bisa Delete, Hubungi Admin!</v-card-title
              >
              <v-card-title v-if="verifikasi == true" class="headline"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions v-if="verifikasi == true">
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon @click="detailItem(item)" color="blue">
          mdi-information-outline
        </v-icon>
        <v-icon @click="verifikasiItem(item)" color="green"
          >mdi-checkbox-marked-circle-outline</v-icon
        >
        <!-- <v-icon @click="editItem(item)" color="warning">mdi-pencil</v-icon> -->
        <v-icon @click="deleteItem(item)" color="red"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script src="../public/js/html2canvas.js"></script>

<script>
import axios from "axios";
import html2canvas from "@/js/html2canvas";

export default {
  name: "PindahGudang",
  data: () => ({
    itemsbr: [
      {
        text: "Konsinyasi",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Pindah Gudang",
        disabled: true,
        href: "breadcrumbs_link_1",
      },
    ],

    formTitle: "Add Item",
    value: "add",
    dialog: false,
    dialogDelete: false,
    dialogDetail: false,
    dialogPreview: false,
    dialogAddBuku: false,
    dialogOngkir: false,
    dialogShowEdit: false,
    dialogShowVerifikasi: false,
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    BaseUrlUpload: "",
    BaseUrl: "",
    BaseUrlDBImage: "",
    headers: [
      { text: "Kode Pindah Gudang", value: "code_transaksi_pg" },
      { text: "Nama Gudang", value: "nama_gudang" },
      { text: "Status Trx", value: "statustrx" },
      { text: "Tangal", value: "created_at" },
      //   { text: "User ID", value: "username" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headersBarang: [
      { text: "Nama Buku", value: "namaBarang" },
      { text: "Jumlah", value: "jumlah" },
      { text: "Diskon", value: "diskon" },
      { text: "Harga", value: "harga", width: "25%" },
    ],
    headerListBuku: [
      { text: "Nama Buku", value: "namaBarang", width: "35%" },
      { text: "Berat", value: "berat", width: "12%" },
      { text: "Harga", value: "harga", width: "18%" },
      { text: "Diskon", value: "diskon", width: "12%" },
      { text: "Jumlah", value: "jumlah", width: "13%" },
      { text: "Actions", value: "actions", sortable: false, width: "10%" },
    ],
    dataobject: [],

    defaultItem: {
      code_transaksi_pg: "",
      nama_gudang: "",
      statustrx: "",
      status: "",

      gambar: "",

      AlamatGudang: "",
      gudangCombobox: "",
      gudangTujuanCombobox: "",
      TotalHarga: "",
      total_diskon: "",
      SubTotal: "",

      code_gudang_tujuan: "",
      code_gudang_asal: "",
      code_perusahaan: "",
      Tanggal: "",
      Admin: "",
      Komentar: "",
      listOrder: [],
    },

    itemsGudangTujuan: [],
    itemsBuku: [],
    itemsTrx: [
      { text: "Regular", value: "regular" },
      { text: "Pre Order", value: "preOrder" },
      { text: "Tempo", value: "tempo" },
      { text: "Arisan", value: "arisan" },
    ],
    itemsTypeGudang: [
      { text: "Gudang Solo/ Reguler", codeGudang: "Gd_001" },
      { text: "Gudang Jakarta", codeGudang: "Gd_002" },
    ],

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,

    verifikasi: false,

    loading: false,
    loadOngkir: false,
    loadMessage: false,
    successMessage: false,
    dropdownOngkirManual: false,
    valueOtherBank: false,
    gambarshow: "",
    fileUpload: "",
    fileUploadReady: false,
    gambarinput: "",
    selectedImage: null,
    resultUpload: false,
    bankVerifikasi: "",
    komentarVerifikasi: "-",
    selectedBankVerifikasi: "",

    e1: 1,
    stepperdone: false,
    showAlamatForm: false,
    discSelectedCustomer: 0,
    nameTextKabROSelected: "",
    valueResetBukuForm: false,
    valueAddForm: false,
    codeBukuTemp: "",
    judulBukuTemp: "",
    hargaBukuTemp: "",
    beratBukuTemp: "",
    jumlahBukuTemp: 0,
    diskonBukuTemp: 0,
    totalBukuTemp: 0,
    beratShowOngkir: 0,

    datepicker: new Date().toISOString().substr(0, 10),
    menu2: "",
    dateTempo: false,

    ongkirTemp: "",

    kodeAdminTrx: "",
    code_jabatan: "",
    status_jabatan: "",
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlUpload = localStorage.getItem("BaseUrlUpload");
    this.BaseUrl = localStorage.getItem("BaseUrl");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.BaseUrlDBImage = localStorage.getItem("BaseUrlDBImage");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.kodeAdminTrx = this.User.kodeAdminTrx;
      this.code_jabatan = this.User.code_jabatan;
      this.status_jabatan = this.User.status_jabatan;
    }
    this.initialize();
    this.getGudangTujuan();
  },

  methods: {
    async initialize() {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetPindahGudangBosman?kodeAdminTrx=" +
            this.kodeAdminTrx,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataobject = [];
        }
      }
    },
    async detailData(val) {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetPindahGudangBosmanDetail?idOrder=" +
            this.defaultItem.code_transaksi_pg,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.defaultItem = response.data.data.result;
          if (val == "Edit") {
            this.defaultItem.customerCombobox = {
              idCust: response.data.data.result.idCust,
              nama: response.data.data.result.Customer,
            };
            this.discSelectedCustomer =
              response.data.data.result.diskonCustomer;
            this.nameTextKabROSelected =
              response.data.data.result.kecamatan +
              "," +
              response.data.data.result.kab_kota +
              "," +
              response.data.data.result.propinsi;
            if (response.data.data.result.kab_kota_ro_kode == "-") {
              await this.getKabKotaROSelected();
              this.defaultItem.kabROCombobox = {
                id_alamat: this.defaultItem.id_alamatkabROCombobox,
                text: this.defaultItem.textkabROCombobox,
              };
            } else {
              this.defaultItem.kabROCombobox = {
                id_alamat: response.data.data.result.kab_kota_ro_kode,
                text: this.nameTextKabROSelected,
              };
            }
          }
        } else {
          console.log("Kosong");
          //   this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          //   this.dataobject = [];
        }
      }
    },

    async getGudangTujuan() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "DropdownGudangStock",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.itemsGudangTujuan = response.data.data.result;
        } else {
          console.log("Kosong");
          this.itemsGudangTujuan = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsGudangTujuan = [];
        }
      }
    },
    async GetBarang() {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetBarang?CodeGudang=" +
            this.defaultItem.code_gudang_asal +
            "&type=non_promo",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.itemsBuku = response.data.data.result;
        } else {
          console.log("Kosong");
          this.itemsBuku = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsBuku = [];
        }
      }
    },

    async verifDelete() {
      const datapost = {
        code_transaksi_pg: this.defaultItem.code_transaksi_pg,
        kodeAdminTrx: this.kodeAdminTrx,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "DeletePindahGudangBosman",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result.data);
        if (response.data.data.result == "success") {
          this.dialogDelete = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses hapus data";
          this.initialize();
        } else {
          this.dialogDelete = false;
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },
    async addData() {
      // console.log(this.defaultItem.NoInvoice);
      // console.log(this.defaultItem.typeTransaksi);
      // console.log(this.defaultItem.customerCombobox);
      // console.log(this.defaultItem.Pengirim);
      // console.log(this.defaultItem.NoPengirim);
      // console.log(this.defaultItem.NamaPenerima);
      // console.log(this.defaultItem.NoPenerima);
      // console.log(this.defaultItem.Alamat);
      // console.log(this.defaultItem.Expedisi);
      // console.log(this.defaultItem.BiayaExpedisi);
      // console.log(this.defaultItem.kabROCombobox);
      // console.log(this.defaultItem.listOrder);
      // console.log(this.defaultItem.codeGudang);

      const datapost = {
        kodeAdminTrx: this.kodeAdminTrx,
        code_gudang_asal: this.defaultItem.code_gudang_asal,
        code_gudang_tujuan: JSON.stringify(
          this.defaultItem.gudangTujuanCombobox
        ),
        ListOrder: JSON.stringify(this.defaultItem.listOrder),
        keterangan: "-",
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "AddPindahGudangBosman",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async updateData() {
      const datapost = {
        NoInvoice: this.defaultItem.NoInvoice,
        kodeAdminTrx: this.kodeAdminTrx,
        TypeTransaksi: this.defaultItem.typeTransaksi,
        Customer: JSON.stringify(this.defaultItem.customerCombobox),
        Pengirim: this.defaultItem.Pengirim,
        NoPengirim: this.defaultItem.NoPengirim,
        NamaPenerima: this.defaultItem.NamaPenerima,
        NoPenerima: this.defaultItem.NoPenerima,
        Alamat: this.defaultItem.Alamat,
        KabKotaRO: JSON.stringify(this.defaultItem.kabROCombobox),
        ListOrder: JSON.stringify(this.defaultItem.listOrder),
        Expedisi: this.defaultItem.Expedisi,
        BiayaExpedisi: this.defaultItem.BiayaExpedisi,
        CodeGudang: this.defaultItem.codeGudang,
        tanggal_tempo: this.datepicker,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "EditInvoice",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogOngkir = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses ubah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          this.dialog = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async apiVerifikasi(val) {
      const datapost = {
        status: val,
        kodeAdminTrx: this.kodeAdminTrx,
        code_transaksi_pg: this.defaultItem.code_transaksi_pg,
        keterangan: this.defaultItem.Komentar,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "VerifikasiAdminPindahGudang",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogShowVerifikasi = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses ubah data";
          this.initialize();
        } else {
          console.log("Kosong");
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },

    gudangCombobox(val) {
      console.log(val);
      this.defaultItem.AlamatGudang = val.alamat;
      this.defaultItem.code_gudang_tujuan = val.codeGudang;
      this.showAlamatForm = true;
    },
    changeGudangSelected(val) {
      console.log(val);
      this.valueAddForm = false;
      this.valueResetBukuForm = true;
      this.defaultItem.code_gudang_asal = val;
      this.GetBarang();
    },
    resetbuku() {
      this.valueAddForm = true;
      this.valueResetBukuForm = false;
      this.defaultItem.codeGudang = "";
      this.defaultItem.listOrder = [];
    },
    changeBukuSelected(val) {
      console.log(val);
      this.codeBukuTemp = val.id;
      this.judulBukuTemp = val.judul_buku;
      this.hargaBukuTemp = val.harga;
      this.beratBukuTemp = val.berat;
      this.totalBukuTemp = val.total;
      this.dialogAddBuku = true;
    },
    // pilihmanual() {
    //   console.log(this.defaultItem.BiayaExpedisi);
    //   console.log(this.defaultItem.Expedisi);
    // },

    addBuku() {
      // console.log(this.codeBukuTemp);
      // console.log(this.jumlahBukuTemp);
      // console.log(this.diskonBukuTemp);
      if (this.jumlahBukuTemp > 0) {
        if (this.jumlahBukuTemp > this.totalBukuTemp) {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Stock Buku Tidak Mencukupi..!!!";
        } else {
          var listmenu = this.defaultItem.listOrder;
          var result = false;
          for (let n = 0; n < listmenu.length; n++) {
            if (listmenu[n].codeBarang == this.codeBukuTemp) {
              result = true;
              break;
            }
          }

          if (result == false) {
            var bukuset = {
              berat: "",
              codeBarang: "",
              diskon: "",
              harga: "",
              jumlah: "",
              namaBarang: "",
            };

            bukuset.berat = this.beratBukuTemp;
            bukuset.codeBarang = this.codeBukuTemp;
            bukuset.diskon = this.diskonBukuTemp;
            bukuset.harga = this.hargaBukuTemp;
            bukuset.jumlah = this.jumlahBukuTemp;
            bukuset.namaBarang = this.judulBukuTemp;

            this.defaultItem.listOrder.push(bukuset);

            this.dialogAddBuku = false;
          } else {
            this.snackbar = true;
            this.colorsnackbar = "red";
            this.textsnackbar = "Buku Sudah Ada Bro..!!!";
          }
        }
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Jumlah Buku Tidak boleh 0..!!!";
      }
    },
    async deletelistitembuku(item) {
      this.editedIndex = await this.defaultItem.listOrder.indexOf(item);
      this.editedItem = await Object.assign({}, item);
      // console.log(this.editedIndex);
      // console.log(this.editedItem);
      this.defaultItem.listOrder.splice(this.editedIndex, 1);
    },

    showAddModal() {
      this.value = "add";
      this.valueAddForm = true;
      this.formTitle = "Create Pindah Gudang";
      this.defaultItem.customerCombobox = { idCust: "", nama: "" };
      this.showDisconForm = false;
      this.resetForm();
      this.e1 = 1;
      this.dialog = true;
    },
    detailItem(item) {
      this.defaultItem = Object.assign({}, item);
      this.dialogDetail = true;
      this.detailData("Detail");
    },
    verifikasiItem(item) {
      this.defaultItem.code_transaksi_pg = item.code_transaksi_pg;
      if (this.code_jabatan == 2) {
        if (item.status == 19 || item.status == 6 || item.status == 10) {
          this.verifikasi = false;
        } else {
          this.verifikasi = true;
        }
      }
      if (this.code_jabatan == 3) {
        if (item.status == 6 || item.status == 10) {
          this.verifikasi = false;
        } else {
          this.verifikasi = true;
        }
      }
      if (this.code_jabatan == 4) {
        if (item.status == 19 || item.status == 10) {
          this.verifikasi = false;
        } else {
          this.verifikasi = true;
        }
      }
      this.dialogShowVerifikasi = true;
    },

    editItem(item) {
      if (this.code_jabatan == 2) {
        if (item.status == 19 || item.status == 6 || item.status == 10) {
          this.verifikasi = false;
        } else {
          this.verifikasi = true;
        }
      } else if (this.code_jabatan == 3 || this.code_jabatan == 4) {
        this.verifikasi = false;
      }
    },
    deleteItem(item) {
      if (this.code_jabatan == 2) {
        if (item.status == 6 || item.status == 10) {
          this.verifikasi = false;
        } else {
          this.verifikasi = true;
        }
      } else if (this.code_jabatan == 3 || this.code_jabatan == 4) {
        this.verifikasi = false;
      }
      this.dialogDelete = true;
    },
    showEditModal() {
      // console.log(item);
      // this.resetForm();
      this.dialogShowEdit = false;
      this.formTitle = "Edit Invoice";
      // this.defaultItem = Object.assign({}, item);
      this.showDisconForm = true;
      this.detailData("Edit");
      this.e1 = 1;
      this.valueAddForm = false;
      this.valueResetBukuForm = false;
      this.value = "edit";
      this.dialog = true;
    },
    showEditVerifikasi() {
      this.dialogShowVerifikasi = true;
      this.dialogShowEdit = false;
    },

    deleteItemConfirm() {
      this.verifDelete();
      this.closeDelete();
    },

    resetForm() {
      this.defaultItem.idOrder = "";
      this.defaultItem.Admin = "";
      this.defaultItem.Alamat = "";
      this.defaultItem.BiayaExpedisi = 0;
      this.defaultItem.typeTransaksi = "";
      this.defaultItem.customerCombobox = "";
      this.defaultItem.kabROCombobox = "";
      this.defaultItem.id_alamatkabROCombobox = "";
      this.defaultItem.textkabROCombobox = "";
      this.defaultItem.kab_kota_ro_kode = "";
      this.defaultItem.Customer = "";
      this.defaultItem.diskonCustomer = "";
      this.defaultItem.Diskon = "";
      this.defaultItem.DiskonKodeUnik = "";
      this.defaultItem.Expedisi = "";
      this.defaultItem.NamaPenerima = "";
      this.defaultItem.NoInvoice = "";
      this.defaultItem.NoPenerima = "";
      this.defaultItem.NoPengirim = "";
      this.defaultItem.Pengirim = "";
      this.defaultItem.SubTotal = "";
      this.defaultItem.Tanggal = "";
      this.defaultItem.TelpCust = "";
      this.defaultItem.TotalHarga = "";
      this.defaultItem.codeGudang = "";
      this.defaultItem.itemsTypePromo = "";
      this.defaultItem.listOrder = [];
      this.itemsBuku = [];
      this.dateTempo = false;
    },

    close() {
      this.dialog = false;
      this.dialogDetail = false;
      this.dialogAddBuku = false;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    closeOngkir() {
      this.dialogOngkir = false;
    },
    closeAddBuku() {
      this.dialogAddBuku = false;
    },
    closeVerification() {
      this.dialogShowVerifikasi = false;
    },
    verifikasiOngkir() {
      if (
        this.defaultItem.typeTransaksi.length != 0 &&
        this.defaultItem.Pengirim.length != 0 &&
        this.defaultItem.NoPengirim.length != 0 &&
        this.defaultItem.NamaPenerima.length != 0 &&
        this.defaultItem.NoPenerima.length != 0 &&
        this.defaultItem.Alamat.length != 0 &&
        this.defaultItem.codeGudang.length != 0 &&
        this.defaultItem.customerCombobox.length != 0 &&
        this.defaultItem.kabROCombobox.length != 0 &&
        this.defaultItem.listOrder.length != 0
      ) {
        this.cekOngkir();
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Kolom tidak boleh ada yang kosong";
      }
    },

    saveInvoice() {
      if (
        this.defaultItem.code_gudang_tujuan.length != 0 &&
        this.defaultItem.code_gudang_asal.length != 0
      ) {
        if (this.defaultItem.code_transaksi_pg) {
          console.log("edit");
          this.updateData();
        } else {
          console.log("add");
          this.addData();
        }
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar =
          "Gagal Membuat Invoice, Kolom tidak boleh ada yang kosong";
      }
    },
    verifikasiTrx() {
      console.log("test");
      console.log(this.defaultItem.code_transaksi_pg);
      this.apiVerifikasi("Approve");
    },

    hold() {
      this.apiVerifikasi("Hold");
    },

    async saveVerification() {
      if (
        this.selectedBankVerifikasi.length != 0 &&
        this.komentarVerifikasi.length != 0
      ) {
        console.log(this.selectedBankVerifikasi);
        console.log(this.komentarVerifikasi);
        await this.UploadData();
        if (this.resultUpload == true) {
          console.log("Save Verification");
          this.verificationApi();
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal Upload Gambar";
        }
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Kolom tidak boleh ada yang kosong";
      }
      // console.log(this.selectedBankVerifikasi);
      // console.log(this.komentarVerifikasi);
    },

    createCanvas() {
      html2canvas(document.querySelector("#capture")).then((canvas) => {
        document.getElementById("cap2").className = "d-none";
        document.getElementById("cap1").className = "d-none";
        document.getElementById("canvas").appendChild(canvas);
        // document.getElementById("capture").className = "d-none";
        document.getElementById("cap1").className = "h1";
        document.getElementById("cap2").className = "container mb-5";
      });
    },

    print() {
      // this.createCanvas();
      this.dialogDetail = false;
      this.dialogPreview = true;
    },

    printImage() {
      document.getElementById("capture").className = "pa-1 ma-1 invGmbr";
      document.getElementById("canvas").className = "d-none";

      html2canvas(document.querySelector("#capture"), {
        useCORS: true,
        allowTaint: true,
      }).then(function (canvas) {
        console.log(canvas);
        var uri = canvas.toDataURL();
        var filename = "file-name.png";
        var link = document.createElement("a");
        if (typeof link.download === "string") {
          link.href = uri;
          link.download = filename;
          //Firefox requires the link to be in the body
          document.body.appendChild(link);
          //simulate click
          link.click();
          //remove the link when done
          document.body.removeChild(link);
        } else {
          window.open(uri);
        }
        // this.saveAs(canvas.toDataURL(), "file-name.png");
      });
      // document.getElementById("capture").className = "d-none";
      // document.getElementById("canvas").className = "";

      this.dialogPreview = false;
    },

    printPdf() {
      var printContents = document.getElementById("capture").innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      location.reload();
    },

    saveAs(uri, filename) {
      var link = document.createElement("a");
      if (typeof link.download === "string") {
        link.href = uri;
        link.download = filename;
        //Firefox requires the link to be in the body
        document.body.appendChild(link);
        //simulate click
        link.click();
        //remove the link when done
        document.body.removeChild(link);
      } else {
        window.open(uri);
      }
    },

    getStatus(status) {
      if (status == "0") return "Customer Reg";
      else return "Reseller";
    },
    getColorStatus(status) {
      if (status == "regular") return "green";
      else if (status == "preOrder") return "blue";
      else if (status == "tempo") return "orange";
      else return "red";
    },
    getTotalHarga(a, b, c, d) {
      var result = 0;
      result = parseInt(a) - parseInt(b) + parseInt(c) - parseInt(d);

      return this.getRupiah(result);
      //
    },

    getRupiah(val) {
      var bilangan = val;

      if (bilangan) {
        var number_string = bilangan.toString(),
          sisa = number_string.length % 3,
          rupiah = number_string.substr(0, sisa),
          ribuan = number_string.substr(sisa).match(/\d{3}/g);

        if (ribuan) {
          var separator = sisa ? "." : "";
          rupiah += separator + ribuan.join(".");
        }

        return "Rp. " + rupiah;
      }
    },

    getColorStatusKonfirmasiAdmin(status) {
      if (status == "19") return "orange";
      else if (status == "5") return "red";
      else if (status == "6") return "purple";
      else if (status == "7") return "purple";
      else if (status == "9") return "blue";
      else return "green";
    },
    getIconStatusAdmin(status) {
      if (status == "19") return "mdi-text-box-search-outline";
      else if (status == "5") return "mdi-text-box-check-outline";
      else if (status == "6") return "mdi-table-search";
      else if (status == "7") return "mdi-package-variant";
      else if (status == "9") return "mdi-check-underline-circle";
      else return "mdi-cube-send";
    },
    getStatusKonfirmasiAdmin(status) {
      if (status == "19") return "Menunggu Keuangan";
      else if (status == "5") return "Konfirmasi Keuangan";
      else if (status == "6") return "Menunggu Gudang";
      else if (status == "7") return "Proses Pengemasan";
      else if (status == "9") return "Selesai Pengemasan";
      else return "Di kirim Kurir";
    },
  },
};
</script>
