<template>
  <div>
    <v-breadcrumbs
      class="breadcrumbsmain fontall"
      :items="itemsbr"
      large
      color="black"
      style="font-weight: bold"
      ><template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template></v-breadcrumbs
    >
    <v-card elevation="5" class="pt-4 pb-0 px-2 mx-3">
      <v-card-text>
        <v-row>
          <v-col class="px-1 py-0" cols="12" sm="3" md="3">
            <v-menu v-model="menu1" transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datepicker1"
                  slot="activator"
                  label="Tanggal Awal"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[(v) => !!v || 'Field is required']"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="datepicker1"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col class="px-1 py-0" cols="12" sm="3" md="3">
            <v-menu v-model="menu2" transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datepicker2"
                  slot="activator"
                  label="Tanggal Akhir"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[(v) => !!v || 'Field is required']"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="datepicker2"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col class="px-1 py-0" cols="12" sm="4" md="4">
            <v-select
              v-model="admindropdown"
              :items="itemsAdmin"
              item-text="nama"
              item-value="kodeAdminTrx"
              label="Pilih Admin"
              outlined
              clearable
              :disabled="validateddisable == true"
              :rules="[(v) => !!v || 'Field is required']"
            ></v-select>
          </v-col>
          <v-col class="px-1 py-0 mb-1" cols="12" sm="2" md="2">
            <v-btn
              large
              block
              color="primary"
              elevation="1"
              @click="searchPerfroma"
            >
              <v-icon left> mdi-magnify </v-icon>
              Search
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card elevation="5" class="mt-4 pb-0 px-2 mx-3">
      <v-card-text
        style="text-align: center; font-weight: bold; font-size: 20px"
      >
        Performa Admin {{ namaAdmin }}</v-card-text
      >
    </v-card>
    <v-row class="mt-2 mx-1">
      <v-col class="px-2 mt-1" cols="12" sm="4" md="4">
        <v-card elevation="5" class="mt-1 mx-auto">
          <v-card-text
            style="text-align: center; font-weight: bold; font-size: 17px"
          >
            Penjualan per tipe Transaksi
          </v-card-text>
          <v-divider style="background-color: black !important"></v-divider>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Tipe</th>
                  <th class="text-left">Barang</th>
                  <th class="text-left">Uang</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listTypePenjualan" :key="item.Value">
                  <td
                    @click="showDetailTypePenjualan(item.Value)"
                    class="pl-3 pr-0"
                  >
                    {{ item.Text }}
                  </td>
                  <td
                    @click="showDetailTypePenjualan(item.Value)"
                    class="pl-2 pr-0"
                  >
                    {{ item.TotalBarang }}
                  </td>
                  <td @click="showDetailTypePenjualan(item.Value)" class="px-1">
                    {{ item.SubTotalUang }}
                  </td>
                </tr>
                <tr style="text-align: center">
                  <td colspan="3">Klik di Baris Untuk Melihat Detail</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col class="px-2 mt-1" cols="12" sm="8" md="8">
        <v-card elevation="5" class="mt-4 mx-auto">
          <v-sheet
            class="v-sheet--offset mx-auto mt-7"
            color="cyan"
            elevation="12"
            max-width="calc(100% - 30px)"
          >
            <v-sparkline
              :labels="labelsSparkline"
              :value="valueSparkline"
              max-height="80px"
              min-height="70px"
              line-width="2"
              padding="16"
              color="white"
            ></v-sparkline>
          </v-sheet>

          <v-card-text class="pt-0">
            <div class="text-h6 font-weight-light mb-2">
              <v-btn
                @click="showPenjualan()"
                block
                text
                small
                class="grey--text"
                >Penjualan 1 Tahun</v-btn
              >
            </div>

            <!-- <div class="subheading font-weight-light grey--text">
              Last Campaign Performance
            </div> -->
            <v-divider class="my-2"></v-divider>
            <v-icon class="mr-2" small> mdi-clock </v-icon>
            <span class="text-caption grey--text font-weight-light"
              >Performa {{ namaAdmin }} 1 Tahun
            </span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-2 mx-1 mb-2">
      <v-col class="px-2 mt-1" cols="12" sm="6" md="6">
        <v-card elevation="5" class="mt-1 mx-auto">
          <v-card-text
            style="text-align: center; font-weight: bold; font-size: 17px"
          >
            Penjualan Buku
          </v-card-text>
          <v-divider style="background-color: black !important"></v-divider>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Kode</th>
                  <th style="width: 45%" class="text-left">Buku</th>
                  <th class="text-left">Jumlah</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listPenjualanBuku" :key="item.code_barang">
                  <td
                    @click="showDetailBukuOrder(item.code_barang)"
                    class="pl-3 pr-0"
                  >
                    {{ item.code_barang }}
                  </td>
                  <td
                    @click="showDetailBukuOrder(item.code_barang)"
                    class="pl-2 pr-0"
                  >
                    {{ item.judul_buku }}
                  </td>
                  <td
                    @click="showDetailBukuOrder(item.code_barang)"
                    class="px-1"
                  >
                    {{ item.total }}
                  </td>
                </tr>
                <tr style="text-align: right">
                  <td>
                    <v-btn
                      v-if="RoleManagerShow == true"
                      @click="showDetailBukuSemua()"
                      block
                      outlined
                      small
                      class="primary--text"
                      >Detail Setiap Buku</v-btn
                    >
                  </td>
                  <td colspan="2">
                    <a @click="showDetailBuku()">Lihat Lebih Banyak >></a>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col class="px-2 mt-1" cols="12" sm="6" md="6">
        <v-card elevation="5" class="mt-1 mx-auto">
          <v-card-text
            style="text-align: center; font-weight: bold; font-size: 17px"
          >
            Pembelian Customer
          </v-card-text>
          <v-divider style="background-color: black !important"></v-divider>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" style="width: 45%">Nama</th>
                  <th class="text-left">Barang</th>
                  <th class="text-left">Uang</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listPembelianCust" :key="item.nama_customer">
                  <td class="pl-3 pr-0">
                    {{ item.nama_customer }}
                  </td>
                  <td class="pl-2 pr-0">
                    {{ item.total_buku }}
                  </td>
                  <td class="px-1">
                    {{ getRupiah(item.total) }}
                  </td>
                </tr>
                <tr style="text-align: right">
                  <td colspan="3">
                    <a @click="showDetailCustomer()">Lihat Lebih Banyak >></a>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogDetailYears" max-width="400px">
      <v-card>
        <v-card-title class="mb-1 headermodalstyle">
          <span>Detail Penjualan Tahun</span>
        </v-card-title>

        <v-card-text class="fontall pa-1">
          <v-container class="pa-1">
            <v-data-table
              :headers="headersPenjualan"
              :items="listPenjualan"
              class="rounded elevation-1 mx-1 pa-1 my-2"
              hide-default-footer
              disable-pagination
            >
              <template v-slot:item.order="{ item }">
                {{ item.order }}
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDetailType" max-width="800px">
      <v-card>
        <v-card-title class="mb-1 headermodalstyle">
          <span>Detail Penjualan Tipe Trx</span>
        </v-card-title>
        <div style="text-align: center">
          <v-btn
            color="black"
            elevation="1"
            outlined
            @click="downloadpenjualantype()"
          >
            <v-icon left> mdi-download-circle-outline </v-icon>
            Download
          </v-btn>
        </div>
        <v-card-text class="fontall pa-1">
          <v-container class="pa-1">
            <v-data-table
              :headers="headersDetailPenjualanType"
              :items="listDetailPenjualanType"
              class="rounded elevation-1 mx-1 pa-1 my-2"
              hide-default-footer
              disable-pagination
            >
              <template v-slot:item.total="{ item }">
                {{ getRupiah(item.total) }}
              </template>
              <template v-slot:item.diskon="{ item }">
                {{ getRupiah(item.diskon) }}
              </template>
              <template v-slot:item.created_at="{ item }">
                {{ gettanggal(item.created_at) }}
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDetailBuku" max-width="800px">
      <v-card>
        <v-card-title class="mb-1 headermodalstyle">
          <span>Detail Penjualan Buku</span>
        </v-card-title>
        <div style="text-align: center">
          <v-btn
            color="black"
            elevation="1"
            outlined
            @click="downloadpenjualanbuku()"
          >
            <v-icon left> mdi-download-circle-outline </v-icon>
            Download
          </v-btn>
        </div>
        <v-card-text class="fontall pa-1">
          <v-container class="pa-1">
            <v-data-table
              :headers="headersDetailPenjualanBuku"
              :items="listDetailPenjualanBuku"
              class="rounded elevation-1 mx-1 pa-1 my-2"
              hide-default-footer
              disable-pagination
            >
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDetailCust" max-width="800px">
      <v-card>
        <v-card-title class="mb-1 headermodalstyle">
          <span>Detail Penjualan Cust</span>
        </v-card-title>
        <div style="text-align: center">
          <v-btn
            color="black"
            elevation="1"
            outlined
            @click="downloadpembeliancust()"
          >
            <v-icon left> mdi-download-circle-outline </v-icon>
            Download
          </v-btn>
        </div>
        <v-card-text class="fontall pa-1">
          <v-container class="pa-1">
            <v-data-table
              :headers="headersDetailPembelianCust"
              :items="listDetailPembelianCust"
              class="rounded elevation-1 mx-1 pa-1 my-2"
              hide-default-footer
              disable-pagination
            >
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDetailBukuTrx" max-width="550px">
      <v-card>
        <v-card-title class="mb-1 headermodalstyle">
          <span>Detail Penjualan Buku per Transaksi </span>
        </v-card-title>
        <v-card-text class="fontall pa-1">
          <v-container class="pa-1">
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td
                      class="pl-2 pr-2 text-left"
                      style="font-size: 14px; font-weight: bold"
                      width="520px"
                      colspan="2"
                    >
                      <v-combobox
                        outlined
                        dense
                        v-model="searchnamabuku"
                        :items="itemsBuku"
                        item-value="id"
                        item-text="judul_buku"
                        v-on:change="enterNamaBuku"
                        color="primary"
                        class="pt-0 px-1"
                        label="Nama Buku"
                        placeholder="Nama Buku...."
                        hide-details
                        clearable
                        prepend-inner-icon="mdi-magnify"
                      ></v-combobox>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="pl-2 pr-2 text-left"
                      style="font-size: 14px; font-weight: bold"
                      width="150px"
                    >
                      Kode Buku
                    </td>
                    <td
                      class="pl-2 pr-2 text-left"
                      style="font-size: 14px; font-weight: bold"
                    >
                      {{ detailpenjualanbuku.id }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="pl-2 pr-2 text-left"
                      style="font-size: 14px; font-weight: bold"
                      width="175px"
                    >
                      Nama Buku
                    </td>
                    <td
                      class="pl-2 pr-2 text-left"
                      style="font-size: 14px; font-weight: bold"
                    >
                      {{ detailpenjualanbuku.namabuku }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="pl-2 pr-2 text-left"
                      style="font-size: 14px; font-weight: bold"
                    >
                      Buku Arisan Proses
                    </td>
                    <td
                      class="pl-2 pr-2 text-center"
                      style="font-size: 14px; font-weight: bold"
                    >
                      {{ detailpenjualanbuku.bukuArisanProses }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="pl-2 pr-2 text-left"
                      style="font-size: 14px; font-weight: bold"
                    >
                      Buku Arisan Selesai
                    </td>
                    <td
                      class="pl-2 pr-2 text-center"
                      style="font-size: 14px; font-weight: bold"
                    >
                      {{ detailpenjualanbuku.bukuArisanSelesai }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="pl-2 pr-2 text-left"
                      style="font-size: 14px; font-weight: bold"
                    >
                      Invoice
                    </td>
                    <td
                      class="pl-2 pr-2 text-center"
                      style="font-size: 14px; font-weight: bold"
                    >
                      {{ detailpenjualanbuku.bukuInvoice }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="pl-2 pr-2 text-left"
                      style="font-size: 14px; font-weight: bold"
                    >
                      Buku PO Proses
                    </td>
                    <td
                      class="pl-2 pr-2 text-center"
                      style="font-size: 14px; font-weight: bold"
                    >
                      {{ detailpenjualanbuku.bukuPreOrderProses }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="pl-2 pr-2 text-left"
                      style="font-size: 14px; font-weight: bold"
                    >
                      Buku PO Selesai
                    </td>
                    <td
                      class="pl-2 pr-2 text-center"
                      style="font-size: 14px; font-weight: bold"
                    >
                      {{ detailpenjualanbuku.bukuPreOrderSelesai }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="pl-2 pr-2 text-left"
                      style="font-size: 14px; font-weight: bold"
                    >
                      Buku Reguler Proses
                    </td>
                    <td
                      class="pl-2 pr-2 text-center"
                      style="font-size: 14px; font-weight: bold"
                    >
                      {{ detailpenjualanbuku.bukuRegulerProses }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="pl-2 pr-2 text-left"
                      style="font-size: 14px; font-weight: bold"
                    >
                      Buku Reguler Selesai
                    </td>
                    <td
                      class="pl-2 pr-2 text-center"
                      style="font-size: 14px; font-weight: bold"
                    >
                      {{ detailpenjualanbuku.bukuRegulerSelesai }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="pl-2 pr-2 text-left"
                      style="font-size: 14px; font-weight: bold"
                    >
                      Buku Tempo Proses
                    </td>
                    <td
                      class="pl-2 pr-2 text-center"
                      style="font-size: 14px; font-weight: bold"
                    >
                      {{ detailpenjualanbuku.bukuTempoProses }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="pl-2 pr-2 text-left"
                      style="font-size: 14px; font-weight: bold"
                    >
                      Buku Tempo Selesai
                    </td>
                    <td
                      class="pl-2 pr-2 text-center"
                      style="font-size: 14px; font-weight: bold"
                    >
                      {{ detailpenjualanbuku.bukuTempoSelesai }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
      class="fontall"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PerformaSales",
  data: () => ({
    itemsbr: [
      {
        text: "Report",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Performa Sales",
        disabled: true,
        href: "breadcrumbs_link_1",
      },
    ],

    formTitle: "Add Item",
    value: "add",
    dialog: false,
    dialogDelete: false,
    dialogReset: false,
    dialogDetailYears: false,
    dialogDetailType: false,
    dialogDetailBuku: false,
    dialogDetailCust: false,
    validateddisable: false,
    RoleManagerShow: false,
    dialogDetailBukuTrx: false,
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    headers: [
      {
        text: "Kode Cust",
        value: "idCust",
      },
      { text: "Nama", value: "nama", width: "25%" },
      { text: "No Telp", value: "telephone" },
      { text: "Type Cust", value: "jenis_reseller" },
      { text: "Diskon", value: "diskon" },
      //   { text: "User ID", value: "username" },
      { text: "Actions", value: "actions", sortable: false, width: "15%" },
    ],
    headersPenjualan: [
      { text: "Bulan", value: "bulan" },
      { text: "Penjualan", value: "order" },
    ],
    headersDetailPenjualanType: [
      { text: "Code Order", value: "code_order" },
      { text: "Type", value: "tipe" },
      { text: "Total Buku", value: "total_buku" },
      { text: "Uang", value: "total" },
      { text: "Diskon", value: "diskon" },
      { text: "Tangal Submit", value: "created_at" },
    ],
    headersDetailPenjualanBuku: [
      { text: "Kode", value: "code_barang" },
      { text: "Buku", value: "judul_buku" },
      { text: "Jumlah", value: "total" },
    ],
    headersDetailPembelianCust: [
      { text: "Nama", value: "nama_customer" },
      { text: "Barang", value: "total_buku" },
      { text: "Uang", value: "total" },
    ],
    listPenjualan: [],
    listTypePenjualan: [],
    listPembelianCust: [],
    listPenjualanBuku: [],
    listDetailPenjualanType: [],
    listDetailPenjualanBuku: [],
    listDetailPembelianCust: [],
    dataobject: [],
    itemsAdmin: [],
    itemsBuku: [],

    defaultItem: {
      id: "",
    },

    admindropdown: "",
    searchnamabuku: [],

    detailpenjualanbuku: {
      id: "",
      namabuku: "",
      bukuArisanProses: "",
      bukuArisanSelesai: "",
      bukuInvoice: "",
      bukuPreOrderProses: "",
      bukuPreOrderSelesai: "",
      bukuRegulerProses: "",
      bukuRegulerSelesai: "",
      bukuTempoProses: "",
      bukuTempoSelesai: "",
    },

    labelsSparkline: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Agust",
      "Sept",
      "Okt",
      "Nop",
      "Dec",
    ],
    valueSparkline: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],

    datepicker1: new Date().toISOString().substr(0, 10),
    datepicker2: new Date().toISOString().substr(0, 10),
    menu1: "",
    menu2: "",
    namaAdmin: "",
    tipetrx: "",

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,

    diskonReset: "",
    jenisResellerReset: "",
    kodeAdminTrx: "",
    statusJabatanAdmin: "",
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.kodeAdminTrx = this.User.kodeAdminTrx;
      if (this.User.status_jabatan == "Staff") {
        this.admindropdown = this.kodeAdminTrx;
        this.validateddisable = true;
      } else {
        this.validateddisable = false;
      }
      this.statusJabatanAdmin = this.User.status_jabatan;
    }
    this.getAdmin();
    this.getBuku();
  },

  methods: {
    async getPerforma() {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetAllPerformaSalesAdmin?kodeAdminTrx=" +
            this.admindropdown +
            "&fromDate=" +
            this.datepicker1 +
            "&toDate=" +
            this.datepicker2,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          // this.dataobject = response.data.data.result.data;
          this.labelsSparkline =
            response.data.data.result.PerformaSalesYear.listbulan;
          this.valueSparkline =
            response.data.data.result.PerformaSalesYear.listorder;
          this.listPenjualan =
            response.data.data.result.PerformaSalesYear.listorderadmin;
          this.namaAdmin = response.data.data.result.namaadmin;

          this.listTypePenjualan = response.data.data.result.listdata;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.dataobject = [];
        }
      }
    },
    async getDetailPerforma(tipe) {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "PerformaSalesMonthDetail?kodeAdminTrx=" +
            this.admindropdown +
            "&fromDate=" +
            this.datepicker1 +
            "&toDate=" +
            this.datepicker2 +
            "&tipe=" +
            tipe,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          // this.dataobject = response.data.data.result.data;

          this.listDetailPenjualanType = response.data.data.result;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.dataobject = [];
        }
      }
    },
    async getPembelianCust() {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "PerformaCustSales?kodeAdminTrx=" +
            this.admindropdown +
            "&fromDate=" +
            this.datepicker1 +
            "&toDate=" +
            this.datepicker2,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.listPembelianCust = response.data.data.result;
          // this.dataobject = response.data.data.result.data;
          // this.labelsSparkline =
          //   response.data.data.result.PerformaSalesYear.listbulan;
          // this.valueSparkline =
          //   response.data.data.result.PerformaSalesYear.listorder;
          // this.listPenjualan =
          //   response.data.data.result.PerformaSalesYear.listorderadmin;
          // this.namaAdmin = response.data.data.result.namaadmin;
          // this.listTypePenjualan = response.data.data.result.listdata;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.dataobject = [];
        }
      }
    },
    async getPembelianCustDetail() {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "PerformaCustSalesDetail?kodeAdminTrx=" +
            this.admindropdown +
            "&fromDate=" +
            this.datepicker1 +
            "&toDate=" +
            this.datepicker2,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.listDetailPembelianCust = response.data.data.result;
          // this.dataobject = response.data.data.result.data;
          // this.labelsSparkline =
          //   response.data.data.result.PerformaSalesYear.listbulan;
          // this.valueSparkline =
          //   response.data.data.result.PerformaSalesYear.listorder;
          // this.listPenjualan =
          //   response.data.data.result.PerformaSalesYear.listorderadmin;
          // this.namaAdmin = response.data.data.result.namaadmin;
          // this.listTypePenjualan = response.data.data.result.listdata;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.dataobject = [];
        }
      }
    },
    async getPenjualanBuku() {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "PerformaBukuSales?kodeAdminTrx=" +
            this.admindropdown +
            "&fromDate=" +
            this.datepicker1 +
            "&toDate=" +
            this.datepicker2,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.listPenjualanBuku = response.data.data.result;
          // this.dataobject = response.data.data.result.data;
          // this.labelsSparkline =
          //   response.data.data.result.PerformaSalesYear.listbulan;
          // this.valueSparkline =
          //   response.data.data.result.PerformaSalesYear.listorder;
          // this.listPenjualan =
          //   response.data.data.result.PerformaSalesYear.listorderadmin;
          // this.namaAdmin = response.data.data.result.namaadmin;
          // this.listTypePenjualan = response.data.data.result.listdata;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.dataobject = [];
        }
      }
    },
    async getPenjualanBukuDetail() {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "PerformaBukuSalesDetail?kodeAdminTrx=" +
            this.admindropdown +
            "&fromDate=" +
            this.datepicker1 +
            "&toDate=" +
            this.datepicker2,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.listDetailPenjualanBuku = response.data.data.result;
          // this.dataobject = response.data.data.result.data;
          // this.labelsSparkline =
          //   response.data.data.result.PerformaSalesYear.listbulan;
          // this.valueSparkline =
          //   response.data.data.result.PerformaSalesYear.listorder;
          // this.listPenjualan =
          //   response.data.data.result.PerformaSalesYear.listorderadmin;
          // this.namaAdmin = response.data.data.result.namaadmin;
          // this.listTypePenjualan = response.data.data.result.listdata;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.dataobject = [];
        }
      }
    },
    async getPenjualanBukuDetailOrder(val) {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "PerformaBukuDetailOrder?kodeAdminTrx=" +
            this.admindropdown +
            "&fromDate=" +
            this.datepicker1 +
            "&toDate=" +
            this.datepicker2 +
            "&code_barang=" +
            val,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.detailpenjualanbuku.bukuArisanProses =
            response.data.data.result.bukuArisanProses;
          this.detailpenjualanbuku.bukuArisanSelesai =
            response.data.data.result.bukuArisanSelesai;
          this.detailpenjualanbuku.bukuInvoice =
            response.data.data.result.bukuInvoice;
          this.detailpenjualanbuku.bukuPreOrderProses =
            response.data.data.result.bukuPreOrderProses;
          this.detailpenjualanbuku.bukuPreOrderSelesai =
            response.data.data.result.bukuPreOrderSelesai;
          this.detailpenjualanbuku.bukuRegulerProses =
            response.data.data.result.bukuRegulerProses;
          this.detailpenjualanbuku.bukuRegulerSelesai =
            response.data.data.result.bukuRegulerSelesai;
          this.detailpenjualanbuku.bukuTempoProses =
            response.data.data.result.bukuTempoProses;
          this.detailpenjualanbuku.bukuTempoSelesai =
            response.data.data.result.bukuTempoSelesai;
          // this.listDetailPenjualanBuku = response.data.data.result;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.dataobject = [];
        }
      }
    },
    async getAdmin() {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetAllAdminbyJabatan?code_jabatan=2&status_jabatan=Staff",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsAdmin = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.itemsAdmin = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsAdmin = [];
        }
      }
    },
    async getBuku() {
      try {
        const response = await axios.get(this.BaseUrlGet + "GetAllBukuBosman", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsBuku = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.itemsBuku = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsBuku = [];
        }
      }
    },

    searchPerfroma() {
      console.log(this.datepicker1);
      console.log(this.datepicker2);
      console.log(this.admindropdown);

      if (this.admindropdown.length != 0) {
        this.getPerforma();
        this.getPembelianCust();
        this.getPenjualanBuku();
        if (this.statusJabatanAdmin != "Staff") {
          this.RoleManagerShow = true;
        }
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Gagal Mencari, Kolom tidak boleh kosong";
      }
    },
    showPenjualan() {
      this.dialogDetailYears = true;
    },

    showDetailTypePenjualan(val) {
      console.log(val);
      this.getDetailPerforma(val);
      this.tipetrx = val;
      this.dialogDetailType = true;
    },

    showDetailCustomer() {
      console.log("test");
      this.getPembelianCustDetail();
      this.dialogDetailCust = true;
    },
    showDetailBuku() {
      console.log("test");
      this.getPenjualanBukuDetail();
      this.dialogDetailBuku = true;
    },
    showDetailBukuSemua() {
      this.dialogDetailBukuTrx = true;
    },

    showDetailBukuOrder(val) {
      this.getPenjualanBukuDetailOrder(val);
    },

    getRupiah(val) {
      var bilangan = val;

      if (bilangan) {
        var number_string = bilangan.toString(),
          sisa = number_string.length % 3,
          rupiah = number_string.substr(0, sisa),
          ribuan = number_string.substr(sisa).match(/\d{3}/g);

        if (ribuan) {
          var separator = sisa ? "." : "";
          rupiah += separator + ribuan.join(".");
        }

        return "Rp. " + rupiah;
      }
    },
    gettanggal(val) {
      var bulanIndo = [
        "",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Juni",
        "Juli",
        "Agust",
        "Sept",
        "Okt",
        "Nov",
        "Des",
      ];

      var date = val.split(" ")[0];

      var tanggal = date.split("-")[2];
      var bulan = date.split("-")[1];
      var tahun = date.split("-")[0];

      return tanggal + " " + bulanIndo[Math.abs(bulan)] + " " + tahun;
    },

    downloadpenjualantype() {
      var str = this.BaseUrlGet;
      window.open(
        str.substring(0, str.length - 4) +
          "ExportPerformaSalesMonthDetail?kodeAdminTrx=" +
          this.admindropdown +
          "&fromDate=" +
          this.datepicker1 +
          "&toDate=" +
          this.datepicker2 +
          "&tipe=" +
          this.tipetrx
      );
    },

    downloadpenjualanbuku() {
      var str = this.BaseUrlGet;
      window.open(
        str.substring(0, str.length - 4) +
          "ExportPerformaBukuSalesDetail?kodeAdminTrx=" +
          this.admindropdown +
          "&fromDate=" +
          this.datepicker1 +
          "&toDate=" +
          this.datepicker2
      );
    },

    downloadpembeliancust() {
      var str = this.BaseUrlGet;
      window.open(
        str.substring(0, str.length - 4) +
          "ExportPerformaCustSalesDetail?kodeAdminTrx=" +
          this.admindropdown +
          "&fromDate=" +
          this.datepicker1 +
          "&toDate=" +
          this.datepicker2
      );
    },

    enterNamaBuku(val) {
      if (val) {
        this.getPenjualanBukuDetailOrder(this.searchnamabuku.id);

        this.detailpenjualanbuku.id = this.searchnamabuku.id;
        this.detailpenjualanbuku.namabuku = this.searchnamabuku.judul_buku;
      }
      // this.getProductBarcode();

      // if (this.searchnamabuku.id) {
      //   this.getPenjualanBukuDetailOrder(this.searchnamabuku.id);
      // }
    },
  },
};
</script>



