<template>
  <div>
    <v-breadcrumbs
      class="breadcrumbsmain fontall"
      :items="itemsbr"
      large
      color="black"
      style="font-weight: bold"
      ><template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template></v-breadcrumbs
    >

    <v-data-table
      :headers="headers"
      :items="dataobject"
      :search="search"
      class="rounded elevation-6 mx-3 pa-1 fontall"
    >
      <template v-slot:item.typeTransaksi="{ item }">
        <v-chip :color="getColorStatus(item.typeTransaksi)" dark>
          {{ item.typeTransaksi }}
        </v-chip>
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip
          outlined
          :color="getColorStatusKonfirmasiAdmin(item.code_status)"
          dark
        >
          <v-icon
            medium
            class="mr-1"
            :color="getColorStatusKonfirmasiAdmin(item.code_status)"
          >
            {{ getIconStatusAdmin(item.code_status) }}
          </v-icon>
          {{ getStatusKonfirmasiAdmin(item.code_status) }}
        </v-chip>
      </template>

      <template v-slot:item.updated_at="{ item }">
        <tr>
          {{
            gettanggal(item.updated_at)
          }}
        </tr>
        <tr>
          {{
            getjam(item.updated_at)
          }}
        </tr>
      </template>

      <template v-slot:item.nama="{ item }">
        <tr>
          {{
            item.nama
          }}
        </tr>
      </template>

      <template v-slot:item.nama_marketing="{ item }">
        <tr>
          {{
            item.nama_marketing
          }}
          /
        </tr>
        <tr>
          {{
            item.nama_staff_gudang
          }}
        </tr>
      </template>

      <template v-slot:item.nama_staff_gudang="{ item }">
        <tr style="visibility: hidden">
          {{
            item.nama_staff_gudang
          }}
          /
        </tr>
      </template>

      <template v-slot:item.StatusCetak="{ item }">
        <v-chip :color="getColorStatuscetak(item.cetak)" dark>
          {{ item.StatusCetak }}
        </v-chip>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <!-- Modal Detail -->
          <v-dialog v-model="dialogDetail" max-width="800px">
            <v-card>
              <v-card-title class="mb-1 headermodalstyle">
                <span>Detail Transaksi</span>
              </v-card-title>

              <v-card-text class="fontall pa-1">
                <v-container class="pa-1">
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 150px; font-size: 14px"
                          >
                            No Invoice
                          </th>
                          <td class="text-left" style="font-size: 14px">
                            {{ defaultItem.NoInvoice }}
                          </td>
                        </tr>
                        <tr v-if="inputResi == false">
                          <th class="text-left" style="font-size: 14px">
                            Customer
                          </th>
                          <td class="text-left" style="font-size: 14px">
                            {{ defaultItem.Customer }} ({{
                              defaultItem.TelpCust
                            }})
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Data Pengirim
                          </th>
                          <td class="text-left" style="font-size: 14px">
                            {{ defaultItem.Pengirim }} ({{
                              defaultItem.NoPengirim
                            }})
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Data Tujuan
                          </th>
                          <td class="text-left" style="font-size: 14px">
                            {{ defaultItem.NamaPenerima }} ({{
                              defaultItem.NoPenerima
                            }})
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Alamat Tujuan
                          </th>
                          <td class="text-left" style="font-size: 14px">
                            <tr>
                              {{
                                defaultItem.Alamat
                              }}
                            </tr>
                            <tr>
                              Kec.
                              {{
                                defaultItem.kecamatan
                              }}, Kab.
                              {{
                                defaultItem.kab_kota
                              }},
                              {{
                                defaultItem.propinsi
                              }}
                            </tr>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Expedisi
                          </th>
                          <td class="text-left" style="font-size: 14px">
                            {{ defaultItem.Expedisi }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Status Cetak
                          </th>
                          <td class="text-left" style="font-size: 14px">
                            <v-chip
                              :color="getColorStatuscetak(defaultItem.cetak)"
                              dark
                            >
                              {{ defaultItem.StatusCetak }}
                            </v-chip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-container>
              </v-card-text>
              <v-card-text v-if="inputResi == true" class="fontall py-1 px-5">
                <v-row>
                  <v-col class="pb-1" cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="defaultItem.no_resi"
                      label="Input No Resi"
                      outlined
                      v-on:change="changeNoResi"
                      autofocus
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider class="mx-4"></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  elevation="1"
                  outlined
                  @click="printTrx()"
                  v-if="inputResi == false"
                >
                  <v-icon left> mdi-printer </v-icon>
                  Cetak </v-btn
                ><v-btn
                  color="primary"
                  elevation="1"
                  outlined
                  @click="inputResiApi()"
                  v-if="inputResi == true"
                >
                  <v-icon left> mdi-content-save-all-outline </v-icon>
                  Simpan
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-4" @click="printItem(item)" color="primary">
          mdi-printer
        </v-icon>
        <v-icon class="mr-1" @click="inputResiItem(item)" color="orange">
          mdi-pencil-circle
        </v-icon>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
      class="fontall"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DalamProses",
  data: () => ({
    itemsbr: [
      {
        text: "Transaksi",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Dalam Proses",
        disabled: true,
        href: "breadcrumbs_link_1",
      },
    ],

    formTitle: "Add Item",
    textheader: "",
    value: "add",
    dialog: false,
    dialogDelete: false,
    dialogDetail: false,
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    headers: [
      { text: "No Inv", value: "id" },
      { text: "Tipe Trx", value: "typeTransaksi" },
      { text: "Pembeli", value: "nama" },
      { text: "Penerima", value: "nama_penerima" },
      { text: "Marketing", value: "nama_marketing" },
      // { text: "Gudang", value: "nama_staff_gudang", width: "0%" },
      { text: "Ekspedisi", value: "expedisi" },
      { text: "Status Cetak", value: "StatusCetak" },
      //   { text: "Status", value: "status" },
      // { text: "Tgl Update", value: "updated_at", width: "13%" },
      //   { text: "User ID", value: "username" },
      { text: "Actions", value: "actions", sortable: false, width: "12%" },
    ],
    headersBarang: [
      { text: "Nama Buku", value: "namaBarang" },
      { text: "Jumlah", value: "jumlah" },
      { text: "Diskon", value: "diskon" },
      { text: "Sub total", value: "subtotal", width: "25%" },
    ],
    headerListBuku: [
      { text: "Nama Buku", value: "namaBarang", width: "35%" },
      { text: "Berat", value: "berat", width: "12%" },
      { text: "Harga", value: "harga", width: "18%" },
      { text: "Diskon", value: "diskon", width: "12%" },
      { text: "Jumlah", value: "jumlah", width: "13%" },
      { text: "Actions", value: "actions", sortable: false, width: "10%" },
    ],
    dataobject: [],

    defaultItem: {
      id: "",
      nama: "",
      nama_penerima: "",

      kode_AWO: "",
      gambar: "",
      nama_staff_gudang: "",

      Admin: "",
      Alamat: "",
      BiayaExpedisi: "",
      typeTransaksi: "",
      customerCombobox: "",
      kabROCombobox: "",
      id_alamatkabROCombobox: "",
      textkabROCombobox: "",
      kab_kota_ro_kode: "",
      Customer: "",
      diskonCustomer: "",
      Diskon: "",
      DiskonKodeUnik: "",
      Expedisi: "",
      NamaPenerima: "",
      NoInvoice: "",
      NoPenerima: "",
      NoPengirim: "",
      Pengirim: "",
      SubTotal: "",
      Tanggal: "",
      TelpCust: "",
      TotalHarga: "",
      codeGudang: "",
      itemsTypePromo: "",
      Bank: "",
      Komentar: "",
      Gambar: "",
      listOrder: [],
      tanggal_tempo: "",
      no_resi: "",
    },

    itemsStaffGudang: [],
    itemsStatus: [
      { text: "Reseller", value: "1" },
      { text: "Cust Reguler", value: "0" },
    ],
    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,
    verifikasi: false,

    selectedImage: null,
    dateTempo: false,
    inputResi: false,

    diskonReset: "",
    jenisResellerReset: "",
    kodeAdminTrx: "",
    location: "",
    kode_AWO: "",
    nama_staff_gudang: "",
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.kodeAdminTrx = this.User.kodeAdminTrx;
      this.location = this.User.location;
    }
    this.initialize();
    this.getStaffGudang();
  },

  methods: {
    async initialize() {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetTransaksiManagerGudangBosman?kodeAdminTrx=" +
            this.kodeAdminTrx +
            "&typeTrx=CetakTrx",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataobject = [];
        }
      }
    },
    async getStaffGudang() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetDropdownStaffGudang?location=" + this.location,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.itemsStaffGudang = response.data.data.result;
        } else {
          console.log("Kosong");
          this.itemsStaffGudang = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsStaffGudang = [];
        }
      }
    },
    async detailData() {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetTransaksiBosmanDetail?idOrder=" +
            this.defaultItem.id,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.defaultItem = response.data.data.result;
          this.defaultItem.no_resi = "";
        } else {
          console.log("Kosong");
          //   this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          //   this.dataobject = [];
        }
      }
    },
    async apiPrint() {
      const datapost = {
        idOrder: this.defaultItem.NoInvoice,
        kode_awo: this.kode_AWO,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "EditStaffGudangOrder",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogDetail = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses ubah data";
          this.initialize();
        } else {
          console.log("Kosong");
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async inputResiApi() {
      if (this.defaultItem.no_resi.length == 0) {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Gagal Update, Kolom tidak boleh kosong";
      } else {
        const datapost = {
          idOrder: this.defaultItem.NoInvoice,
          no_resi: this.defaultItem.no_resi,
        };
        console.log(datapost);
        // this.dialogDetail = false;
        try {
          const response = await axios.post(
            this.BaseUrlGet + "InputResiOrder",
            datapost,
            {
              headers: {
                Authorization: `Bearer ` + this.authtoken,
              },
            }
          );
          console.log(response.data.data.result);
          if (response.data.data.result == "success") {
            this.dialogDetail = false;
            this.snackbar = true;
            this.colorsnackbar = "green";
            this.textsnackbar = "Sukses ubah data";
            this.sendEmailApi();
            this.initialize();
          } else {
            console.log("Kosong");
            this.snackbar = true;
            this.colorsnackbar = "red";
            this.textsnackbar = "Gagal ubah data";
          }
        } catch (error) {
          console.error(error);
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            this.$router.push("/");
          } else {
            this.snackbar = true;
            this.colorsnackbar = "red";
            this.textsnackbar = "Gagal ubah data";
          }
        }
      }
    },
    async sendEmailApi() {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "KirimEmailTrx?idOrder=" +
            this.defaultItem.NoInvoice,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.dialogOngkir = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses Kirim email";
          // this.defaultItem = response.data.data.result;
          // if (response.data.data.result.keterangantrace == "success") {
          //   this.manifestShow = true;
          //   this.defaultItem.statuskirim =
          //     response.data.data.result.ResultTrace.summary.status;
          //   this.defaultItem.receiver_name =
          //     response.data.data.result.ResultTrace.summary.receiver_name;
          //   this.defaultItem.destination =
          //     response.data.data.result.ResultTrace.summary.destination;
          //   this.defaultItem.waybill_date =
          //     response.data.data.result.ResultTrace.summary.waybill_date;
          //   var objarray = response.data.data.result.ResultTrace.manifest;
          //   this.manifest = objarray.reverse();
          // } else {
          //   this.manifestShow = false;
          // }
        } else {
          console.log("Kosong");
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
          //   this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
          //   this.dataobject = [];
        }
      }
    },

    detailItem(item) {
      this.defaultItem = Object.assign({}, item);
      console.log(item.nama_staff_gudang);
      this.nama_staff_gudang = item.nama_staff_gudang;
      this.kode_AWO = item.kode_AWO;
      this.verifikasi = this.verifikasiButton(item.code_status);
      this.dialogDetail = true;
      this.detailData("Detail");
    },
    printItem(item) {
      this.defaultItem = Object.assign({}, item);
      console.log(item.nama_staff_gudang);
      this.nama_staff_gudang = item.nama_staff_gudang;
      this.kode_AWO = item.kode_AWO;
      this.verifikasi = this.verifikasiButton(item.code_status);
      this.dialogDetail = true;
      this.detailData("Detail");
      this.inputResi = false;
    },
    inputResiItem(item) {
      this.defaultItem = Object.assign({}, item);
      console.log(item.nama_staff_gudang);
      this.nama_staff_gudang = item.nama_staff_gudang;
      this.kode_AWO = item.kode_AWO;
      this.verifikasi = this.verifikasiButton(item.code_status);
      this.dialogDetail = true;
      this.detailData("Detail");
      this.inputResi = true;
    },
    changeNoResi(val) {
      console.log(val);
      this.defaultItem.no_resi = val;
      this.inputResiApi();

      // this.discSelectedCustomer = val.diskon;
    },

    close() {
      this.dialogDetail = false;
    },
    verifikasiTrx() {
      console.log("test");
      console.log(this.kode_AWO);
      console.log(this.defaultItem.NoInvoice);
      this.apiUpdate();
    },
    printTrx() {
      console.log(this.defaultItem.NoInvoice);
      var str = this.BaseUrlGet;
      window.open(
        str.substring(0, str.length - 4) +
          "ExportCetakAlamat?idOrder=" +
          this.defaultItem.NoInvoice
      );
    },

    zoom(url) {
      // console.log("zooom");
      console.log("Zoom", url);
      this.selectedImage = url;
    },

    verifikasiButton(status) {
      if (status == "4") return false;
      else if (status == "5") return true;
      else if (status == "6") return false;
      else if (status == "7") return false;
      else return false;
    },

    getStatus(status) {
      if (status == "0") return "Customer Reg";
      else return "Reseller";
    },
    getColorStatus(status) {
      if (status == "regular") return "green";
      else if (status == "preOrder") return "blue";
      else if (status == "tempo") return "orange";
      else return "red";
    },
    getColorStatusKonfirmasi(status) {
      if (status == "regular") return "green";
      else if (status == "preOrder") return "blue";
      else if (status == "tempo") return "orange";
      else return "red";
    },
    getColorStatusKonfirmasiAdmin(status) {
      if (status == "4") return "orange";
      else if (status == "5") return "red";
      else if (status == "6") return "purple";
      else if (status == "7") return "purple";
      else return "green";
    },
    getIconStatusAdmin(status) {
      if (status == "4") return "mdi-text-box-search-outline";
      else if (status == "5") return "mdi-text-box-check-outline";
      else if (status == "6") return "mdi-table-search";
      else if (status == "7") return "mdi-package-variant";
      else return "mdi-check-underline-circle";
    },
    getStatusKonfirmasiAdmin(status) {
      if (status == "4") return "Menunggu Keuangan";
      else if (status == "5") return "Konfirmasi Keuangan";
      else if (status == "6") return "Menunggu Gudang";
      else if (status == "7") return "Proses Pengemasan";
      else return "Selesai Pengemasan";
    },
    getTotalHarga(a, b, c, d) {
      var result = 0;
      result = parseInt(a) - parseInt(b) + parseInt(c) - parseInt(d);

      return this.getRupiah(result);
      //
    },

    getRupiah(val) {
      var bilangan = val;

      if (bilangan) {
        var number_string = bilangan.toString(),
          sisa = number_string.length % 3,
          rupiah = number_string.substr(0, sisa),
          ribuan = number_string.substr(sisa).match(/\d{3}/g);

        if (ribuan) {
          var separator = sisa ? "." : "";
          rupiah += separator + ribuan.join(".");
        }

        return "Rp. " + rupiah;
      }
    },

    gettanggal(val) {
      var bulanIndo = [
        "",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Juni",
        "Juli",
        "Agust",
        "Sept",
        "Okt",
        "Nov",
        "Des",
      ];

      var date = val.split(" ")[0];

      var tanggal = date.split("-")[2];
      var bulan = date.split("-")[1];
      var tahun = date.split("-")[0];

      return tanggal + " " + bulanIndo[Math.abs(bulan)] + " " + tahun;
    },
    getjam(val) {
      var time = val.split(" ")[1];

      var jam = time.split(":")[0];
      var menit = time.split(":")[1];

      return jam + ":" + menit;
    },

    getColorStatuscetak(status) {
      if (status == 1) return "green";
      else return "orange";
    },
  },
};
</script>



