<template>
  <div>
    <v-breadcrumbs
      class="breadcrumbsmain fontall"
      :items="itemsbr"
      large
      color="black"
      style="font-weight: bold"
      ><template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template></v-breadcrumbs
    >

    <v-data-table
      :headers="headers"
      :items="dataobject"
      :search="search"
      class="rounded elevation-6 mx-3 pa-1 fontall"
    >
      <template v-slot:item.asal="{ item }">
        <tr>
          {{
            item.asal
          }}
          ({{
            item.nama_asal
          }})
        </tr>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn dark class="mb-2" @click="showAddModal()" color="green">
            <v-icon small>mdi-plus</v-icon> Add Item
          </v-btn>

          <!-- Modal Add/ Edit -->
          <v-dialog v-model="dialog" max-width="800px">
            <v-card>
              <v-card-title class="mb-1 headermodalstyle">
                <span>{{ formTitle }}</span>
              </v-card-title>
              <v-card-text class="pa-0 fontall">
                <v-stepper v-model="e1">
                  <v-stepper-header>
                    <v-stepper-step editable :complete="e1 > 1" step="1">
                      Main Data
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step editable step="3">
                      List Buku
                    </v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-items>
                    <v-stepper-content class="pa-3" step="1">
                      <v-container class="mb-2">
                        <v-row class="mb-3">
                          <h4>Type Transaksi</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row>
                          <v-col class="px-1 py-0" cols="12" sm="12" md="12">
                            <v-select
                              v-model="defaultItem.typeTrx"
                              :items="itemsTypeTrx"
                              item-value="value"
                              item-text="text"
                              label="Pilih Type Transaksi"
                              clearable
                              outlined
                              v-on:change="changeTypeTrx"
                              :rules="[(v) => !!v || 'Field is required']"
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row v-if="showHibah == false" class="mb-3">
                          <h4>Type Customer</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row v-if="showHibah == false">
                          <v-col class="px-1 py-0" cols="12" sm="12" md="12">
                            <v-select
                              v-model="defaultItem.typeAsal"
                              :items="itemsTypeAsal"
                              item-value="value"
                              item-text="text"
                              label="Pilih Type Customer"
                              clearable
                              outlined
                              :rules="[(v) => !!v || 'Field is required']"
                              v-on:change="changeTypeAsal"
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row v-if="showHibah == false">
                          <v-col
                            v-if="dataLain == false"
                            class="px-1 py-0"
                            cols="12"
                            sm="12"
                            md="12"
                          >
                            <v-combobox
                              v-model="defaultItem.customerCombobox"
                              :items="itemsAsal"
                              item-value="id"
                              item-text="nama"
                              label="Pilih Customer"
                              v-on:change="changeCustomer"
                              outlined
                              :rules="[(v) => !!v || 'Field is required']"
                            ></v-combobox>
                          </v-col>
                          <v-col
                            v-if="dataLain == true"
                            class="pb-1"
                            cols="12"
                            sm="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="defaultItem.valueAsal"
                              label="Isi Lain-lain"
                              outlined
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="mb-3">
                          <h4>Keterangan</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row>
                          <v-col class="pb-1" cols="12" sm="12" md="12">
                            <v-text-field
                              v-model="defaultItem.keterangan"
                              label="Isi Keterangan"
                              outlined
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>

                      <v-row class="ma-2">
                        <v-spacer></v-spacer>
                        <v-btn
                          class="mr-1"
                          color="red"
                          elevation="1"
                          @click="close"
                          outlined
                        >
                          <v-icon left> mdi-close-circle-outline </v-icon>
                          Cancel
                        </v-btn>
                        <v-btn
                          elevation="1"
                          outlined
                          color="primary"
                          @click="e1 = 3"
                        >
                          <v-icon left>
                            mdi-chevron-right-circle-outline
                          </v-icon>
                          Next
                        </v-btn>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-content class="pa-3" step="3">
                      <v-container class="mb-2">
                        <v-row class="mb-3">
                          <h4>Pilih Buku</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row>
                          <v-col class="px-1 py-0" cols="12" sm="12" md="12">
                            <v-combobox
                              v-model="defaultItem.itemsBuku"
                              :items="itemsBuku"
                              item-value="id"
                              item-text="judul_buku"
                              label="Pilih Buku"
                              v-on:change="changeBukuSelected"
                              outlined
                              clearable
                            ></v-combobox>
                          </v-col>
                        </v-row>
                        <v-row class="mb-3">
                          <h4>List Buku</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-data-table
                              :headers="headerListBuku"
                              :items="defaultItem.listOrder"
                              class="elevation-3"
                              append-icon="mdi-magnify"
                              :items-per-page="5"
                            >
                              <template v-slot:item.actions="{ item }">
                                <v-icon
                                  class="mr-2"
                                  @click="deletelistitembuku(item)"
                                  color="red"
                                >
                                  mdi-delete
                                </v-icon>
                                <!-- <v-icon @click="deleteItem(item)" color="red"> mdi-delete </v-icon> -->
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-container>

                      <v-row class="ma-2">
                        <v-spacer></v-spacer>
                        <v-btn
                          class="mr-1"
                          color="red"
                          elevation="1"
                          @click="close"
                          outlined
                        >
                          <v-icon left> mdi-close-circle-outline </v-icon>
                          Cancel
                        </v-btn>
                        <v-btn
                          color="success"
                          elevation="1"
                          outlined
                          @click="SaveReturnHibah"
                        >
                          <v-icon left>
                            mdi-checkbox-marked-circle-outline
                          </v-icon>
                          Save
                        </v-btn>
                      </v-row>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-card-text>
              <!-- <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn color="success" elevation="1" outlined @click="save">
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  Save
                </v-btn>
              </v-card-actions> -->
            </v-card>
          </v-dialog>

          <!-- Modal Add Buku -->
          <v-dialog v-model="dialogAddBuku" max-width="400px">
            <v-card>
              <v-card-text class="pa-1 fontall">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <div>
                        <h5>Nama Buku</h5>
                        <h3 class="ml-2">
                          {{ judulBukuTemp }}
                        </h3>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <div>
                        <h5>Harga</h5>
                        <h3 class="ml-2">
                          {{ hargaBukuTemp }}
                        </h3>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <div>
                        <h5>Berat</h5>
                        <h3 class="ml-2">{{ beratBukuTemp }} gram</h3>
                      </div>
                    </v-col>
                    <!-- <v-col cols="12" sm="6" md="6">
                      <div>
                        <h5>Stock Tersedia</h5>
                        <h3 class="ml-2">{{ totalBukuTemp }}</h3>
                      </div>
                    </v-col> -->
                  </v-row>
                  <v-row>
                    <v-col class="pb-1" cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="jumlahBukuTemp"
                        label="Jumlah"
                        outlined
                        type="number"
                        required
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col class="pb-1" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="diskonBukuTemp"
                        label="Diskon"
                        required
                        type="number"
                        outlined
                      ></v-text-field>
                    </v-col> -->
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="closeAddBuku">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn color="success" elevation="1" outlined @click="addBuku">
                  <v-icon left> mdi-plus-circle-outline </v-icon>
                  Add
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Modal Delete -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon @click="deleteItem(item)" color="red"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script src="../public/js/html2canvas.js"></script>

<script>
import axios from "axios";
import html2canvas from "@/js/html2canvas";

export default {
  name: "ReturnHibah",
  data: () => ({
    itemsbr: [
      {
        text: "Utilities",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Return Hibah",
        disabled: true,
        href: "breadcrumbs_link_1",
      },
    ],

    formTitle: "Add Item",
    value: "add",
    dialog: false,
    dialogDelete: false,
    dialogDetail: false,
    dialogPreview: false,
    dialogAddBuku: false,
    dialogOngkir: false,
    dialogShowEdit: false,
    dialogShowVerifikasi: false,
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    BaseUrlUpload: "",
    BaseUrl: "",
    BaseUrlDBImage: "",
    headers: [
      { text: "type", value: "type" },
      { text: "Nama Barang", value: "judul_buku" },
      { text: "jumlah", value: "jumlah" },
      { text: "Asal", value: "asal" },
      { text: "keterangan", value: "keterangan" },
      { text: "Tanggal", value: "created_at" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headersBarang: [
      { text: "Nama Buku", value: "namaBarang" },
      { text: "Jumlah", value: "jumlah" },
      { text: "Diskon", value: "diskon" },
      { text: "Sub total", value: "subtotal", width: "25%" },
    ],
    headerListBuku: [
      { text: "Nama Buku", value: "namaBarang", width: "55%" },
      //   { text: "Berat", value: "berat", width: "12%" },
      //   { text: "Harga", value: "harga", width: "18%" },
      //   { text: "Diskon", value: "diskon", width: "12%" },
      { text: "Jumlah", value: "jumlah", width: "25%" },
      { text: "Actions", value: "actions", sortable: false, width: "15%" },
    ],
    dataobject: [],

    defaultItem: {
      id: "",
      idOrder: "",
      nama: "",
      nama_penerima: "",

      gambar: "",

      Admin: "",
      Alamat: "",
      BiayaExpedisi: "",
      typeTransaksi: "",
      customerCombobox: "",
      kabROCombobox: "",
      id_alamatkabROCombobox: "",
      textkabROCombobox: "",
      kab_kota_ro_kode: "",
      Customer: "",
      diskonCustomer: "",
      Diskon: "",
      DiskonKodeUnik: "",
      Expedisi: "",
      NamaPenerima: "",
      NoInvoice: "",
      NoPenerima: "",
      NoPengirim: "",
      Pengirim: "",
      SubTotal: "",
      Tanggal: "",
      TelpCust: "",
      TotalHarga: "",
      codeGudang: "",
      listOrder: [],
      typeAsal: "",
      valueAsal: "",
      nama_asal: "",
      itemsBuku: "",
      typeTrx: "",
      keterangan: "",
    },

    itemsAsal: [],
    itemsCustomer: [],
    itemsGudang: [],
    itemsBuku: [],
    itemsTypeAsal: [
      { text: "Customer", value: "1" },
      { text: "Gudang", value: "2" },
      { text: "Lain-lain", value: "3" },
    ],
    itemsTypeTrx: [
      { text: "Return", value: "return" },
      { text: "Hibah", value: "hibah" },
    ],

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,

    dataLain: false,

    loadOngkir: false,
    loadMessage: false,
    successMessage: false,

    e1: 1,
    stepperdone: false,
    showDisconForm: false,
    discSelectedCustomer: 0,
    nameTextKabROSelected: "",
    valueResetBukuForm: false,
    valueAddForm: false,
    codeBukuTemp: "",
    judulBukuTemp: "",
    hargaBukuTemp: "",
    beratBukuTemp: "",
    jumlahBukuTemp: 0,
    diskonBukuTemp: 0,
    totalBukuTemp: 0,
    beratShowOngkir: 0,

    showHibah: false,

    ongkirTemp: "",

    kodeAdminTrx: "",
    location: "",
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlUpload = localStorage.getItem("BaseUrlUpload");
    this.BaseUrl = localStorage.getItem("BaseUrl");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.BaseUrlDBImage = localStorage.getItem("BaseUrlDBImage");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.kodeAdminTrx = this.User.kodeAdminTrx;
      this.location = this.User.location;
    }
    this.initialize();
    this.getCustommer();
    this.getGudang();
    this.GetBarang();
  },

  methods: {
    async initialize() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetReturnHibah?codeLocation=" + this.location,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataobject = [];
        }
      }
    },

    async getCustommer() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetAllCustomerAdm",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsCustomer = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.itemsCustomer = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsCustomer = [];
        }
      }
    },
    async getGudang() {
      try {
        const response = await axios.get(this.BaseUrlGet + "GetAllGudangAdm", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsGudang = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.itemsGudang = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsGudang = [];
        }
      }
    },

    async GetBarang() {
      if (this.location == 1) {
        var code_gudang = "Gd_001";
      } else {
        var code_gudang = "Gd_002";
      }
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetBarang?CodeGudang=" +
            code_gudang +
            "&type=non_promo",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.itemsBuku = response.data.data.result;
        } else {
          console.log("Kosong");
          this.itemsBuku = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsBuku = [];
        }
      }
    },
    async verifDelete() {
      const datapost = {
        id: this.defaultItem.idOrder,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "DeleteReturnHibah",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result.data);
        if (response.data.data.result == "success") {
          this.dialogDelete = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses hapus data";
          this.initialize();
        } else {
          this.dialogDelete = false;
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },
    async addData() {
      //   console.log(this.defaultItem.typeTrx);
      //   console.log(this.defaultItem.typeAsal);
      //   console.log(this.defaultItem.valueAsal);
      //   console.log(this.defaultItem.listOrder);
      //   console.log(this.defaultItem.keterangan);
      const datapost = {
        kodeAdminTrx: this.kodeAdminTrx,
        type: this.defaultItem.typeTrx,
        Asal: this.defaultItem.valueAsal,
        nama_asal: this.defaultItem.nama_asal,
        keterangan: this.defaultItem.keterangan,
        type_asal: this.defaultItem.typeAsal,
        listOrder: JSON.stringify(this.defaultItem.listOrder),
        code_perusahaan: this.location,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "AddReturnHibah",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogOngkir = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },

    changeTypeTrx(val) {
      if (val == "hibah") {
        this.showHibah = true;
        this.defaultItem.typeAsal = "2";
        var code_asal = "";
        var nama_asal_val = "";
        if (this.location == 2) {
          code_asal = "Gd_002";
          nama_asal_val = "Jakarta";
        } else {
          code_asal = "Gd_001";
          nama_asal_val = "Reguler";
        }
        this.defaultItem.valueAsal = code_asal;
        this.defaultItem.nama_asal = nama_asal_val;
      } else {
        this.showHibah = false;
        this.defaultItem.typeAsal = "";
        this.defaultItem.valueAsal = "";
        this.defaultItem.nama_asal = "";
      }
    },
    changeTypeAsal(val) {
      console.log(val);
      if (val == "1") {
        this.itemsAsal = this.itemsCustomer;
        this.dataLain = false;
      } else if (val == "2") {
        this.itemsAsal = this.itemsGudang;
        this.dataLain = false;
      } else {
        this.dataLain = true;
        this.defaultItem.valueAsal = "";
        this.defaultItem.nama_asal = "-";
      }
      // this.discSelectedCustomer = val.diskon;
    },
    changeCustomer(val) {
      console.log(val);
      this.defaultItem.valueAsal = val.id;
      this.defaultItem.nama_asal = val.nama;
      this.showDisconForm = true;
    },
    changeGudangSelected(val) {
      console.log(val);
      this.valueAddForm = false;
      this.valueResetBukuForm = true;
      this.defaultItem.codeGudang = val;
    },
    resetbuku() {
      this.valueAddForm = true;
      this.valueResetBukuForm = false;
      this.defaultItem.codeGudang = "";
      this.defaultItem.listOrder = [];
    },
    changeBukuSelected(val) {
      console.log(val);
      this.codeBukuTemp = val.id;
      this.judulBukuTemp = val.judul_buku;
      this.hargaBukuTemp = val.harga;
      this.beratBukuTemp = val.berat;
      this.totalBukuTemp = val.total;
      this.dialogAddBuku = true;
    },
    // pilihmanual() {
    //   console.log(this.defaultItem.BiayaExpedisi);
    //   console.log(this.defaultItem.Expedisi);
    // },

    addBuku() {
      // console.log(this.codeBukuTemp);
      // console.log(this.jumlahBukuTemp);
      // console.log(this.diskonBukuTemp);
      if (this.jumlahBukuTemp > 0) {
        var listmenu = this.defaultItem.listOrder;
        var result = false;
        for (let n = 0; n < listmenu.length; n++) {
          if (listmenu[n].codeBarang == this.codeBukuTemp) {
            result = true;
            break;
          }
        }

        if (result == false) {
          var bukuset = {
            berat: "",
            codeBarang: "",
            diskon: "",
            harga: "",
            jumlah: "",
            namaBarang: "",
          };

          bukuset.berat = this.beratBukuTemp;
          bukuset.codeBarang = this.codeBukuTemp;
          bukuset.diskon = this.diskonBukuTemp;
          bukuset.harga = this.hargaBukuTemp;
          bukuset.jumlah = this.jumlahBukuTemp;
          bukuset.namaBarang = this.judulBukuTemp;

          this.defaultItem.listOrder.push(bukuset);

          this.dialogAddBuku = false;
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Buku Sudah Ada Bro..!!!";
        }
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Jumlah Buku Tidak boleh 0..!!!";
      }
    },
    async deletelistitembuku(item) {
      this.editedIndex = await this.defaultItem.listOrder.indexOf(item);
      this.editedItem = await Object.assign({}, item);
      // console.log(this.editedIndex);
      // console.log(this.editedItem);
      this.defaultItem.listOrder.splice(this.editedIndex, 1);
    },

    showAddModal() {
      this.value = "add";
      this.valueAddForm = true;
      this.formTitle = "Create";
      this.defaultItem.customerCombobox = { idCust: "", nama: "" };
      this.showDisconForm = false;
      this.resetForm();
      this.e1 = 1;
      this.dialog = true;
    },

    deleteItem(item) {
      this.defaultItem = Object.assign({}, item);
      console.log(item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.verifDelete();
      this.closeDelete();
    },

    resetForm() {
      this.defaultItem.idOrder = "";
      this.defaultItem.Admin = "";
      this.defaultItem.Alamat = "";
      this.defaultItem.BiayaExpedisi = 0;
      this.defaultItem.typeTransaksi = "";
      this.defaultItem.customerCombobox = "";
      this.defaultItem.kabROCombobox = "";
      this.defaultItem.id_alamatkabROCombobox = "";
      this.defaultItem.textkabROCombobox = "";
      this.defaultItem.kab_kota_ro_kode = "";
      this.defaultItem.Customer = "";
      this.defaultItem.diskonCustomer = "";
      this.defaultItem.Diskon = "";
      this.defaultItem.DiskonKodeUnik = "";
      this.defaultItem.Expedisi = "";
      this.defaultItem.NamaPenerima = "";
      this.defaultItem.NoInvoice = "";
      this.defaultItem.NoPenerima = "";
      this.defaultItem.NoPengirim = "";
      this.defaultItem.Pengirim = "";
      this.defaultItem.SubTotal = "";
      this.defaultItem.Tanggal = "";
      this.defaultItem.TelpCust = "";
      this.defaultItem.TotalHarga = "";
      this.defaultItem.codeGudang = "";
      this.defaultItem.listOrder = [];
      //   this.itemsBuku = [];
      this.dateTempo = false;
    },

    close() {
      this.dialog = false;
      this.dialogDetail = false;
      this.dialogAddBuku = false;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    closeAddBuku() {
      this.dialogAddBuku = false;
    },
    closeVerification() {
      this.dialogShowVerifikasi = false;
    },
    SaveReturnHibah() {
      //   console.log(this.defaultItem.typeTrx);
      //   console.log(this.defaultItem.typeAsal);
      //   console.log(this.defaultItem.valueAsal);
      //   console.log(this.defaultItem.nama_asal);
      //   console.log(this.defaultItem.listOrder);
      //   console.log(this.defaultItem.keterangan);

      if (
        this.defaultItem.typeTrx.length != 0 &&
        this.defaultItem.typeAsal.length != 0 &&
        this.defaultItem.valueAsal.length != 0 &&
        this.defaultItem.nama_asal.length != 0 &&
        this.defaultItem.keterangan.length != 0 &&
        this.defaultItem.listOrder.length != 0
      ) {
        this.addData();
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Kolom tidak boleh ada yang kosong";
      }
    },

    getStatus(status) {
      if (status == "0") return "Customer Reg";
      else return "Reseller";
    },
    getColorStatus(status) {
      if (status == "regular") return "green";
      else if (status == "preOrder") return "blue";
      else if (status == "tempo") return "orange";
      else return "red";
    },
    getTotalHarga(a, b, c, d) {
      var result = 0;
      result = parseInt(a) - parseInt(b) + parseInt(c) - parseInt(d);

      return this.getRupiah(result);
      //
    },

    getRupiah(val) {
      var bilangan = val;

      if (bilangan) {
        var number_string = bilangan.toString(),
          sisa = number_string.length % 3,
          rupiah = number_string.substr(0, sisa),
          ribuan = number_string.substr(sisa).match(/\d{3}/g);

        if (ribuan) {
          var separator = sisa ? "." : "";
          rupiah += separator + ribuan.join(".");
        }

        return "Rp. " + rupiah;
      }
    },
  },
};
</script>
