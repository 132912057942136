<template>
  <div>
    <v-breadcrumbs
      class="breadcrumbsmain fontall"
      :items="itemsbr"
      large
      color="black"
      style="font-weight: bold"
      ><template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template></v-breadcrumbs
    >

    <v-data-table
      :headers="headers"
      :items="dataobject"
      :search="search"
      class="rounded elevation-6 mx-3 pa-1 fontall"
    >
      <template v-slot:item.typeTransaksi="{ item }">
        <v-chip :color="getColorStatus(item.typeTransaksi)" dark>
          {{ item.typeTransaksi }}
        </v-chip>
      </template>

      <template v-slot:item.total="{ item }">
        {{
          getTotalHarga(
            item.total_harga,
            item.totalDiskon,
            item.biaya_expedisi,
            item.diskonKodeUnik
          )
        }}
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <!-- Modal Detail -->
          <v-dialog v-model="dialogDetail" max-width="700px">
            <v-card>
              <v-card-title class="headermodalstyle">
                <span>Detail Invoice</span>
              </v-card-title>

              <v-card-text class="fontall pa-1">
                <v-container>
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 150px; font-size: 14px"
                          >
                            No Invoice
                          </th>
                          <td class="text-left" style="font-size: 14px">
                            {{ defaultItem.NoInvoice }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Customer
                          </th>
                          <td class="text-left" style="font-size: 14px">
                            {{ defaultItem.Customer }} ({{
                              defaultItem.TelpCust
                            }})
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Data Pengirim
                          </th>
                          <td class="text-left" style="font-size: 14px">
                            {{ defaultItem.Pengirim }} ({{
                              defaultItem.NoPengirim
                            }})
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Data Tujuan
                          </th>
                          <td class="text-left" style="font-size: 14px">
                            {{ defaultItem.NamaPenerima }} ({{
                              defaultItem.NoPenerima
                            }})
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Alamat Tujuan
                          </th>
                          <td class="text-left" style="font-size: 14px">
                            <tr>
                              {{
                                defaultItem.Alamat
                              }}
                            </tr>
                            <tr>
                              Kec.
                              {{
                                defaultItem.kecamatan
                              }}, Kab.
                              {{
                                defaultItem.kab_kota
                              }},
                              {{
                                defaultItem.propinsi
                              }}
                            </tr>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <v-data-table
                    :headers="headersBarang"
                    :items="defaultItem.listOrder"
                    :search="search"
                    class="rounded elevation-1 mx-1 pa-1 my-2"
                    hide-default-footer
                    disable-pagination
                  >
                    <template v-slot:item.subtotal="{ item }">
                      {{ getRupiah(item.subtotal) }}
                    </template>
                  </v-data-table>
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Sub Total
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ getRupiah(defaultItem.SubTotal) }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Diskon
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ getRupiah(defaultItem.Diskon) }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Diskon Kode Unik
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ getRupiah(defaultItem.DiskonKodeUnik) }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Expedisi ({{ defaultItem.Expedisi }})
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ getRupiah(defaultItem.BiayaExpedisi) }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Total Harga
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ getRupiah(defaultItem.TotalHarga) }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Type Transaksi
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.typeTransaksi }}
                          </th>
                        </tr>
                        <tr v-if="dateTempo == true">
                          <th class="text-left" style="font-size: 14px">
                            Jatuh Tempo
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.tanggal_tempo }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Bank
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.Bank }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Komentar
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.Komentar }}
                          </th>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <v-container>
                    <v-row justify="center">
                      <div v-if="selectedImage" max-width="85vw">
                        <img
                          :src="selectedImage"
                          alt=""
                          width="100%"
                          @click.stop="selectedImage = null"
                        />
                        <hr />
                      </div>
                      <v-col v-if="selectedImage == null" cols="12" md="12">
                        <v-card
                          class="d-flex align-center"
                          elevation="2"
                          height="450"
                          width="450"
                        >
                          <v-img
                            v-bind:src="defaultItem.Gambar"
                            class="my-2 mx-1 mb-4"
                            max-height="450"
                            contain
                            v-on:click="zoom(defaultItem.Gambar)"
                          ></v-img>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-container>
              </v-card-text>
              <v-divider class="mx-4"></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue" elevation="1" outlined @click="print">
                  <v-icon left> mdi-printer-check </v-icon>
                  Cetak Invoice
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Modal Preview -->
          <v-dialog v-model="dialogPreview" max-width="900px">
            <v-card>
              <v-card-title class="headermodalstyle">
                <span>Preview Invoie</span>
              </v-card-title>
              <div class="text-center fontprint" style="">
                <h1 id="cap1" class="h1 d-none">INVOICE AQWAM</h1>
                <div
                  id="cap2"
                  class="container mb-5 d-none"
                  style="width: 100%"
                >
                  <input
                    type="button"
                    class="btn btn-success float-right mx-2"
                    onclick="printDiv('capture')"
                    value="Cetak Invoice PDF"
                  />
                  <button
                    id="download"
                    class="btn btn-success float-right mx-2"
                  >
                    Cetak Invoice PNG
                  </button>
                  <br style="clear: both" />
                  <br style="clear: both" />
                  <br style="clear: both" />
                </div>
                <div
                  class="container-fluid pa-2 mt-5 mr-2"
                  style="overflow: auto"
                >
                  <div style="width: 100%" id="canvas" />
                </div>
              </div>
              <div style="overflow: auto" class="mr-2">
                <div
                  id="capture"
                  class="pa-2 ma-2"
                  style="transform: scale(0.85)"
                >
                  <div class="printtable mr-2" style="padding: 1px !important">
                    <table
                      style="width: 100% !important"
                      class="fontprint mb-1"
                    >
                      <tr>
                        <!-- nama store -->
                        <td rowspan="3">
                          <img
                            src="/images/aqwamhitam.png"
                            class="mr-auto"
                            style="margin-left: 10px !important; width: 150px"
                          />
                        </td>
                        <!-- Tanggal -->
                        <td>
                          <p style="margin: 5px 0px 2px"><b>Tanggal</b></p>
                          <p style="margin-bottom: 10px">
                            {{ defaultItem.Tanggal }}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <!-- No Invoice -->
                        <td>
                          <p style="margin: 1px 0px 2px">
                            <b>Nomor Invoice</b>
                          </p>
                          <p style="margin-bottom: 10px">
                            {{ defaultItem.NoInvoice }}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <!-- nama admin -->
                          <p style="margin: 1px 0px 2px"><b>Nama Admin</b></p>
                          <p style="margin-bottom: 10px">
                            {{ defaultItem.Admin }}
                          </p>
                        </td>
                      </tr>
                      <tr class="fontprint">
                        <td>
                          <p style="margin: 1px 0px 1px 10px !important">
                            Assalamu'alaikum Warahmatullahi Wabarakatuh
                          </p>
                          <!-- nama pembeli -->
                          <p style="margin: 1px 0px 1px 10px !important">
                            <b>Kepada {{ defaultItem.Customer }}</b>
                          </p>
                          <!-- Terimakasih -->
                          <p style="margin: 1px 0px 1px 10px !important">
                            Terimakasih sudah berbelanja di AQWAM STORE, Berikut
                            rincian dari orderan anda :
                          </p>
                        </td>
                        <!-- keterangan bayar -->
                        <td><h3 style="color: red">BELUM DIBAYAR</h3></td>
                      </tr>
                    </table>
                    <table
                      class="table margininvoicebot2"
                      style="
                        margin: 5px 0px 0px 0px !important;
                        padding: 0px 1px 0px 2px !important;
                      "
                    >
                      <tr>
                        <th class="font-weight-bold">Nama Produk</th>
                        <th class="font-weight-bold text-center">Jumlah</th>
                        <th class="font-weight-bold text-center">Berat</th>
                        <th class="font-weight-bold text-center">Harga</th>
                        <th class="font-weight-bold text-center">Diskon</th>
                        <th class="font-weight-bold text-center">SubTotal</th>
                      </tr>
                      <!-- <tr>
                        <td>Fikih Empat Mazhab Jilid II</td>
                        <td class="text-center">1</td>
                        <td class="text-center">1kg</td>
                        <td class="text-right">Rp 179.000</td>
                        <td class="text-center">0%</td>
                        <td class="text-right">Rp 179.000</td>
                      </tr> -->
                      <tr
                        v-for="vallist in defaultItem.listOrder"
                        :key="vallist.codeBarang"
                      >
                        <td>
                          <h4>
                            {{ vallist.namaBarang }}
                          </h4>
                          <li
                            v-for="item in vallist.listbuku"
                            :key="item.judul_buku"
                          >
                            {{ item.judul_buku }}
                          </li>
                        </td>
                        <td class="text-center">{{ vallist.jumlah }}</td>
                        <td class="text-center">{{ vallist.berat }} kg</td>
                        <td class="text-right">
                          {{ getRupiah(vallist.harga) }}
                        </td>
                        <td class="text-center">{{ vallist.diskon }}%</td>
                        <td class="text-right">
                          {{ getRupiah(vallist.subtotal) }}
                        </td>
                      </tr>
                      <tr class="border-top">
                        <td
                          class="font-weight-bold text-weight-bold"
                          colspan="2"
                        >
                          Jumlah
                        </td>
                        <td class="text-center"></td>
                        <td class="text-right"></td>
                        <td class="text-right"></td>
                        <td class="text-right">
                          {{ getRupiah(defaultItem.SubTotal) }}
                        </td>
                      </tr>
                      <!-- ekspedisi -->
                      <tr>
                        <td class="font-weight-bold" colspan="5">
                          Kurir - {{ defaultItem.Expedisi }}
                        </td>
                        <td class="text-right">
                          {{ getRupiah(defaultItem.BiayaExpedisi) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold" colspan="5">Diskon</td>
                        <td class="text-right">
                          {{ getRupiah(defaultItem.Diskon) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold" colspan="5">
                          Diskon Kode Unik
                        </td>
                        <td class="text-right">
                          {{ getRupiah(defaultItem.DiskonKodeUnik) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">TOTAL</td>
                        <td class="font-weight-bold"></td>
                        <td class="font-weight-bold"></td>
                        <td class="font-weight-bold"></td>
                        <td class="font-weight-bold"></td>
                        <td class="font-weight-bold text-right">
                          {{ getRupiah(defaultItem.TotalHarga) }}
                        </td>
                      </tr>
                    </table>
                    <hr class="border-top m-2" />
                    <table class="table table-sm-responsive table-borderless">
                      <tr>
                        <td
                          class="font-weight-bold margininvoicebot1"
                          style="width: 350px"
                        >
                          <p class="margininvoicebot1">Rekening Pembayaran</p>
                          <p class="margininvoicebot1">
                            - BCA 7850875230 an Ismail Huda
                          </p>
                          <p class="margininvoicebot1">
                            - BNI 1122644123 an Ismail Huda
                          </p>
                          <p class="margininvoicebot1">
                            - Mandiri 1380018169503 an Ismail Huda
                          </p>
                          <p class="margininvoicebot1">
                            - BRI 033401087104503 an Ismail Huda
                          </p>
                          <p>Semoga Allah Memberi Keberkahan</p>
                          <p
                            style="border: 2px solid red; text-align: justify"
                            class="notedprint h5 rounded"
                          >
                            <span class="font-weight-bolder">NOTE :</span> Untuk
                            mendukung kecepatan dan ketepatan pengiriman order,
                            harap transfer
                            <span class="font-weight-bolder"
                              >sesuai dengan nominal invoice</span
                            >, melalui
                            <span class="font-weight-bolder"
                              >satu rekening bank</span
                            >
                            yang dipilih.
                          </p>
                        </td>
                        <!-- </tr>
          <tr> -->
                        <td class="font-weight-bold" style="width: 120px">
                          Detail Pengiriman
                        </td>
                        <td>
                          <p class="font-weight-bold margininvoicebot1">
                            #Pengirim'
                          </p>
                          <p class="margininvoicebot2">
                            {{ defaultItem.Pengirim }} ({{
                              defaultItem.NoPengirim
                            }})
                          </p>
                          <p class="font-weight-bold margininvoicebot1">
                            #Tujuan '
                          </p>
                          <p class="margininvoicebot1">
                            {{ defaultItem.NamaPenerima }} ({{
                              defaultItem.NoPenerima
                            }})
                          </p>
                          <p class="margininvoicebot1">
                            {{ defaultItem.Alamat }}
                          </p>
                          <p class="margininvoicebot1">
                            Kec. {{ defaultItem.kecamatan }}, Kab.
                            {{ defaultItem.kab_kota }},
                            {{ defaultItem.propinsi }}
                          </p>
                          <!-- <p>{{ defaultItem.Alamat }}</p> -->
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <!-- </v-container> -->
              <v-card-actions>
                <v-btn
                  class="d-sm-none"
                  color="blue"
                  elevation="1"
                  outlined
                  @click="printPdf"
                >
                  <v-icon left> mdi-printer-check </v-icon>
                  Cetak PDF
                </v-btn>
                <v-btn
                  class="d-sm-none"
                  color="blue"
                  elevation="1"
                  outlined
                  @click="printImage"
                >
                  <v-icon left> mdi-printer-check </v-icon>
                  Cetak Image
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  class="d-none d-sm-block"
                  color="blue"
                  elevation="1"
                  outlined
                  @click="printPdf"
                >
                  <v-icon left> mdi-printer-check </v-icon>
                  Cetak PDF
                </v-btn>
                <v-btn
                  class="d-none d-sm-block"
                  color="blue"
                  elevation="1"
                  outlined
                  @click="printImage"
                >
                  <v-icon left> mdi-printer-check </v-icon>
                  Cetak Image
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Modal Edit Dialog -->
          <v-dialog v-model="dialogShowEdit" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >What you want to edit?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn outlined color="blue" @click="showEditModal"
                  >Edit Order</v-btn
                >
                <v-btn outlined color="green" @click="showEditVerifikasi"
                  >Edit Bayar</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Modal Add/ Edit -->
          <v-dialog v-model="dialog" max-width="800px">
            <v-card>
              <v-card-title class="mb-1 headermodalstyle">
                <span>{{ formTitle }}</span>
              </v-card-title>
              <v-card-text class="pa-0 fontall">
                <v-stepper v-model="e1">
                  <v-stepper-header>
                    <v-stepper-step editable :complete="e1 > 1" step="1">
                      Main Data
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step editable :complete="e1 > 2" step="2">
                      Data Pembeli
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step editable step="3">
                      List Buku
                    </v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-items>
                    <v-stepper-content class="pa-3" step="1">
                      <v-container class="mb-2">
                        <v-row class="mb-3">
                          <h4>Type Transaksi</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row>
                          <v-col class="px-1 py-0" cols="12" sm="12" md="12">
                            <v-select
                              v-model="defaultItem.typeTransaksi"
                              :items="itemsTrx"
                              item-value="value"
                              item-text="text"
                              label="Pilih Type Transaksi"
                              clearable
                              outlined
                              :rules="[(v) => !!v || 'Field is required']"
                              v-on:change="changeTypeTrx"
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            v-if="dateTempo == true"
                            class="px-1 py-0"
                            cols="12"
                            sm="12"
                            md="12"
                          >
                            <v-menu
                              v-model="menu2"
                              transition="scale-transition"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="datepicker"
                                  slot="activator"
                                  label="Tanggal Jatuh Tempo"
                                  outlined
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :rules="[(v) => !!v || 'Field is required']"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="datepicker"
                                @input="menu2 = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                        <v-row class="mb-3">
                          <h4>Customer</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row>
                          <v-col class="px-1 py-0" cols="12" sm="12" md="12">
                            <v-combobox
                              v-model="defaultItem.customerCombobox"
                              :items="itemsCustomer"
                              item-value="idCust"
                              item-text="nama"
                              label="Pilih Customer"
                              v-on:change="changeCustomer"
                              outlined
                              :rules="[(v) => !!v || 'Field is required']"
                            ></v-combobox>
                          </v-col>
                          <v-col
                            v-if="showDisconForm == true"
                            class="pa-1"
                            cols="12"
                            sm="12"
                            md="12"
                          >
                            <v-alert
                              border="left"
                              color="green"
                              dense
                              type="info"
                              >Diskon Customer :
                              {{ discSelectedCustomer }} %</v-alert
                            >
                          </v-col>
                        </v-row>
                      </v-container>

                      <v-row class="ma-2">
                        <v-spacer></v-spacer>
                        <v-btn
                          class="mr-1"
                          color="red"
                          elevation="1"
                          @click="close"
                          outlined
                        >
                          <v-icon left> mdi-close-circle-outline </v-icon>
                          Cancel
                        </v-btn>
                        <v-btn
                          elevation="1"
                          outlined
                          color="primary"
                          @click="e1 = 2"
                        >
                          <v-icon left>
                            mdi-chevron-right-circle-outline
                          </v-icon>
                          Next
                        </v-btn>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-content class="pa-3" step="2">
                      <v-container class="mb-2">
                        <v-row class="mb-3">
                          <h4>Data Pengirim</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row>
                          <v-col class="px-1 py-0" cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="defaultItem.Pengirim"
                              label="Nama Pengirim"
                              outlined
                              :rules="[(v) => !!v || 'Field is required']"
                            ></v-text-field>
                          </v-col>
                          <v-col class="px-1 py-0" cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="defaultItem.NoPengirim"
                              label="No Telp Pengirim"
                              outlined
                              :rules="[(v) => !!v || 'Field is required']"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="mb-3">
                          <h4>Data Penerima</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row>
                          <v-col class="px-1 py-0" cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="defaultItem.NamaPenerima"
                              label="Nama Penerima"
                              outlined
                              :rules="[(v) => !!v || 'Field is required']"
                            ></v-text-field>
                          </v-col>
                          <v-col class="px-1 py-0" cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="defaultItem.NoPenerima"
                              label="No Telp Penerima"
                              outlined
                              :rules="[(v) => !!v || 'Field is required']"
                            ></v-text-field>
                          </v-col>
                          <v-col class="px-1 py-0" cols="12" sm="12" md="12">
                            <v-textarea
                              v-model="defaultItem.Alamat"
                              label="Alamat"
                              rows="3"
                              outlined
                              :rules="[(v) => !!v || 'Field is required']"
                            ></v-textarea>
                          </v-col>
                          <v-col class="px-1 py-0" cols="12" sm="12" md="12">
                            <v-combobox
                              v-model="defaultItem.kabROCombobox"
                              :items="itemsKabRO"
                              item-value="id_alamat"
                              item-text="text"
                              label="Pilih Kec/Kab Tujuan"
                              outlined
                              :rules="[(v) => !!v || 'Field is required']"
                            ></v-combobox>
                          </v-col>
                        </v-row>
                      </v-container>

                      <v-row class="ma-2">
                        <v-spacer></v-spacer>
                        <v-btn
                          class="mr-1"
                          color="red"
                          elevation="1"
                          @click="close"
                          outlined
                        >
                          <v-icon left> mdi-close-circle-outline </v-icon>
                          Cancel
                        </v-btn>
                        <v-btn
                          elevation="1"
                          outlined
                          color="primary"
                          @click="e1 = 3"
                        >
                          <v-icon left>
                            mdi-chevron-right-circle-outline
                          </v-icon>
                          Next
                        </v-btn>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-content class="pa-3" step="3">
                      <v-container class="mb-2">
                        <v-row class="mb-3">
                          <h4>Pilih Buku</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                          <v-btn
                            v-if="valueResetBukuForm == true"
                            color="red"
                            @click="resetbuku"
                            outlined
                          >
                            <v-icon left> mdi-close-circle-outline </v-icon>
                            Reset
                          </v-btn>
                        </v-row>
                        <v-row v-if="valueAddForm == true">
                          <v-col class="px-1 py-0" cols="12" sm="12" md="12">
                            <v-select
                              v-model="defaultItem.codeGudang"
                              :items="itemsTypeGudang"
                              item-value="codeGudang"
                              item-text="text"
                              label="Pilih Gudang"
                              v-on:change="changeGudangSelected"
                              clearable
                              outlined
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row v-if="valueAddForm == false">
                          <v-col class="px-1 py-0" cols="12" sm="12" md="12">
                            <v-select
                              v-model="defaultItem.itemsTypePromo"
                              :items="itemsTypePromo"
                              item-value="value"
                              item-text="text"
                              label="Pilih Type Promo"
                              clearable
                              outlined
                              v-on:change="changeTypePromo"
                            ></v-select>
                          </v-col>
                          <v-col class="px-1 py-0" cols="12" sm="12" md="12">
                            <v-combobox
                              v-model="defaultItem.itemsBuku"
                              :items="itemsBuku"
                              item-value="id"
                              item-text="judul_buku"
                              label="Pilih Buku"
                              v-on:change="changeBukuSelected"
                              outlined
                              clearable
                            ></v-combobox>
                          </v-col>
                        </v-row>
                        <v-row class="mb-3">
                          <h4>List Buku</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-data-table
                              :headers="headerListBuku"
                              :items="defaultItem.listOrder"
                              class="elevation-3"
                              append-icon="mdi-magnify"
                              :items-per-page="5"
                            >
                              <template v-slot:item.actions="{ item }">
                                <v-icon
                                  class="mr-2"
                                  @click="deletelistitembuku(item)"
                                  color="red"
                                >
                                  mdi-delete
                                </v-icon>
                                <!-- <v-icon @click="deleteItem(item)" color="red"> mdi-delete </v-icon> -->
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-container>

                      <v-row class="ma-2">
                        <v-spacer></v-spacer>
                        <v-btn
                          class="mr-1"
                          color="red"
                          elevation="1"
                          @click="close"
                          outlined
                        >
                          <v-icon left> mdi-close-circle-outline </v-icon>
                          Cancel
                        </v-btn>
                        <v-btn
                          color="success"
                          elevation="1"
                          outlined
                          @click="verifikasiOngkir"
                        >
                          <v-icon left>
                            mdi-checkbox-marked-circle-outline
                          </v-icon>
                          Save
                        </v-btn>
                      </v-row>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-card-text>
              <!-- <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn color="success" elevation="1" outlined @click="save">
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  Save
                </v-btn>
              </v-card-actions> -->
            </v-card>
          </v-dialog>

          <!-- Modal Verifikasi -->
          <v-dialog v-model="dialogShowVerifikasi" max-width="800px">
            <v-card>
              <v-card-title class="mb-1 headermodalstyle">
                <span>Verifikasi Invoice</span>
              </v-card-title>
              <v-card-text class="fontall pa-1">
                <v-container>
                  <v-row class="mb-1 ml-1">
                    <h4>Detail Invoice</h4>
                    <v-divider class="mx-2 mt-3"></v-divider>
                  </v-row>
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 150px; font-size: 14px"
                          >
                            No Invoice
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.idOrder }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Customer
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.nama }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Data Tujuan
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.nama_penerima }}
                          </th>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-row class="mb-1 mt-2 ml-1">
                    <h4>Verifikasi</h4>
                    <v-divider class="mx-2 mt-3"></v-divider>
                  </v-row>
                  <v-row class="ma-1 pa-1">
                    <v-col class="pb-1" cols="12" sm="8" md="8">
                      <v-select
                        v-model="bankVerifikasi"
                        :items="itemsBank"
                        item-value="value"
                        item-text="text"
                        label="Pilih Bank"
                        clearable
                        outlined
                        v-on:change="changeBankSelected"
                        required
                      ></v-select>
                    </v-col>
                    <v-col
                      v-if="valueOtherBank == true"
                      class="pb-1"
                      cols="12"
                      sm="4"
                      md="4"
                    >
                      <v-text-field
                        v-model="selectedBankVerifikasi"
                        label="Pembayaran"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-1" cols="12" sm="8" md="8">
                      <v-text-field
                        v-model="komentarVerifikasi"
                        label="Masukkan Komentar"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="pa-1">
                      <v-file-input
                        accept="image/png, image/jpeg, image/bmp"
                        placeholder="Pilih Foto"
                        prepend-icon="mdi-camera"
                        show-size
                        label="Pilih Bukti Transfer"
                        outlined
                        v-model="gambarinput"
                        v-on:change="pilihgambar"
                      ></v-file-input>
                    </v-col>

                    <v-container>
                      <v-row justify="center">
                        <div v-if="selectedImage" max-width="85vw">
                          <img
                            :src="selectedImage"
                            alt=""
                            width="100%"
                            @click.stop="selectedImage = null"
                          />
                          <hr />
                        </div>
                        <v-col v-if="selectedImage == null" cols="12" md="12">
                          <v-card
                            class="d-flex align-center"
                            elevation="2"
                            height="450"
                            width="450"
                          >
                            <v-img
                              v-bind:src="gambarshow"
                              class="my-2 mx-1 mb-4"
                              max-height="450"
                              contain
                              v-on:click="zoom(gambarshow)"
                            ></v-img>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  elevation="1"
                  outlined
                  @click="closeVerification"
                >
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn
                  color="success"
                  elevation="1"
                  outlined
                  @click="saveVerification"
                >
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Modal Ongkir -->
          <v-dialog v-model="dialogOngkir" max-width="500px">
            <v-card>
              <v-card-title class="headermodalstyle">
                <span>Pilih Ongkir</span>
              </v-card-title>

              <v-card-text class="pa-1 fontall">
                <v-container
                  v-if="loadOngkir == true"
                  fluid
                  fill-height
                  style="background-color: rgba(255, 255, 255, 0.5)"
                >
                  <v-layout justify-center align-center>
                    <v-progress-circular
                      :size="80"
                      :width="10"
                      indeterminate
                      color="primary"
                    >
                    </v-progress-circular>
                  </v-layout>
                </v-container>
                <v-container v-if="loadOngkir == false">
                  <v-container v-if="loadMessage == true">
                    <v-layout justify-center align-center>
                      <v-icon
                        v-if="successMessage == true"
                        style="font-size: 125px"
                        color="success"
                        center
                      >
                        mdi-checkbox-marked-circle-outline
                      </v-icon>
                      <v-icon
                        v-if="successMessage == false"
                        style="font-size: 125px"
                        color="red"
                        center
                      >
                        mdi-alert-circle-outline
                      </v-icon>
                    </v-layout>
                    <v-layout justify-center align-center>
                      <h4 v-if="successMessage == true">
                        Success Load Data Ongkir
                      </h4>
                      <h4 v-if="successMessage == false">
                        Gagal Load Data Ongkir. Reload Lagi!!!
                      </h4>
                    </v-layout>
                  </v-container>
                  <v-container class="pa-1" v-if="loadMessage == false">
                    <v-row class="mb-1">
                      <v-select
                        class="px-1 py-0"
                        cols="12"
                        sm="12"
                        md="12"
                        v-model="ongkirTemp"
                        :items="itemsOngkir"
                        item-value="value"
                        item-text="text"
                        label="Pilih Ongkir"
                        clearable
                        outlined
                        v-on:change="changeTypeOngkir"
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-select>
                      <v-col
                        v-if="dropdownOngkirManual == true"
                        class="px-1 py-0"
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          v-model="defaultItem.Expedisi"
                          label="Isi Nama Jasa"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col
                        v-if="dropdownOngkirManual == true"
                        class="px-1 py-0"
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          v-model="defaultItem.BiayaExpedisi"
                          label="Isi Nominal Biaya"
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mb-3 ml-1">
                      <h4 style="color: red">
                        Berat Total Buku = {{ beratShowOngkir }} gram
                      </h4>
                    </v-row>
                  </v-container>
                </v-container>
              </v-card-text>
              <v-divider class="mx-4"></v-divider>
              <v-container class="pa-1" v-if="loadOngkir == false">
                <v-card-actions v-if="loadMessage == false">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="red"
                    elevation="1"
                    outlined
                    @click="closeOngkir"
                  >
                    <v-icon left> mdi-close-circle-outline </v-icon>
                    Cancel
                  </v-btn>
                  <v-btn
                    color="success"
                    elevation="1"
                    outlined
                    @click="saveInvoice"
                  >
                    <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                    Save
                  </v-btn>
                </v-card-actions>
              </v-container>
            </v-card>
          </v-dialog>

          <!-- Dialog Loader -->
          <v-dialog v-model="loading" fullscreen full-width>
            <v-container
              fluid
              fill-height
              style="background-color: rgba(255, 255, 255, 0.5)"
            >
              <v-layout justify-center align-center>
                <v-progress-circular indeterminate color="primary">
                </v-progress-circular>
              </v-layout>
            </v-container>
          </v-dialog>

          <!-- Modal Add Buku -->
          <v-dialog v-model="dialogAddBuku" max-width="400px">
            <v-card>
              <v-card-text class="pa-1 fontall">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <div>
                        <h5>Nama Buku</h5>
                        <h3 class="ml-2">
                          {{ judulBukuTemp }}
                        </h3>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <div>
                        <h5>Harga</h5>
                        <h3 class="ml-2">
                          {{ hargaBukuTemp }}
                        </h3>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <div>
                        <h5>Berat</h5>
                        <h3 class="ml-2">{{ beratBukuTemp }} gram</h3>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <div>
                        <h5>Stock Tersedia</h5>
                        <h3 class="ml-2">{{ totalBukuTemp }}</h3>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-1" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="jumlahBukuTemp"
                        label="Jumlah"
                        outlined
                        type="number"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-1" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="diskonBukuTemp"
                        label="Diskon"
                        required
                        type="number"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="closeAddBuku">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn color="success" elevation="1" outlined @click="addBuku">
                  <v-icon left> mdi-plus-circle-outline </v-icon>
                  Add
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Modal Delete -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" outlined @click="closeDelete">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  outlined
                  @click="deleteItemConfirm"
                >
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  OK
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Modal Aktif Hold -->
          <v-dialog v-model="dialogHold" max-width="500px">
            <v-card>
              <v-card-title class="headermodalstyle"
                >Aktifkan kembali transaksi?</v-card-title
              >
              <v-card-text>
                <v-contain>
                  <v-row>
                    <v-col class="pb-1" cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="komentarAktifasi"
                        label="Masukkan Komentar"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-contain>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="closeOngkir">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn
                  color="success"
                  elevation="1"
                  outlined
                  @click="AktifasiOrder"
                >
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  Aktifasi
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon @click="detailItem(item)" color="blue">
          mdi-information-outline
        </v-icon>
        <v-icon @click="editItem(item)" color="warning">mdi-pencil</v-icon>
        <v-icon @click="aktifHold(item)" color="green"
          >mdi-checkbox-marked-circle-outline</v-icon
        >
        <v-icon @click="deleteItem(item)" color="red"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script src="../public/js/html2canvas.js"></script>

<script>
import axios from "axios";
import html2canvas from "@/js/html2canvas";

export default {
  name: "TransaksiDitahan",
  data: () => ({
    itemsbr: [
      {
        text: "Transaksi",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Transaksi Ditahan",
        disabled: true,
        href: "breadcrumbs_link_1",
      },
    ],

    formTitle: "Add Item",
    value: "add",
    dialog: false,
    dialogDelete: false,
    dialogDetail: false,
    dialogHold: false,
    dialogPreview: false,
    dialogAddBuku: false,
    dialogOngkir: false,
    dialogShowEdit: false,
    dialogShowVerifikasi: false,
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    BaseUrlUpload: "",
    BaseUrl: "",
    BaseUrlDBImage: "",
    headers: [
      { text: "Kode Invoice", value: "idOrder" },
      { text: "Nama Cust", value: "nama", width: "20%" },
      { text: "Tujuan", value: "nama_penerima", width: "15%" },
      { text: "Type Trx", value: "typeTransaksi" },
      { text: "keterangan", value: "keterangan" },
      //   { text: "User ID", value: "username" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headersBarang: [
      { text: "Nama Buku", value: "namaBarang" },
      { text: "Jumlah", value: "jumlah" },
      { text: "Diskon", value: "diskon" },
      { text: "Sub total", value: "subtotal", width: "25%" },
    ],
    headerListBuku: [
      { text: "Nama Buku", value: "namaBarang", width: "35%" },
      { text: "Berat", value: "berat", width: "12%" },
      { text: "Harga", value: "harga", width: "18%" },
      { text: "Diskon", value: "diskon", width: "12%" },
      { text: "Jumlah", value: "jumlah", width: "13%" },
      { text: "Actions", value: "actions", sortable: false, width: "10%" },
    ],
    dataobject: [],

    defaultItem: {
      idOrder: "",
      nama: "",
      nama_penerima: "",
      keterangan: "",

      gambar: "",

      Admin: "",
      Alamat: "",
      BiayaExpedisi: "",
      typeTransaksi: "",
      customerCombobox: "",
      kabROCombobox: "",
      id_alamatkabROCombobox: "",
      textkabROCombobox: "",
      kab_kota_ro_kode: "",
      Customer: "",
      diskonCustomer: "",
      Diskon: "",
      DiskonKodeUnik: "",
      Expedisi: "",
      NamaPenerima: "",
      NoInvoice: "",
      NoPenerima: "",
      NoPengirim: "",
      Pengirim: "",
      SubTotal: "",
      Tanggal: "",
      TelpCust: "",
      TotalHarga: "",
      codeGudang: "",
      itemsTypePromo: "",
      listOrder: [],
      tanggal_tempo: "",
    },

    itemsCustomer: [],
    itemsKabRO: [],
    itemsBuku: [],
    itemsTrx: [
      { text: "Regular", value: "regular" },
      { text: "Pre Order", value: "preOrder" },
      { text: "Tempo", value: "tempo" },
      { text: "Arisan", value: "arisan" },
    ],
    itemsTypeGudang: [
      { text: "Gudang Solo/ Reguler", codeGudang: "Gd_001" },
      { text: "Gudang Jakarta", codeGudang: "Gd_002" },
    ],
    itemsTypePromo: [
      { text: "non promo", value: "non_promo" },
      { text: "promo", value: "promo" },
    ],
    itemsOngkir: [],
    itemsBank: [
      { text: "BCA", value: "BCA" },
      { text: "BRI", value: "BRI" },
      { text: "BNI", value: "BNI" },
      { text: "Mandiri", value: "Mandiri" },
      { text: "Lain-Lain", value: "lain" },
    ],

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,

    loading: false,
    loadOngkir: false,
    loadMessage: false,
    successMessage: false,
    dropdownOngkirManual: false,
    valueOtherBank: false,
    gambarshow: "",
    fileUpload: "",
    fileUploadReady: false,
    gambarinput: "",
    selectedImage: null,
    resultUpload: false,
    bankVerifikasi: "",
    komentarVerifikasi: "-",
    selectedBankVerifikasi: "",

    komentarAktifasi: "",

    e1: 1,
    stepperdone: false,
    showDisconForm: false,
    discSelectedCustomer: 0,
    nameTextKabROSelected: "",
    valueResetBukuForm: false,
    valueAddForm: false,
    codeBukuTemp: "",
    judulBukuTemp: "",
    hargaBukuTemp: "",
    beratBukuTemp: "",
    jumlahBukuTemp: 0,
    diskonBukuTemp: 0,
    totalBukuTemp: 0,
    beratShowOngkir: 0,

    datepicker: new Date().toISOString().substr(0, 10),
    menu2: "",
    dateTempo: false,

    ongkirTemp: "",

    kodeAdminTrx: "",
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlUpload = localStorage.getItem("BaseUrlUpload");
    this.BaseUrl = localStorage.getItem("BaseUrl");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.BaseUrlDBImage = localStorage.getItem("BaseUrlDBImage");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.kodeAdminTrx = this.User.kodeAdminTrx;
    }
    this.initialize();
    this.getCustommer();
    this.getKabKotaROAll();
  },

  methods: {
    async initialize() {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetTransaksiHoldBosman?kodeAdminTrx=" +
            this.kodeAdminTrx,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataobject = [];
        }
      }
    },
    async detailData(val) {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetTransaksiBosmanDetail?idOrder=" +
            this.defaultItem.idOrder,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.defaultItem = response.data.data.result;
          if (val == "Edit") {
            this.defaultItem.customerCombobox = {
              idCust: response.data.data.result.idCust,
              nama: response.data.data.result.Customer,
            };
            this.discSelectedCustomer =
              response.data.data.result.diskonCustomer;
            this.nameTextKabROSelected =
              response.data.data.result.kecamatan +
              "," +
              response.data.data.result.kab_kota +
              "," +
              response.data.data.result.propinsi;
            if (response.data.data.result.kab_kota_ro_kode == "-") {
              await this.getKabKotaROSelected();
              this.defaultItem.kabROCombobox = {
                id_alamat: this.defaultItem.id_alamatkabROCombobox,
                text: this.defaultItem.textkabROCombobox,
              };
            } else {
              this.defaultItem.kabROCombobox = {
                id_alamat: response.data.data.result.kab_kota_ro_kode,
                text: this.nameTextKabROSelected,
              };
            }
          }

          if (response.data.data.result.typeTransaksi == "tempo") {
            this.dateTempo = true;
          } else {
            this.dateTempo = false;
          }
          this.datepicker = response.data.data.result.tanggal_tempo;
        } else {
          console.log("Kosong");
          //   this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          //   this.dataobject = [];
        }
      }
    },

    async getCustommer() {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetCustomerbyAdmin?kodeAdminTrx=" +
            this.kodeAdminTrx,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsCustomer = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.itemsCustomer = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsCustomer = [];
        }
      }
    },
    async getKabKotaROAll() {
      try {
        const response = await axios.get(this.BaseUrlGet + "GetKotaKabRO", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsKabRO = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.itemsKabRO = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsKabRO = [];
        }
      }
    },
    async getKabKotaROSelected() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetKotaKabRO?name=" + this.nameTextKabROSelected,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.defaultItem.id_alamatkabROCombobox =
            response.data.data.result.data[0].id_alamat;
          this.defaultItem.textkabROCombobox =
            response.data.data.result.data[0].text;
        } else {
          console.log("Kosong");
          // this.itemsKabRO = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.itemsKabRO = [];
        }
      }
    },
    async GetBarang() {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetBarang?CodeGudang=" +
            this.defaultItem.codeGudang +
            "&type=" +
            this.defaultItem.itemsTypePromo,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.itemsBuku = response.data.data.result;
        } else {
          console.log("Kosong");
          this.itemsBuku = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsBuku = [];
        }
      }
    },

    async cekOngkir() {
      this.dialog = false;
      this.dialogOngkir = true;
      this.loadOngkir = true;
      const datapost = {
        kodeAdminTrx: this.kodeAdminTrx,
        codeGudang: this.defaultItem.codeGudang,
        id_alamat: this.defaultItem.kabROCombobox.id_alamat,
        listOrder: this.defaultItem.listOrder,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "CekOngkir",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);

        if (response.data.success == true) {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses Load data";
          this.loadOngkir = false;
          this.loadMessage = true;
          this.successMessage = true;
          await new Promise((r) => setTimeout(r, 2000));
          this.loadMessage = false;

          this.itemsOngkir = response.data.data.result.dataongkir;
          this.beratShowOngkir = response.data.data.result.berat;
          // this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = response.data.data.result;
          this.loadOngkir = false;
          this.loadMessage = true;
          this.successMessage = false;
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = error.response.data.data.result;
          this.loadOngkir = false;
          this.loadMessage = true;
          this.successMessage = false;
        }
      }
    },

    async verifDelete() {
      const datapost = {
        idOrder: this.defaultItem.idOrder,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "DeleteHoldOrder",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result.data);
        if (response.data.data.result == "success") {
          this.dialogDelete = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses hapus data";
          this.initialize();
        } else {
          this.dialogDelete = false;
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },
    async addData() {
      // console.log(this.defaultItem.NoInvoice);
      // console.log(this.defaultItem.typeTransaksi);
      // console.log(this.defaultItem.customerCombobox);
      // console.log(this.defaultItem.Pengirim);
      // console.log(this.defaultItem.NoPengirim);
      // console.log(this.defaultItem.NamaPenerima);
      // console.log(this.defaultItem.NoPenerima);
      // console.log(this.defaultItem.Alamat);
      // console.log(this.defaultItem.Expedisi);
      // console.log(this.defaultItem.BiayaExpedisi);
      // console.log(this.defaultItem.kabROCombobox);
      // console.log(this.defaultItem.listOrder);
      // console.log(this.defaultItem.codeGudang);

      const datapost = {
        kodeAdminTrx: this.kodeAdminTrx,
        TypeTransaksi: this.defaultItem.typeTransaksi,
        Customer: JSON.stringify(this.defaultItem.customerCombobox),
        Pengirim: this.defaultItem.Pengirim,
        NoPengirim: this.defaultItem.NoPengirim,
        NamaPenerima: this.defaultItem.NamaPenerima,
        NoPenerima: this.defaultItem.NoPenerima,
        Alamat: this.defaultItem.Alamat,
        KabKotaRO: JSON.stringify(this.defaultItem.kabROCombobox),
        ListOrder: JSON.stringify(this.defaultItem.listOrder),
        Expedisi: this.defaultItem.Expedisi,
        BiayaExpedisi: this.defaultItem.BiayaExpedisi,
        CodeGudang: this.defaultItem.codeGudang,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "AddInvoice",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogOngkir = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async updateData() {
      const datapost = {
        NoInvoice: this.defaultItem.NoInvoice,
        kodeAdminTrx: this.kodeAdminTrx,
        TypeTransaksi: this.defaultItem.typeTransaksi,
        Customer: JSON.stringify(this.defaultItem.customerCombobox),
        Pengirim: this.defaultItem.Pengirim,
        NoPengirim: this.defaultItem.NoPengirim,
        NamaPenerima: this.defaultItem.NamaPenerima,
        NoPenerima: this.defaultItem.NoPenerima,
        Alamat: this.defaultItem.Alamat,
        KabKotaRO: JSON.stringify(this.defaultItem.kabROCombobox),
        ListOrder: JSON.stringify(this.defaultItem.listOrder),
        Expedisi: this.defaultItem.Expedisi,
        BiayaExpedisi: this.defaultItem.BiayaExpedisi,
        CodeGudang: this.defaultItem.codeGudang,
        tanggal_tempo: this.datepicker,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "EditHoldOrder",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogOngkir = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses ubah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          this.dialog = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async verificationApi() {
      const datapost = {
        idOrder: this.defaultItem.idOrder,
        selectedBankVerifikasi: this.selectedBankVerifikasi,
        komentarVerifikasi: this.komentarVerifikasi,
        imageTemp: this.BaseUrlDBImage + this.defaultItem.gambar,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "VerificationHoldOrder",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogShowVerifikasi = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses verifikasi data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          this.dialog = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async aktifasiApi() {
      const datapost = {
        idOrder: this.defaultItem.idOrder,
        komentarVerifikasi: this.komentarAktifasi,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "AktifasinHoldOrder",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogHold = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses aktifasi";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          this.dialog = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async UploadData() {
      console.log(this.BaseUrlUpload);
      if (this.fileUploadReady == true) {
        var dateConvert = Date.now();

        const formData = new FormData();
        formData.append("files", this.fileUpload);
        var namafile = "ORD_" + dateConvert;

        // console.log(namafile);
        try {
          const response = await axios.post(
            this.BaseUrlUpload + "?nama=" + namafile,
            formData,
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
              },
            }
          );
          console.log(response);
          if (response.data.success == true) {
            this.resultUpload = true;
            this.defaultItem.gambar = response.data.TempName;
          } else {
            this.resultUpload = false;
            // this.dialog = false;
          }
        } catch (error) {
          console.error(error.response);
          if (error.response.status == 401) {
            this.resultUpload = false;
            localStorage.removeItem("token");
            this.$router.push("/");
            // this.dialog = false;
          } else {
            this.resultUpload = false;
          }
        }
      } else {
        this.defaultItem.gambar = "-";
        this.resultUpload = true;
      }
    },

    changeTypeTrx(val) {
      console.log(val);
      if (val == "tempo") {
        this.dateTempo = true;
      } else {
        this.dateTempo = false;
      }
      // this.discSelectedCustomer = val.diskon;
    },
    changeCustomer(val) {
      console.log(val);
      this.discSelectedCustomer = val.diskon;
      this.showDisconForm = true;
    },
    changeGudangSelected(val) {
      console.log(val);
      this.valueAddForm = false;
      this.valueResetBukuForm = true;
      this.defaultItem.codeGudang = val;
    },
    resetbuku() {
      this.valueAddForm = true;
      this.valueResetBukuForm = false;
      this.defaultItem.codeGudang = "";
      this.defaultItem.listOrder = [];
    },
    changeTypePromo(val) {
      this.defaultItem.itemsTypePromo = val;
      this.GetBarang();
    },
    changeBukuSelected(val) {
      console.log(val);
      this.codeBukuTemp = val.id;
      this.judulBukuTemp = val.judul_buku;
      this.hargaBukuTemp = val.harga;
      this.beratBukuTemp = val.berat;
      this.totalBukuTemp = val.total;
      this.dialogAddBuku = true;
    },
    changeTypeOngkir(val) {
      console.log(val);
      if (val == "Manual") {
        this.defaultItem.Expedisi = "";
        this.dropdownOngkirManual = true;
        this.defaultItem.BiayaExpedisi = 0;
      } else {
        this.defaultItem.BiayaExpedisi = 0;
        this.dropdownOngkirManual = false;
        let str = val;
        const myArr = str.split("-");
        this.defaultItem.BiayaExpedisi = myArr[1];
        this.defaultItem.Expedisi = myArr[0];

        console.log(this.defaultItem.BiayaExpedisi);
        console.log(this.defaultItem.Expedisi);
      }
    },
    // pilihmanual() {
    //   console.log(this.defaultItem.BiayaExpedisi);
    //   console.log(this.defaultItem.Expedisi);
    // },

    addBuku() {
      // console.log(this.codeBukuTemp);
      // console.log(this.jumlahBukuTemp);
      // console.log(this.diskonBukuTemp);
      if (this.jumlahBukuTemp > 0) {
        if (this.jumlahBukuTemp > this.totalBukuTemp) {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Stock Buku Tidak Mencukupi..!!!";
        } else {
          var listmenu = this.defaultItem.listOrder;
          var result = false;
          for (let n = 0; n < listmenu.length; n++) {
            if (listmenu[n].codeBarang == this.codeBukuTemp) {
              result = true;
              break;
            }
          }

          if (result == false) {
            var bukuset = {
              berat: "",
              codeBarang: "",
              diskon: "",
              harga: "",
              jumlah: "",
              namaBarang: "",
            };

            bukuset.berat = this.beratBukuTemp;
            bukuset.codeBarang = this.codeBukuTemp;
            bukuset.diskon = this.diskonBukuTemp;
            bukuset.harga = this.hargaBukuTemp;
            bukuset.jumlah = this.jumlahBukuTemp;
            bukuset.namaBarang = this.judulBukuTemp;

            this.defaultItem.listOrder.push(bukuset);

            this.dialogAddBuku = false;
          } else {
            this.snackbar = true;
            this.colorsnackbar = "red";
            this.textsnackbar = "Buku Sudah Ada Bro..!!!";
          }
        }
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Jumlah Buku Tidak boleh 0..!!!";
      }
    },
    async deletelistitembuku(item) {
      this.editedIndex = await this.defaultItem.listOrder.indexOf(item);
      this.editedItem = await Object.assign({}, item);
      // console.log(this.editedIndex);
      // console.log(this.editedItem);
      this.defaultItem.listOrder.splice(this.editedIndex, 1);
    },

    showAddModal() {
      this.value = "add";
      this.valueAddForm = true;
      this.formTitle = "Create Invoice";
      this.defaultItem.customerCombobox = { idCust: "", nama: "" };
      this.showDisconForm = false;
      this.resetForm();
      this.e1 = 1;
      this.dialog = true;
    },
    detailItem(item) {
      this.defaultItem = Object.assign({}, item);
      this.dialogDetail = true;
      this.detailData("Detail");
    },
    editItem(item) {
      console.log(item);
      this.resetForm();
      // this.formTitle = "Edit Invoice";
      this.defaultItem = Object.assign({}, item);
      console.log(this.defaultItem);
      // this.showDisconForm = true;
      // this.detailData("Edit");
      // this.e1 = 1;
      // this.valueAddForm = false;
      // this.valueResetBukuForm = false;
      // this.value = "edit";
      this.dialogShowEdit = true;
    },
    showEditModal() {
      // console.log(item);
      // this.resetForm();
      this.dialogShowEdit = false;
      this.formTitle = "Edit Order";
      // this.defaultItem = Object.assign({}, item);
      this.showDisconForm = true;
      this.detailData("Edit");
      this.e1 = 1;
      this.valueAddForm = false;
      this.valueResetBukuForm = false;
      this.value = "edit";
      this.dialog = true;
    },
    showEditVerifikasi() {
      this.dialogShowVerifikasi = true;
      this.dialogShowEdit = false;
    },
    changeBankSelected(val) {
      console.log(val);
      if (val == "lain") {
        this.valueOtherBank = true;
        this.selectedBankVerifikasi = "-";
      } else {
        this.valueOtherBank = false;
        this.selectedBankVerifikasi = val;
      }
    },
    pilihgambar(event) {
      console.log(event);
      if (event != null) {
        this.gambarshow = URL.createObjectURL(event);
        console.log(this.defaultItem.gambarshow);
        this.fileUpload = event;
        this.fileUploadReady = true;
        // this.filephotoarray.push("lahan1");
      } else {
        this.gambarshow = "/images/noimage.png";
        this.fileUploadReady = false;
      }
    },
    zoom(url) {
      // console.log("zooom");
      console.log("Zoom", url);
      this.selectedImage = url;
    },

    aktifHold(item) {
      this.defaultItem = Object.assign({}, item);
      this.dialogHold = true;
    },
    deleteItem(item) {
      this.defaultItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      // console.log(this.defaultItem);
      this.verifDelete();
      this.closeDelete();
    },

    resetForm() {
      this.defaultItem.idOrder = "";
      this.defaultItem.Admin = "";
      this.defaultItem.Alamat = "";
      this.defaultItem.BiayaExpedisi = 0;
      this.defaultItem.typeTransaksi = "";
      this.defaultItem.customerCombobox = "";
      this.defaultItem.kabROCombobox = "";
      this.defaultItem.id_alamatkabROCombobox = "";
      this.defaultItem.textkabROCombobox = "";
      this.defaultItem.kab_kota_ro_kode = "";
      this.defaultItem.Customer = "";
      this.defaultItem.diskonCustomer = "";
      this.defaultItem.Diskon = "";
      this.defaultItem.DiskonKodeUnik = "";
      this.defaultItem.Expedisi = "";
      this.defaultItem.NamaPenerima = "";
      this.defaultItem.NoInvoice = "";
      this.defaultItem.NoPenerima = "";
      this.defaultItem.NoPengirim = "";
      this.defaultItem.Pengirim = "";
      this.defaultItem.SubTotal = "";
      this.defaultItem.Tanggal = "";
      this.defaultItem.TelpCust = "";
      this.defaultItem.TotalHarga = "";
      this.defaultItem.codeGudang = "";
      this.defaultItem.itemsTypePromo = "";
      this.defaultItem.listOrder = [];
      this.itemsBuku = [];
    },

    close() {
      this.dialog = false;
      this.dialogDetail = false;
      this.dialogAddBuku = false;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    closeHold() {
      this.dialogHold = false;
    },
    closeOngkir() {
      this.dialogOngkir = false;
    },
    closeAddBuku() {
      this.dialogAddBuku = false;
    },
    closeVerification() {
      this.dialogShowVerifikasi = false;
    },
    verifikasiOngkir() {
      if (
        this.defaultItem.typeTransaksi.length != 0 &&
        this.defaultItem.Pengirim.length != 0 &&
        this.defaultItem.NoPengirim.length != 0 &&
        this.defaultItem.NamaPenerima.length != 0 &&
        this.defaultItem.NoPenerima.length != 0 &&
        this.defaultItem.Alamat.length != 0 &&
        this.defaultItem.codeGudang.length != 0 &&
        this.defaultItem.customerCombobox.length != 0 &&
        this.defaultItem.kabROCombobox.length != 0 &&
        this.defaultItem.listOrder.length != 0
      ) {
        this.cekOngkir();
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Kolom tidak boleh ada yang kosong";
      }
    },

    saveInvoice() {
      if (
        this.defaultItem.Expedisi.length != 0 &&
        this.defaultItem.BiayaExpedisi.length != 0
      ) {
        console.log(this.defaultItem.NoInvoice);
        console.log(this.defaultItem.typeTransaksi);
        console.log(this.defaultItem.customerCombobox);
        console.log(this.defaultItem.Pengirim);
        console.log(this.defaultItem.NoPengirim);
        console.log(this.defaultItem.NamaPenerima);
        console.log(this.defaultItem.NoPenerima);
        console.log(this.defaultItem.Alamat);
        console.log(this.defaultItem.Expedisi);
        console.log(this.defaultItem.BiayaExpedisi);
        console.log(this.defaultItem.kabROCombobox);
        console.log(this.defaultItem.listOrder);
        console.log(this.defaultItem.codeGudang);
        if (this.defaultItem.NoInvoice) {
          console.log("edit");
          this.updateData();
        } else {
          console.log("add");
          this.addData();
        }
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar =
          "Gagal Membuat Invoice, Kolom tidak boleh ada yang kosong";
      }
    },
    async saveVerification() {
      if (this.selectedBankVerifikasi.length != 0) {
        console.log(this.selectedBankVerifikasi);
        await this.UploadData();
        if (this.resultUpload == true) {
          console.log("Save Verification");
          this.verificationApi();
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal Upload Gambar";
        }
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Kolom tidak boleh ada yang kosong";
      }
      // console.log(this.selectedBankVerifikasi);
      // console.log(this.komentarVerifikasi);
    },
    AktifasiOrder() {
      if (this.komentarAktifasi.length != 0) {
        this.aktifasiApi();
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Kolom tidak boleh ada yang kosong";
      }
    },

    createCanvas() {
      html2canvas(document.querySelector("#capture")).then((canvas) => {
        document.getElementById("cap2").className = "d-none";
        document.getElementById("cap1").className = "d-none";
        document.getElementById("canvas").appendChild(canvas);
        // document.getElementById("capture").className = "d-none";
        document.getElementById("cap1").className = "h1";
        document.getElementById("cap2").className = "container mb-5";
      });
    },

    print() {
      // this.createCanvas();
      this.dialogDetail = false;
      this.dialogPreview = true;
    },

    printImage() {
      document.getElementById("capture").className = "pa-1 ma-1 invGmbr";
      document.getElementById("canvas").className = "d-none";

      html2canvas(document.querySelector("#capture"), {
        useCORS: true,
        allowTaint: true,
      }).then(function (canvas) {
        console.log(canvas);
        var uri = canvas.toDataURL();
        var filename = "file-name.png";
        var link = document.createElement("a");
        if (typeof link.download === "string") {
          link.href = uri;
          link.download = filename;
          //Firefox requires the link to be in the body
          document.body.appendChild(link);
          //simulate click
          link.click();
          //remove the link when done
          document.body.removeChild(link);
        } else {
          window.open(uri);
        }
        // this.saveAs(canvas.toDataURL(), "file-name.png");
      });
      // document.getElementById("capture").className = "d-none";
      // document.getElementById("canvas").className = "";

      this.dialogPreview = false;
    },

    printPdf() {
      var printContents = document.getElementById("capture").innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      location.reload();
    },

    saveAs(uri, filename) {
      var link = document.createElement("a");
      if (typeof link.download === "string") {
        link.href = uri;
        link.download = filename;
        //Firefox requires the link to be in the body
        document.body.appendChild(link);
        //simulate click
        link.click();
        //remove the link when done
        document.body.removeChild(link);
      } else {
        window.open(uri);
      }
    },

    getStatus(status) {
      if (status == "0") return "Customer Reg";
      else return "Reseller";
    },
    getColorStatus(status) {
      if (status == "regular") return "green";
      else if (status == "preOrder") return "blue";
      else if (status == "tempo") return "orange";
      else return "red";
    },
    getTotalHarga(a, b, c, d) {
      var result = 0;
      result = parseInt(a) - parseInt(b) + parseInt(c) - parseInt(d);

      return this.getRupiah(result);
      //
    },

    getRupiah(val) {
      var bilangan = val;

      if (bilangan) {
        var number_string = bilangan.toString(),
          sisa = number_string.length % 3,
          rupiah = number_string.substr(0, sisa),
          ribuan = number_string.substr(sisa).match(/\d{3}/g);

        if (ribuan) {
          var separator = sisa ? "." : "";
          rupiah += separator + ribuan.join(".");
        }

        return "Rp. " + rupiah;
      }
    },
  },
};
</script>
