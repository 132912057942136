<template >
  <v-content
    style="
      background-image: url('/images/bgimg1.jpg');
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-size: 100% 100%;
      height: 100vh;
    "
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card
        max-width="300"
        elevation="6"
        outlined
        class="mx-auto rounded-card"
        style="margin-top: 110px"
      >
        <v-img
          class="mt-6 mb-1"
          height="60"
          src="/images/BosmanGreen.png"
        ></v-img>
        <!-- <v-card-title>Smart School Administration</v-card-title> -->
        <v-card-text class="px-5 pt-1 pb-3">
          <v-text-field
            v-model="username"
            label="Username"
            prepend-icon="mdi-account-circle"
            v-on:keyup="submitLogin"
            :rules="[(v) => !!v || 'Field is required']"
          />
          <v-text-field
            v-model="password"
            label="Password"
            :type="showPassword ? 'text' : 'password'"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            v-on:keyup="submitLogin"
            :rules="[(v) => !!v || 'Field is required']"
          />
        </v-card-text>

        <!-- <v-divider></v-divider> -->
        <v-card-actions>
          <v-btn
            width="200"
            @click="login()"
            color="#20B2AA"
            class="mx-auto mb-3"
            >Login</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
    <v-snackbar v-model="snackbar" :color="colorsnackbar" :timeout="timeout">
      {{ text }}
    </v-snackbar>
  </v-content>
</template>

<script>
import axios from "axios";

export default {
  name: "Trees",
  data: () => ({
    showPassword: false,
    username: "",
    password: "",
    authtoken: "",
    BaseUrlGet: "https://apiaccounting.aqwam.biz/api/",
    BaseUrlUpload: "https://hero.aqwam.biz/upload.php",
    BaseUrl: "https://hero.aqwam.biz/",
    BaseUrlDBImage: "hero.aqwam.biz/",
    snackbar: false,
    text: "Test",
    timeout: 2000,
    colorsnackbar: null,
    valid: true,

    angle: "0",
    color1: "red",
    color2: "blue",
  }),
  created() {
    this.firstAccessPage();
  },
  computed: {
    createBackgroundString() {
      return `linear-gradient(${this.angle}deg, rgba(186,18,235,1) 0%, rgba(127,47,255,1) 79%)`;
    },
  },
  methods: {
    firstAccessPage() {
      var authtoken = localStorage.getItem("token");
      if (authtoken) {
        localStorage.removeItem("token");
      }
      var User = JSON.parse(localStorage.getItem("User"));
      if (User) {
        localStorage.removeItem("User");
      }
      var BaseUrlGet = localStorage.getItem("BaseUrlGet");
      if (BaseUrlGet) {
        localStorage.removeItem("BaseUrlGet");
      }
      var BaseUrl = localStorage.getItem("BaseUrl");
      if (BaseUrl) {
        localStorage.removeItem("BaseUrl");
      }
      var BaseUrlUpload = localStorage.getItem("BaseUrlUpload");
      if (BaseUrlUpload) {
        localStorage.removeItem("BaseUrlUpload");
      }
      var BaseUrlDBImage = localStorage.getItem("BaseUrlDBImage");
      if (BaseUrlDBImage) {
        localStorage.removeItem("BaseUrlDBImage");
      }
    },
    async cekLogin() {
      const datapost = {
        username: this.username,
        password: this.password,
        type: 2,
      };
      // console.log(datapost);
      // console.log(this.BaseUrlGet);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(this.BaseUrlGet + "Login", datapost);
        console.log(response.data.data.status.code);
        this.snackbar = true;
        if (response.data.data.status.code == 200) {
          this.colorsnackbar = "green";
          this.text = "Sukses Login";
          console.log(response.data.data.result.access_token);
          localStorage.setItem("token", response.data.data.result.access_token);
          localStorage.setItem(
            "User",
            JSON.stringify(response.data.data.result.User)
          );
          localStorage.setItem("BaseUrlGet", this.BaseUrlGet);
          localStorage.setItem("BaseUrlUpload", this.BaseUrlUpload);
          localStorage.setItem("BaseUrl", this.BaseUrl);
          localStorage.setItem("BaseUrlDBImage", this.BaseUrlDBImage);

          location.reload();
          this.$router.push("/Dashboard");
        } else {
          this.colorsnackbar = "red";
          this.text = "Gagal Login, username dan password salah";
        }
      } catch (error) {
        console.error(error.response);
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.text = "Gagal Login, username dan password salah";
      }
    },

    login() {
      this.$refs.form.validate();
      // console.log(this.email);
      // console.log(this.password);
      this.cekLogin();
    },

    submitLogin: function (e) {
      if (e.keyCode === 13) {
        this.$refs.form.validate();
        this.cekLogin();
      }
      // this.log += e.key;
    },
  },
};
</script>

<style scoped>
.rounded-card {
  border-radius: 15px;
}
</style>