<template>
  <div>
    <v-breadcrumbs
      class="breadcrumbsmain fontall"
      :items="itemsbr"
      large
      color="black"
      style="font-weight: bold"
      ><template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template></v-breadcrumbs
    >

    <v-data-table
      :headers="headers"
      :items="dataobject"
      :search="search"
      class="rounded elevation-6 mx-3 pa-1 fontall"
    >
      <template v-slot:item.jenis_reseller="{ item }">
        <v-chip :color="getColorStatus(item.jenis_reseller)" dark>
          {{ getStatus(item.jenis_reseller) }}
        </v-chip>
      </template>

      <template v-slot:item.nama="{ item }">
        <tr>
          {{
            item.nama
          }}
          <h4 style="font-weight: bold">({{ item.username }})</h4>
        </tr>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            dark
            class="mb-2 mr-1 d-none d-md-block"
            @click="showResetModal()"
            color="blue"
          >
            <v-icon small>mdi-update</v-icon> Reset Diskon
          </v-btn>
          <v-btn dark class="mb-2" @click="showAddModal()" color="green">
            <v-icon small>mdi-plus</v-icon> Add Item
          </v-btn>
          <v-dialog v-model="dialogReset" max-width="500px">
            <v-card>
              <v-card-title class="headermodalstyle">
                <span>Reset Diskon</span>
              </v-card-title>
              <v-card-text class="fontall">
                <v-container>
                  <v-row>
                    <v-col class="pb-1" cols="12" sm="6" md="6">
                      <v-select
                        v-model="jenisResellerReset"
                        :items="itemsStatus"
                        item-value="value"
                        item-text="text"
                        label="Status Customer"
                        clearable
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-select>
                    </v-col>
                    <v-col class="pb-1" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="diskonReset"
                        label="Diskon"
                        :rules="[(v) => !!v || 'Field is required']"
                        type="number"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn color="success" elevation="1" outlined @click="reset">
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  Reset
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialog" max-width="600px">
            <v-card>
              <v-card-title class="headermodalstyle">
                <span>{{ formTitle }}</span>
              </v-card-title>
              <v-card-text class="pa-0 fontall">
                <v-container>
                  <v-row>
                    <v-col class="pb-1" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.nama"
                        label="Nama Customer"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-1" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.telephone"
                        label="No Telephone"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-1" cols="12" sm="12" md="12">
                      <v-textarea
                        v-model="defaultItem.alamat"
                        label="Alamat"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                        rows="3"
                      ></v-textarea>
                    </v-col>
                    <v-col class="pb-1" cols="12" sm="6" md="6">
                      <v-select
                        v-model="defaultItem.jenis_reseller"
                        :items="itemsStatus"
                        item-value="value"
                        item-text="text"
                        label="Status Customer"
                        clearable
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-select>
                    </v-col>
                    <v-col class="pb-1" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.diskon"
                        label="Diskon"
                        :rules="[(v) => !!v || 'Field is required']"
                        type="number"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn color="success" elevation="1" outlined @click="save">
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card class="fontall">
              <v-card-title class="headline"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="closeDelete">
                  <v-icon left> mdi-close-circle-outline </v-icon> Cancel</v-btn
                >
                <v-btn
                  color="success"
                  elevation="1"
                  outlined
                  @click="deleteItemConfirm"
                >
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogOrder" max-width="800px" class="pa-1">
            <v-card>
              <v-card-title class="mb-1 headermodalstyle">
                <span>Detail Order</span>
              </v-card-title>

              <v-card-text class="fontall pa-1">
                <v-container class="pa-1">
                  <v-divider
                    style="border-color: black !important"
                    class="mx-1 mb-2"
                  ></v-divider>
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 250px !important; font-size: 14px"
                          >
                            Nama Customer
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.nama }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Kode Customer
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.idCust }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Periode Belanja (Update)
                          </th>
                          <td
                            v-if="detailorderready == true"
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ gettanggal(detailOrder.from) }} -
                            {{ gettanggal(detailOrder.to) }}
                          </td>
                          <td
                            v-if="detailorderready == false"
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            Belum Ada Pembelian dari Customer
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Total Barang Belanja
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ detailOrder.totalbarang }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Total Belanja Nominal
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ getRupiah(detailOrder.totalbelanja) }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <v-divider
                    style="border-color: black !important"
                    class="mx-1 mb-2"
                  ></v-divider>

                  <v-data-table
                    :headers="headersOrderCust"
                    :items="listordercust"
                    :items-per-page="5"
                    class="elevation-1"
                  >
                    <template v-slot:item.total_harga="{ item }">
                      {{
                        getTotalHarga(
                          item.total_harga,
                          item.totalDiskon,
                          item.diskonKodeUnik
                        )
                      }}
                    </template>
                    <template v-slot:item.created_at="{ item }">
                      {{ gettanggal(item.created_at) }}
                    </template>
                    <template v-slot:item.updated_at="{ item }">
                      {{ gettanggal(item.updated_at) }}
                    </template>
                  </v-data-table>
                  <v-divider
                    style="border-color: black !important"
                    class="mx-1 mb-2"
                  ></v-divider>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-1" @click="editItem(item)" color="warning">
          mdi-pencil
        </v-icon>
        <v-icon class="mr-1" @click="deleteItem(item)" color="red">
          mdi-delete
        </v-icon>
        <v-icon @click="orderItem(item)" color="blue">
          mdi-order-bool-ascending-variant
        </v-icon>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
      class="fontall"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Customer",
  data: () => ({
    itemsbr: [
      {
        text: "Main Data",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Customer",
        disabled: true,
        href: "breadcrumbs_link_1",
      },
    ],

    formTitle: "Add Item",
    value: "add",
    dialog: false,
    dialogDelete: false,
    dialogReset: false,
    dialogOrder: false,
    detailorderready: false,
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    headers: [
      {
        text: "Kode Cust",
        value: "idCust",
      },
      { text: "Nama", value: "nama", width: "25%" },
      { text: "No Telp", value: "telephone" },
      { text: "Type Cust", value: "jenis_reseller" },
      { text: "Diskon", value: "diskon" },
      //   { text: "User ID", value: "username" },
      { text: "Actions", value: "actions", sortable: false, width: "15%" },
    ],
    headersOrderCust: [
      {
        text: "No Inv",
        value: "id",
      },
      { text: "Barang", value: "total_barang" },
      { text: "Total Belanja", value: "total_harga" },
      { text: "Tanggal", value: "created_at" },
      { text: "Update", value: "updated_at" },
    ],
    dataobject: [],
    listordercust: [],

    defaultItem: {
      idCust: "",
      nama: "",
      telephone: "",
      jenis_reseller: "",
      alamat: "",
      diskon: "",
    },

    detailOrder: {
      listorder: [],
      totalbarang: "",
      totalbelanja: "",
      from: "",
      to: "",
    },

    itemsStatus: [
      { text: "Reseller", value: "1" },
      { text: "Cust Reguler", value: "0" },
    ],
    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,

    diskonReset: "",
    jenisResellerReset: "",
    kodeAdminTrx: "",
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.kodeAdminTrx = this.User.kodeAdminTrx;
    }
    this.initialize();
  },

  methods: {
    async initialize() {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetCustomerbyAdmin?kodeAdminTrx=" +
            this.kodeAdminTrx,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataobject = [];
        }
      }
    },
    async getDetailOrder() {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetTotalOrderCustomerbyAdmin?idCust=" +
            this.defaultItem.idCust,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.detailOrder.totalbarang = response.data.data.result.totalbarang;
          this.detailOrder.totalbelanja =
            response.data.data.result.totalbelanja;
          this.detailOrder.from = response.data.data.result.from;
          this.detailOrder.to = response.data.data.result.to;

          this.listordercust = response.data.data.result.data;

          this.detailorderready = true;

          console.log(this.listordercust);
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.detailOrder.totalbarang = 0;
          this.detailOrder.totalbelanja = 0;
          this.listordercust = [];
          this.detailorderready = false;
        }
      }
    },
    async verifDelete() {
      const datapost = {
        idCust: this.defaultItem.idCust,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "SoftDeleteCustomer",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result.data);
        if (response.data.data.result == "success") {
          this.dialogDelete = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses hapus data";
          this.initialize();
        } else {
          this.dialogDelete = false;
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },
    async addData() {
      const datapost = {
        kodeAdminTrx: this.kodeAdminTrx,
        nama: this.defaultItem.nama,
        alamat: this.defaultItem.alamat,
        telephone: this.defaultItem.telephone,
        diskon: this.defaultItem.diskon,
        jenis_reseller: this.defaultItem.jenis_reseller,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "AddCustomer",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async updateData() {
      const datapost = {
        idCust: this.defaultItem.idCust,
        kodeAdminTrx: this.kodeAdminTrx,
        nama: this.defaultItem.nama,
        alamat: this.defaultItem.alamat,
        telephone: this.defaultItem.telephone,
        diskon: this.defaultItem.diskon,
        jenis_reseller: this.defaultItem.jenis_reseller,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "UpdateCustomer",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses ubah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          this.dialog = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async resetDiskon() {
      const datapost = {
        diskon: this.diskonReset,
        kodeAdminTrx: this.kodeAdminTrx,
        jenis_reseller: this.jenisResellerReset,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "ResetDiskonCustomer",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogReset = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses ubah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          this.dialogReset = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },

    showResetModal() {
      this.dialogReset = true;
    },
    showAddModal() {
      this.value = "add";
      this.formTitle = "Add Item";
      this.defaultItem.idCust = "";
      this.defaultItem.nama = "";
      this.defaultItem.telephone = "";
      this.defaultItem.jenis_reseller = "";
      this.defaultItem.alamat = "";
      this.defaultItem.diskon = "";
      this.dialog = true;
    },
    editItem(item) {
      console.log(item);
      this.formTitle = "Edit Item";
      this.defaultItem = Object.assign({}, item);
      this.value = "edit";
      this.dialog = true;
    },
    deleteItem(item) {
      this.defaultItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    orderItem(item) {
      this.defaultItem = Object.assign({}, item);
      this.getDetailOrder();
      this.dialogOrder = true;
    },

    deleteItemConfirm() {
      this.closeDelete();
      this.verifDelete();
    },
    close() {
      this.dialog = false;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    save() {
      if (
        this.defaultItem.nama.length != 0 &&
        this.defaultItem.telephone.length != 0 &&
        this.defaultItem.alamat.length != 0 &&
        this.defaultItem.diskon.length != 0
      ) {
        if (this.defaultItem.idCust) {
          console.log("edit");
          this.updateData();
        } else {
          console.log("add");
          this.addData();
        }
        this.close();
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Gagal Menambah, Kolom tidak boleh ada yang kosong";
      }
    },
    reset() {
      console.log(this.jenisResellerReset);
      console.log(this.diskonReset);
      this.resetDiskon();
    },

    getStatus(status) {
      if (status == "0") return "Customer Reg";
      else return "Reseller";
    },
    getColorStatus(status) {
      if (status == "0") return "orange";
      else return "green";
    },

    getRupiah(val) {
      var bilangan = val;

      if (bilangan) {
        var number_string = bilangan.toString(),
          sisa = number_string.length % 3,
          rupiah = number_string.substr(0, sisa),
          ribuan = number_string.substr(sisa).match(/\d{3}/g);

        if (ribuan) {
          var separator = sisa ? "." : "";
          rupiah += separator + ribuan.join(".");
        }

        return "Rp. " + rupiah;
      }
    },

    gettanggal(val) {
      var bulanIndo = [
        "",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Juni",
        "Juli",
        "Agust",
        "Sept",
        "Okt",
        "Nov",
        "Des",
      ];

      var date = val.split(" ")[0];

      var tanggal = date.split("-")[2];
      var bulan = date.split("-")[1];
      var tahun = date.split("-")[0];

      return tanggal + " " + bulanIndo[Math.abs(bulan)] + " " + tahun;
    },

    getTotalHarga(a, b, c) {
      var result = 0;
      result = parseInt(a) - parseInt(b) - parseInt(c);

      return this.getRupiah(result);
      //
    },
  },
};
</script>



