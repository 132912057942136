<template>
  <div>
    <v-breadcrumbs
      class="breadcrumbsmain"
      :items="itemsbr"
      divider=">"
      large
    ></v-breadcrumbs>

    <v-data-table
      :headers="headers"
      :items="dataobject"
      :search="search"
      class="rounded elevation-6 mx-3 pa-1"
    >
      <template v-slot:item.totalHarga="{ item }">
        {{ getRupiah(item.totalHarga) }}
      </template>

      <template v-slot:item.hpp="{ item }">
        {{ getRupiah(item.hpp) }}
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            dark
            class="mb-2 mr-1 d-none d-md-block"
            @click="download()"
            color="blue"
          >
            <v-icon class="mr-1" small>mdi-download-circle</v-icon> Download
          </v-btn>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PersediaanSolo",
  data: () => ({
    itemsbr: [
      {
        text: "Persediaan",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Persediaan Solo",
        disabled: true,
        href: "breadcrumbs_link_1",
      },
    ],

    search: "",
    authtoken: "",
    BaseUrlGet: "",
    headers: [
      {
        text: "Kode Buku",
        value: "codeBuku",
      },
      { text: "Nama Buku", value: "judulBuku" },
      { text: "Stock", value: "stock" },
      { text: "Hpp", value: "hpp" },
      { text: "Total Harga", value: "totalHarga" },
      //   { text: "Actions", value: "actions", sortable: false },
    ],
    dataobject: [],

    defaultItem: { id: "", namaKlasifikasi: "" },
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.initialize();
  },

  methods: {
    async initialize() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetTransaksiPersediaanSolo?limit=all",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataobject = [];
        }
      }
    },

    download() {
      console.log(this.gudang);
      var str = this.BaseUrlGet;
      window.open(
        str.substring(0, str.length - 4) +
          "ExportTransaksiPersediaan?limit=all&code_gudang=Gd_001"
      );
    },

    getRupiah(val) {
      var bilangan = val;

      if (bilangan) {
        var number_string = bilangan.toString(),
          sisa = number_string.length % 3,
          rupiah = number_string.substr(0, sisa),
          ribuan = number_string.substr(sisa).match(/\d{3}/g);

        if (ribuan) {
          var separator = sisa ? "." : "";
          rupiah += separator + ribuan.join(".");
        }

        return "Rp. " + rupiah;
      }
    },
  },
};
</script>