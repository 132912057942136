import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'
import Login from '../views/Login.vue'
import GantiPassword from '../views/GantiPassword.vue'

import Akun from '../views/MainData/Akun.vue'
import DataStock from '../views/MainData/DataStock.vue'
import Customer from '../views/MainData/Customer.vue'
import DataBuku from '../views/MainData/DataBuku.vue'
import DataPromo from '../views/MainData/DataPromo.vue'
import DataGudang from '../views/MainData/DataGudang.vue'
import Admin from '../views/MainData/Admin.vue'
import AdminLogin from '../views/MainData/AdminLogin.vue'

import InvoiceBosman from '../views/Invoice/InvoiceBosman.vue'
import InvoiceStore from '../views/Invoice/InvoiceStore.vue'
import KeepPO from '../views/Invoice/KeepPO.vue'

import TransaksiBaru from '../views/Transaksi/TransaksiBaru.vue'
import DalamProses from '../views/Transaksi/DalamProses.vue'
import TransaksiDitahan from '../views/Transaksi/TransaksiDitahan.vue'
import TagihanTempo from '../views/Transaksi/TagihanTempo.vue'
import AturTransaksi from '../views/Transaksi/AturTransaksi.vue'
import CetakInputResi from '../views/Transaksi/CetakInputResi.vue'
import OrderReguler from '../views/Transaksi/OrderReguler.vue'
import OrderPO from '../views/Transaksi/OrderPO.vue'
import Dikemas from '../views/Transaksi/Dikemas.vue'

import PindahGudang from '../views/PindahGudang/PindahGudang.vue'

import OngkirManual from '../views/Utilities/OngkirManual.vue'
import ReturnHibah from '../views/Utilities/ReturnHibah.vue'

import History from '../views/History/History.vue'

import PerformaSales from '../views/Report/PerformaSales.vue'
import PerformaFinance from '../views/Report/PerformaFinance.vue'
import PerformaGudang from '../views/Report/PerformaGudang.vue'

import PersediaanSolo from '../views/Persediaan/PersediaanSolo.vue'
import PersediaanJakarta from '../views/Persediaan/PersediaanJakarta.vue'
import PersediaanKonsi from '../views/Persediaan/PersediaanKonsi.vue'
import PenyesuaianPersediaan from '../views/Persediaan/PenyesuaianPersediaan.vue'
import DaftarStock from '../views/Persediaan/DaftarStock.vue'
import StockOpname from '../views/Persediaan/StockOpname.vue'
import TransferGudang from '../views/Persediaan/TransferGudang.vue'




// import FieldFacilitator from '../views/Employee/FF.vue'
import MainPage from '../views/MainPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/',
    name: 'Login',
    component: Login
  },{
    path: '/Uploads',
    name: 'Login',
    component: Login
  },
  {
    path: '/GantiPassword',
    name: 'GantiPassword',
    component: GantiPassword
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/MainPage',
    name: 'MainPage',
    component: MainPage
  },
  
  {
    path: '/Akun',
    name: 'Akun',
    component: Akun
  },
  {
    path: '/Customer',
    name: 'Customer',
    component: Customer
  },
  {
    path: '/DataStock',
    name: 'DataStock',
    component: DataStock
  },
  {
    path: '/DataBuku',
    name: 'DataBuku',
    component: DataBuku
  },
  {
    path: '/DataPromo',
    name: 'DataPromo',
    component: DataPromo
  },
  {
    path: '/DataGudang',
    name: 'DataGudang',
    component: DataGudang
  },
  {
    path: '/Admin',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/AdminLogin',
    name: 'AdminLogin',
    component: AdminLogin
  },  

  {
    path: '/InvoiceBosman',
    name: 'InvoiceBosman',
    component: InvoiceBosman
  },
  {
    path: '/InvoiceStore',
    name: 'InvoiceStore',
    component: InvoiceStore
  },
  {
    path: '/KeepPO',
    name: 'KeepPO',
    component: KeepPO
  },

  {
    path: '/TransaksiBaru',
    name: 'TransaksiBaru',
    component: TransaksiBaru
  },
  {
    path: '/DalamProses',
    name: 'DalamProses',
    component: DalamProses
  },
  {
    path: '/TransaksiDitahan',
    name: 'TransaksiDitahan',
    component: TransaksiDitahan
  },
  {
    path: '/TagihanTempo',
    name: 'TagihanTempo',
    component: TagihanTempo
  },
  {
    path: '/AturTransaksi',
    name: 'AturTransaksi',
    component: AturTransaksi
  }, 
  {
    path: '/CetakInputResi',
    name: 'CetakInputResi',
    component: CetakInputResi
  },
  {
    path: '/OrderReguler',
    name: 'OrderReguler',
    component: OrderReguler
  },
  {
    path: '/OrderPO',
    name: 'OrderPO',
    component: OrderPO
  },
  {
    path: '/Dikemas',
    name: 'Dikemas',
    component: Dikemas
  },  
  

  {
    path: '/PindahGudang',
    name: 'PindahGudang',
    component: PindahGudang
  },     

  {
    path: '/OngkirManual',
    name: 'OngkirManual',
    component: OngkirManual
  },
  {
    path: '/ReturnHibah',
    name: 'ReturnHibah',
    component: ReturnHibah
  },   
  
  
  {
    path: '/History',
    name: 'History',
    component: History
  }, 
  
  {
    path: '/PerformaSales',
    name: 'PerformaSales',
    component: PerformaSales
  }, 
  {
    path: '/PerformaFinance',
    name: 'PerformaFinance',
    component: PerformaFinance
  }, 
  {
    path: '/PerformaGudang',
    name: 'PerformaGudang',
    component: PerformaGudang
  }, 
  
  {
    path: '/PersediaanSolo',
    name: 'PersediaanSolo',
    component: PersediaanSolo
  },
  {
    path: '/PersediaanJakarta',
    name: 'PersediaanJakarta',
    component: PersediaanJakarta
  },
  {
    path: '/PersediaanKonsi',
    name: 'PersediaanKonsi',
    component: PersediaanKonsi
  },
  {
    path: '/PenyesuaianPersediaan',
    name: 'PenyesuaianPersediaan',
    component: PenyesuaianPersediaan
  },
  {
    path: '/DaftarStock',
    name: 'DaftarStock',
    component: DaftarStock
  },
  {
    path: '/StockOpname',
    name: 'StockOpname',
    component: StockOpname
  },
  {
    path: '/TransferGudang',
    name: 'TransferGudang',
    component: TransferGudang
  },
  
]

const router = new VueRouter({
  routes
})

export default router
