<template>
  <div>
    <v-breadcrumbs
      class="breadcrumbsmain fontall"
      :items="itemsbr"
      large
      color="black"
      style="font-weight: bold"
      ><template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template></v-breadcrumbs
    >

    <v-data-table
      :headers="headers"
      :items="dataobject"
      :search="search"
      class="rounded elevation-6 mx-3 pa-1 fontall"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-select
            v-model="selectGudang"
            :items="itemsGudang"
            item-value="codeGudang"
            item-text="namaGudang"
            v-on:change="selectedGudang"
            label="Pilih Gudang"
            clearable
            class="mx-3 mt-7 fontall"
            style="max-width: 225px"
          ></v-select>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="d-none d-md-block"
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn
            dark
            class="mb-2 mr-1 d-none d-md-block"
            @click="download()"
            color="blue"
          >
            <v-icon class="mr-1" small>mdi-download-circle</v-icon> Download
          </v-btn>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DataStock",
  data: () => ({
    itemsbr: [
      {
        text: "Main Data",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Data Stock",
        disabled: true,
        href: "breadcrumbs_link_1",
      },
    ],

    search: "",
    authtoken: "",
    BaseUrlGet: "",
    headers: [
      {
        text: "Kode Buku",
        value: "code_barang",
      },
      { text: "Nama Buku", value: "judul_buku" },
      { text: "Berat", value: "berat" },
      { text: "harga", value: "harga" },
      { text: "Stock", value: "stock" },
      //   { text: "Actions", value: "actions", sortable: false },
    ],
    dataobject: [],

    itemsGudang: [],
    gudang: "Gd_001",
    selectGudang: "",
    defaultItem: { id: "", namaKlasifikasi: "" },

    uri: "data:application/vnd.ms-excel;base64,",
    template:
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
    base64: function (s) {
      return window.btoa(unescape(encodeURIComponent(s)));
    },
    format: function (s, c) {
      return s.replace(/{(\w+)}/g, function (m, p) {
        return c[p];
      });
    },
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.initialize();
    this.getGudang();
  },

  methods: {
    async initialize() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetStockGudang?codeGudang=" + this.gudang,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataobject = [];
        }
      }
    },

    async getGudang() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "DropdownGudangStockSoloJakarta",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsGudang = response.data.data.result;
        } else {
          console.log("Kosong");
          this.itemsGudang = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsGudang = [];
        }
      }
    },
    async downloadApi() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetStockGudang?codeGudang=" + this.gudang,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data);
        if (response.data.length != 0) {
          localStorage.setItem("exportStock", response.data.data.result.data);
          console.log("test");
          this.$router.push("/ExportDataStock");
          // this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.dataobject = [];
        }
      }
    },

    selectedGudang(val) {
      console.log(val);
      if (val != null) {
        this.gudang = val;
      } else {
        this.gudang = "Gd_001";
      }
      this.initialize();
    },

    download() {
      console.log(this.gudang);
      var str = this.BaseUrlGet;
      window.open(
        str.substring(0, str.length - 4) +
          "ExportStockGudang?codeGudang=" +
          this.gudang
      );
    },

    tableToExcel(table, name) {
      if (!table.nodeType) table = this.$refs.table;
      var ctx = { worksheet: name || "Worksheet", table: table.innerHTML };
      window.location.href =
        this.uri + this.base64(this.format(this.template, ctx));
    },
  },
};
</script>