<template>
  <v-container class="mt-4 pa-1">
    <!-- Modal Detail -->
    <v-dialog v-model="dialogDetail" max-width="400px">
      <v-card>
        <v-card-title class="mb-1 headermodalstyle">
          <span>Detail Penjualan</span>
        </v-card-title>

        <v-card-text class="fontall pa-1">
          <v-container class="pa-1">
            <v-data-table
              :headers="headersPenjualan"
              :items="listPenjualan"
              class="rounded elevation-1 mx-1 pa-1 my-2"
              hide-default-footer
              disable-pagination
            >
              <template v-slot:item.order="{ item }">
                {{ item.order }}
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row dense>
      <v-col class="pa-2 mb-3" cols="12" sm="6" md="3">
        <v-card
          :to="data1.link"
          style="border-left: 10px solid rgb(28 157 255)"
          elevation="5"
        >
          <v-card-title class="text-h5 pa-3"> {{ data1.Judul }} </v-card-title>
          <v-card-subtitle class="mb-2 pt-1">
            {{ data1.Deskripsi }}
          </v-card-subtitle>
          <v-row>
            <v-col class="pa-3" cols="4">
              <v-icon x-large class="ml-3">
                mdi-folder-multiple-plus-outline
              </v-icon>
            </v-col>
            <v-col class="pa-1" cols="8">
              <p
                class="text-h4 mr-6 mt-2"
                style="display: flex; justify-content: flex-end"
              >
                {{ data1.Count }}
              </p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col class="pa-2 mb-3" cols="12" sm="6" md="3">
        <v-card
          :to="data2.link"
          style="border-left: 10px solid rgb(191 28 255)"
          elevation="5"
        >
          <v-card-title class="text-h5 pa-3"> {{ data2.Judul }} </v-card-title>
          <v-card-subtitle class="mb-2 pt-1">
            {{ data2.Deskripsi }}
          </v-card-subtitle>
          <v-row>
            <v-col class="pa-3" cols="4">
              <v-icon x-large class="ml-3">
                mdi-file-table-box-multiple-outline
              </v-icon>
            </v-col>
            <v-col class="pa-1" cols="8">
              <p
                class="text-h4 mr-6 mt-2"
                style="display: flex; justify-content: flex-end"
              >
                {{ data2.Count }}
              </p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col class="pa-2 mb-3" cols="12" sm="6" md="3">
        <v-card
          :to="data3.link"
          style="border-left: 10px solid rgb(210 8 53)"
          elevation="5"
        >
          <v-card-title class="text-h5 pa-3"> {{ data3.Judul }} </v-card-title>
          <v-card-subtitle class="mb-2 pt-1">
            {{ data3.Deskripsi }}
          </v-card-subtitle>
          <v-row>
            <v-col class="pa-3" cols="4">
              <v-icon x-large class="ml-3">
                mdi-checkbox-multiple-marked-outline
              </v-icon>
            </v-col>
            <v-col class="pa-1" cols="8">
              <p
                class="text-h4 mr-6 mt-2"
                style="display: flex; justify-content: flex-end"
              >
                {{ data3.Count }}
              </p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col class="pa-2 mb-3" cols="12" sm="6" md="3">
        <v-card
          :to="data4.link"
          style="border-left: 10px solid rgb(8 210 128)"
          elevation="5"
        >
          <v-card-title class="text-h5 pa-3"> {{ data4.Judul }} </v-card-title>
          <v-card-subtitle class="mb-2 pt-1">
            {{ data4.Deskripsi }}
          </v-card-subtitle>
          <v-row>
            <v-col class="pa-3" cols="4">
              <v-icon x-large class="ml-3"> mdi-calendar-clock </v-icon>
            </v-col>
            <v-col class="pa-1" cols="8">
              <p
                class="text-h4 mr-6 mt-2"
                style="display: flex; justify-content: flex-end"
              >
                {{ data4.Count }}
              </p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense class="mt-2">
      <v-col class="px-2 mt-1" cols="12" sm="8" md="8">
        <v-card elevation="5" class="mt-4 mx-auto">
          <v-sheet
            class="v-sheet--offset mx-auto mt-7"
            color="cyan"
            elevation="12"
            max-width="calc(100% - 30px)"
            :click="test()"
          >
            <v-sparkline
              :labels="labelsSparkline"
              :value="valueSparkline"
              :gradient="gradient"
              :gradient-direction="gradientDirection"
              max-height="80px"
              min-height="70px"
              line-width="2"
              padding="16"
              color="white"
              :click="test()"
            ></v-sparkline>
          </v-sheet>

          <v-card-text class="pt-0">
            <div class="text-h6 font-weight-light mb-2">
              <v-btn
                @click="showPenjualan()"
                block
                text
                small
                class="grey--text"
                >Penjualan 1 Tahun</v-btn
              >
            </div>

            <!-- <div class="subheading font-weight-light grey--text">
              Last Campaign Performance
            </div> -->
            <v-divider class="my-2"></v-divider>
            <v-icon class="mr-2" small> mdi-clock </v-icon>
            <span class="text-caption grey--text font-weight-light"
              >Performa {{ namaAdmin }} 1 Tahun
            </span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="px-1 py-1 mt-1" cols="12" sm="4" md="4">
        <v-card class="d-none d-md-block" elevation="5">
          <v-card-text class="py-1 px-1">
            <GChart
              type="PieChart"
              :data="chartData"
              :options="chartOptionsGoogle"
            />
          </v-card-text>
        </v-card>

        <v-card class="d-sm-block d-md-none" elevation="5">
          <v-card-text class="py-1 px-1">
            <GChart
              type="PieChart"
              :data="chartData"
              :options="chartOptionsGoogleMobile"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

const gradients = [
  ["#222"],
  ["#42b3f4"],
  ["red", "orange", "yellow"],
  ["purple", "violet"],
  ["#FF0", "#F0F", "#00c6ff"],
  ["#1feaea", "#ffd200", "#f72047"],
];

export default {
  name: "Dashboard",
  data: () => ({
    drawer: null,
    BaseUrlGet: "",
    authtoken: "",
    dialogDetail: false,
    headersPenjualan: [
      { text: "Bulan", value: "bulan" },
      { text: "Penjualan", value: "order" },
    ],
    listPenjualan: [],

    data1: {
      Judul: "",
      Deskripsi: "",
      Count: "",
      link: "",
    },
    data2: {
      Judul: "",
      Deskripsi: "",
      Count: "",
      link: "",
    },
    data3: {
      Judul: "",
      Deskripsi: "",
      Count: "",
      link: "",
    },
    data4: {
      Judul: "",
      Deskripsi: "",
      Count: "",
      link: "",
    },

    gradientDirection: "top",
    gradients,
    gradient: gradients[5],
    labelsSparkline: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Agust",
      "Sept",
      "Okt",
      "Nop",
      "Dec",
    ],
    valueSparkline: [350, 200, 410, 390, 310, 460, 250, 240, 0, 0, 0, 0],
    srcimage: "https://cdn.vuetifyjs.com/images/cards/foster.jpg",
    datacollection: null,
    namaAdmin: "Joko",
    User: "",
    kodeAdminTrx: "",
    code_jabatan: "",
    status_jabatan: "",

    series: [400, 750, 350, 1700, 150, 100, 50],
    chartOptions: {
      chart: {
        width: 300,
        type: "donut",
        height: "100%",
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
        },
      },
      dataLabels: {
        enabled: true,
        formatter(val, opts) {
          const name = opts.w.globals.labels[opts.seriesIndex];
          return [name];
        },
      },
      fill: {
        type: "gradient",
      },
      legend: {
        // formatter: function (val, opts) {
        //   return val + " - " + opts.w.globals.series[opts.seriesIndex];
        // },
        // fontSize: "11px",
        show: false,
      },
      title: {
        text: "Performa Admin",
      },
      labels: ["Alfin", "Zainal", "Yogi", "Cholid", "Taufik", "Dwi", "Novi"],
      responsive: [
        {
          breakpoint: 100,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "top",
            },
          },
        },
      ],
    },

    chartData: [
      ["Year", "Sales"],
      ["2014", 1000],
      ["2015", 1170],
      ["2016", 660],
      ["2017", 1030],
      ["2018", 103],
    ],
    chartOptionsGoogle: {
      title: "Performa Transaksi",
      // pieHole: 0.4,
      is3D: true,
      legend: "none",
      pieSliceText: "label",
      width: $(window).width() * 0.25,
      height: $(window).height() * 0.45,
    },
    chartOptionsGoogleMobile: {
      title: "Performa Transaksi",
      // pieHole: 0.4,
      is3D: true,
      legend: "none",
      pieSliceText: "label",
    },
  }),
  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.kodeAdminTrx = this.User.kodeAdminTrx;
      this.namaAdmin = this.User.name;
      this.code_jabatan = this.User.code_jabatan;
      this.status_jabatan = this.User.status_jabatan;
    }
    this.firstinitialize();
    this.initialize();
  },
  methods: {
    async firstinitialize() {
      if (this.status_jabatan == "Manager") {
        this.namaAdmin = "Penjualan";
      }
      if (this.code_jabatan == "3") {
        this.namaAdmin = "Penjualan";
      }
      if (this.authtoken) {
      } else {
        localStorage.removeItem("token");
        this.$router.push("/");
      }
    },

    async initialize() {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetDashboardBosman?kodeAdminTrx=" +
            this.kodeAdminTrx,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.data1 = response.data.data.result.data1;
          this.data2 = response.data.data.result.data2;
          this.data3 = response.data.data.result.data3;
          this.data4 = response.data.data.result.data4;

          this.labelsSparkline =
            response.data.data.result.PerformaMonth.listbulan;
          this.valueSparkline =
            response.data.data.result.PerformaMonth.listorder;

          this.listPenjualan =
            response.data.data.result.PerformaMonth.listorderadmin;

          this.series = response.data.data.result.PerformaAll.listorder;
          console.log(this.chartOptions.labels);
          this.chartOptions.labels =
            response.data.data.result.PerformaAll.listadmin;
          this.options = response.data.data.result.PerformaAll.listadmin;
          this.chartData = response.data.data.result.PerformaAll.listnew;

          console.log(this.chartOptions.labels);
          this.chartOptionsGoogle.title =
            response.data.data.result.NamaPerformaPieChart;
          this.chartOptionsGoogleMobile.title =
            response.data.data.result.NamaPerformaPieChart;
          // this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.dataobject = [];
        }
      }
    },

    showPenjualan() {
      this.dialogDetail = true;
    },

    test() {
      console.log("test");
    },

    getRupiah(val) {
      var bilangan = val;

      if (bilangan) {
        var number_string = bilangan.toString(),
          sisa = number_string.length % 3,
          rupiah = number_string.substr(0, sisa),
          ribuan = number_string.substr(sisa).match(/\d{3}/g);

        if (ribuan) {
          var separator = sisa ? "." : "";
          rupiah += separator + ribuan.join(".");
        }

        return "Rp. " + rupiah;
      }
    },
  },
};
</script>



