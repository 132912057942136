<template>
  <div>
    <v-breadcrumbs
      class="breadcrumbsmain fontall"
      :items="itemsbr"
      large
      color="black"
      style="font-weight: bold"
      ><template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template></v-breadcrumbs
    >

    <v-data-table
      :headers="headers"
      :items="dataobject"
      :search="search"
      class="rounded elevation-6 mx-3 pa-1 fontall"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn dark class="mb-2" @click="showAddModal()" color="green">
            <v-icon small>mdi-plus</v-icon> Add Item
          </v-btn>

          <v-dialog v-model="dialogDetail" max-width="600px">
            <v-card>
              <v-card-title
                class="headermodalstyle"
                style="margin-bottom: -2px !important"
              >
                <span>Detail Item</span>
              </v-card-title>
              <v-card-text class="pa-0 fontall">
                <v-container>
                  <v-divider
                    style="background-color: black !important"
                  ></v-divider>
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 200px; font-size: 14px"
                          >
                            Nama Promo
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.nama_promo }}
                          </th>
                        </tr>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 200px; font-size: 14px"
                          >
                            Tampilkan di Store
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ getStatusShow(defaultItem.is_del) }}
                          </th>
                        </tr>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 200px; font-size: 14px"
                          >
                            Harga Promo
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ getRupiah(defaultItem.harga_jadi) }}
                          </th>
                        </tr>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 200px; font-size: 14px"
                          >
                            Berat Promo
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.berat_total }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Tanggal Di buat
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ gettanggal(defaultItem.created_at) }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Tanggal Cetak
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ gettanggal(defaultItem.tanggal_cetak) }}
                          </th>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <v-divider
                    style="background-color: black !important"
                  ></v-divider>
                  <v-data-table
                    :headers="headerListBuku"
                    :items="defaultItem.listdetail"
                    class="rounded elevation-1 mx-1 pa-1 my-2"
                    hide-default-footer
                    disable-pagination
                  >
                    <template v-slot:item.harga="{ item }">
                      {{ getRupiah(item.harga) }}
                    </template>
                  </v-data-table>
                  <v-divider
                    style="background-color: black !important"
                  ></v-divider>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogAdd" max-width="800px">
            <v-card>
              <v-card-title class="mb-1 headermodalstyle">
                <span>{{ formTitle }}</span>
              </v-card-title>
              <v-card-text class="pa-0 fontall">
                <v-stepper v-model="e1">
                  <v-stepper-header>
                    <v-stepper-step editable :complete="e1 > 1" step="1">
                      Main Data
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-divider></v-divider>
                    <v-stepper-step editable step="3">
                      List Buku
                    </v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-items>
                    <v-stepper-content class="pa-3" step="1">
                      <v-container class="mb-2">
                        <v-row class="mb-3">
                          <v-col class="pb-1" cols="12" sm="12" md="12">
                            <v-text-field
                              v-model="defaultItem.nama_promo"
                              label="Nama Promo"
                              outlined
                              :rules="[(v) => !!v || 'Field is required']"
                            ></v-text-field>
                          </v-col>
                          <v-col class="pb-1" cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="defaultItem.harga_jadi"
                              label="Harga Jadi"
                              type="number"
                              outlined
                              :rules="[(v) => !!v || 'Field is required']"
                            ></v-text-field>
                          </v-col>
                          <v-col class="pb-1" cols="12" sm="6" md="6">
                            <v-select
                              v-model="defaultItem.show_store"
                              :items="itemsStatus"
                              item-value="value"
                              item-text="text"
                              label="Tampilkan Store"
                              clearable
                              outlined
                              :rules="[(v) => !!v || 'Field is required']"
                            ></v-select>
                          </v-col>
                          <v-col class="pb-1" cols="12" sm="6" md="6">
                            <v-select
                              v-model="defaultItem.pre_order"
                              :items="itemsPo"
                              item-value="value"
                              item-text="text"
                              label="Status Promo"
                              clearable
                              outlined
                              :rules="[(v) => !!v || 'Field is required']"
                            ></v-select>
                          </v-col>
                          <v-col class="pb-1" cols="12" sm="6" md="6">
                            <v-menu
                              v-model="menu2"
                              transition="scale-transition"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="datepicker"
                                  slot="activator"
                                  label="Tanggal Cetak"
                                  outlined
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :rules="[(v) => !!v || 'Field is required']"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="datepicker"
                                @input="menu2 = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-container>

                      <v-row class="ma-2">
                        <v-spacer></v-spacer>
                        <v-btn
                          class="mr-1"
                          color="red"
                          elevation="1"
                          @click="close"
                          outlined
                        >
                          <v-icon left> mdi-close-circle-outline </v-icon>
                          Cancel
                        </v-btn>
                        <v-btn
                          elevation="1"
                          outlined
                          color="primary"
                          @click="e1 = 3"
                        >
                          <v-icon left>
                            mdi-chevron-right-circle-outline
                          </v-icon>
                          Next
                        </v-btn>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-content class="pa-3" step="3">
                      <v-container class="mb-2">
                        <v-row class="mb-3">
                          <h4>Pilih Buku</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row>
                          <v-col class="px-1 py-0" cols="12" sm="12" md="12">
                            <v-combobox
                              v-model="defaultItem.itemsBuku"
                              :items="itemsBuku"
                              item-value="id"
                              item-text="judul_buku"
                              label="Pilih Buku"
                              v-on:change="changeBukuSelected"
                              outlined
                              clearable
                            ></v-combobox>
                          </v-col>
                        </v-row>
                        <v-row class="mb-3">
                          <h4>List Buku</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-data-table
                              :headers="headerListBukuAdd"
                              :items="defaultItem.listOrder"
                              class="elevation-3"
                              append-icon="mdi-magnify"
                              :items-per-page="5"
                            >
                              <template v-slot:item.actions="{ item }">
                                <v-icon
                                  class="mr-2"
                                  @click="deletelistitembuku(item)"
                                  color="red"
                                >
                                  mdi-delete
                                </v-icon>
                                <!-- <v-icon @click="deleteItem(item)" color="red"> mdi-delete </v-icon> -->
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-container>

                      <v-row class="ma-2">
                        <v-spacer></v-spacer>
                        <v-btn
                          class="mr-1"
                          color="red"
                          elevation="1"
                          @click="close"
                          outlined
                        >
                          <v-icon left> mdi-close-circle-outline </v-icon>
                          Cancel
                        </v-btn>
                        <v-btn
                          color="success"
                          elevation="1"
                          outlined
                          @click="save"
                        >
                          <v-icon left>
                            mdi-checkbox-marked-circle-outline
                          </v-icon>
                          Save
                        </v-btn>
                      </v-row>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-card-text>
              <!-- <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn color="success" elevation="1" outlined @click="save">
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  Save
                </v-btn>
              </v-card-actions> -->
            </v-card>
          </v-dialog>

          <!-- Modal Add Buku -->
          <v-dialog v-model="dialogAddBuku" max-width="400px">
            <v-card>
              <v-card-text class="pa-1 fontall">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <div>
                        <h5>Nama Buku</h5>
                        <h3 class="ml-2">
                          {{ judulBukuTemp }}
                        </h3>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <div>
                        <h5>Harga</h5>
                        <h3 class="ml-2">
                          {{ hargaBukuTemp }}
                        </h3>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <div>
                        <h5>Berat</h5>
                        <h3 class="ml-2">{{ beratBukuTemp }} gram</h3>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <div>
                        <h5>Stock Tersedia</h5>
                        <h3 class="ml-2">{{ totalBukuTemp }}</h3>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="closeAddBuku">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn color="success" elevation="1" outlined @click="addBuku">
                  <v-icon left> mdi-plus-circle-outline </v-icon>
                  Add
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogEdit" max-width="600px">
            <v-card>
              <v-card-title class="headermodalstyle">
                <span>{{ formTitle }}</span>
              </v-card-title>
              <v-card-text class="pa-0 fontall">
                <v-container>
                  <v-row>
                    <v-col class="pb-1" cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="defaultItem.nama_promo"
                        label="Nama Promo"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-1" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.harga_jadi"
                        label="Harga Jadi"
                        type="number"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-1" cols="12" sm="6" md="6">
                      <v-select
                        v-model="defaultItem.show_store"
                        :items="itemsStatus"
                        item-value="value"
                        item-text="text"
                        label="Tampilkan Store"
                        clearable
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-select>
                    </v-col>
                    <v-col class="pb-1" cols="12" sm="6" md="6">
                      <v-select
                        v-model="defaultItem.pre_order"
                        :items="itemsPo"
                        item-value="value"
                        item-text="text"
                        label="Status Promo"
                        clearable
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-select>
                    </v-col>
                    <v-col class="pb-1" cols="12" sm="6" md="6">
                      <v-menu v-model="menu2" transition="scale-transition">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="datepicker"
                            slot="activator"
                            label="Tanggal Cetak"
                            outlined
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="[(v) => !!v || 'Field is required']"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="datepicker"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn color="success" elevation="1" outlined @click="save">
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card class="fontall">
              <v-card-title class="headline"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="closeDelete">
                  <v-icon left> mdi-close-circle-outline </v-icon> Cancel</v-btn
                >
                <v-btn
                  color="success"
                  elevation="1"
                  outlined
                  @click="deleteItemConfirm"
                >
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-1" @click="detailItem(item)" color="primary">
          mdi-information-outline
        </v-icon>
        <v-icon class="mr-1" @click="editItem(item)" color="warning">
          mdi-pencil
        </v-icon>
        <v-icon @click="deleteItem(item)" color="red"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
      class="fontall"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DataPromo",
  data: () => ({
    itemsbr: [
      {
        text: "Main Data",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Data Promo",
        disabled: true,
        href: "breadcrumbs_link_1",
      },
    ],

    formTitle: "Add Item",
    value: "add",
    dialogAdd: false,
    dialogAddBuku: false,
    dialogEdit: false,
    dialogDelete: false,
    dialogReset: false,
    dialogDetail: false,
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    headers: [
      {
        text: "Kode",
        value: "code_promo",
      },
      { text: "Nama Promo", value: "nama_promo", width: "35%" },
      { text: "Stock", value: "stock" },
      { text: "Harga Promo", value: "harga_jadi" },
      { text: "Status", value: "status_po" },
      { text: "Berat", value: "berat_total" },
      //   { text: "User ID", value: "username" },
      { text: "Actions", value: "actions", sortable: false, width: "15%" },
    ],
    headerListBuku: [
      { text: "Nama Buku", value: "judul_buku", width: "45%" },
      { text: "Berat", value: "berat", width: "15%" },
      { text: "Harga", value: "harga", width: "20%" },
      { text: "Stock", value: "stock", width: "20%" },
    ],
    headerListBukuAdd: [
      { text: "Nama Buku", value: "namaBarang", width: "35%" },
      { text: "Berat", value: "berat", width: "12%" },
      { text: "Harga", value: "harga", width: "18%" },
      { text: "Actions", value: "actions", sortable: false, width: "10%" },
    ],
    dataobject: [],

    defaultItem: {
      code_promo: "",
      nama_promo: "",
      harga_jadi: "",
      pre_order: "",
      tanggal_cetak: "",
      berat_total: "",
      created_at: "",
      is_del: "",
      show_store: "",
      listdetail: [],
      listOrder: [],
    },

    itemsStatus: [
      { text: "Yes", value: "0" },
      { text: "No", value: "1" },
    ],
    itemsPo: [
      { text: "Reguler", value: "0" },
      { text: "PreOrder", value: "1" },
    ],
    itemsBuku: [],

    e1: 1,
    stepperdone: false,

    codeBukuTemp: "",
    judulBukuTemp: "",
    hargaBukuTemp: "",
    beratBukuTemp: "",
    jumlahBukuTemp: 0,
    diskonBukuTemp: 0,
    totalBukuTemp: 0,

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,

    location: "",
    diskonReset: "",
    jenisResellerReset: "",
    kodeAdminTrx: "",

    datepicker: new Date().toISOString().substr(0, 10),
    menu2: "",
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.kodeAdminTrx = this.User.kodeAdminTrx;
      this.location = this.User.location;
    }
    this.initialize();
    this.GetBarang();
  },

  methods: {
    async initialize() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetAllPromoBosman?codeLocation=" + this.location,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataobject = [];
        }
      }
    },

    async getDetail() {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetPromoDetailBosman?code_promo=" +
            this.defaultItem.code_promo +
            "&codeLocation=" +
            this.location,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.defaultItem = response.data.data.result;
          this.datepicker = response.data.data.result.tanggal_cetak;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.dataobject = [];
        }
      }
    },

    async GetBarang() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetBarang?CodeGudang=Gd_001&type=Non_promo",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.itemsBuku = response.data.data.result;
        } else {
          console.log("Kosong");
          this.itemsBuku = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsBuku = [];
        }
      }
    },
    async verifDelete() {
      const datapost = {
        kode: this.defaultItem.code_promo,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "DeletePromoBosman",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result.data);
        if (response.data.data.result == "success") {
          this.dialogDelete = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses hapus data";
          this.initialize();
        } else {
          this.dialogDelete = false;
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },
    async addData() {
      const datapost = {
        kode: this.defaultItem.code_promo,
        nama_promo: this.defaultItem.nama_promo,
        harga_jadi: this.defaultItem.harga_jadi,
        tanggal_cetak: this.datepicker,
        show_store: this.defaultItem.show_store,
        po: this.defaultItem.pre_order,
        deskripsi: "-",
        listOrder: JSON.stringify(this.defaultItem.listOrder),
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "AddPromoBosman",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogAdd = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async updateData() {
      const datapost = {
        kode: this.defaultItem.code_promo,
        nama_promo: this.defaultItem.nama_promo,
        harga_jadi: this.defaultItem.harga_jadi,
        tanggal_cetak: this.datepicker,
        show_store: this.defaultItem.show_store,
        po: this.defaultItem.pre_order,
        deskripsi: "-",
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "EditPromoBosman",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses ubah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          this.dialog = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },

    showResetModal() {
      this.dialogReset = true;
    },
    showAddModal() {
      this.value = "add";
      this.formTitle = "Add Item";
      this.defaultItem.code_promo = "";
      this.defaultItem.nama_promo = "";
      this.defaultItem.harga_jadi = "";
      this.defaultItem.pre_order = "";
      this.defaultItem.tanggal_cetak = "";
      this.datepicker = "";
      this.defaultItem.berat_total = "";
      this.defaultItem.created_at = "";
      this.defaultItem.is_del = "";
      this.defaultItem.show_store = "";
      // this.defaultItem.listdetail = [];
      this.defaultItem.listOrder = [];
      this.e1 = 1;
      this.dialogAdd = true;
    },
    detailItem(item) {
      console.log(item);
      this.defaultItem = Object.assign({}, item);
      this.getDetail();
      this.dialogDetail = true;
    },
    editItem(item) {
      console.log(item);
      this.formTitle = "Edit Item";
      this.defaultItem = Object.assign({}, item);
      this.value = "edit";
      this.getDetail();
      this.dialogEdit = true;
    },
    deleteItem(item) {
      this.defaultItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();
      this.verifDelete();
    },
    close() {
      this.dialog = false;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    save() {
      if (
        this.defaultItem.nama_promo.length != 0 &&
        this.defaultItem.harga_jadi.length != 0
      ) {
        if (this.defaultItem.code_promo) {
          console.log("edit");
          this.updateData();
        } else {
          console.log("add");
          this.addData();
        }
        this.close();
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Gagal Menambah, Kolom tidak boleh ada yang kosong";
      }
    },
    reset() {
      console.log(this.jenisResellerReset);
      console.log(this.diskonReset);
      this.resetDiskon();
    },
    changeBukuSelected(val) {
      console.log(val);
      if (val != null) {
        this.codeBukuTemp = val.id;
        this.judulBukuTemp = val.judul_buku;
        this.hargaBukuTemp = val.harga;
        this.beratBukuTemp = val.berat;
        this.totalBukuTemp = val.total;
        this.dialogAddBuku = true;
      }
    },
    addBuku() {
      // console.log(this.codeBukuTemp);
      // console.log(this.jumlahBukuTemp);
      // console.log(this.diskonBukuTemp);
      var listmenu = this.defaultItem.listOrder;
      var result = false;
      for (let n = 0; n < listmenu.length; n++) {
        if (listmenu[n].codeBarang == this.codeBukuTemp) {
          result = true;
          break;
        }
      }

      if (result == false) {
        var bukuset = {
          berat: "",
          codeBarang: "",
          diskon: "",
          harga: "",
          jumlah: "",
          namaBarang: "",
        };

        bukuset.berat = this.beratBukuTemp;
        bukuset.codeBarang = this.codeBukuTemp;
        bukuset.diskon = this.diskonBukuTemp;
        bukuset.harga = this.hargaBukuTemp;
        bukuset.jumlah = this.jumlahBukuTemp;
        bukuset.namaBarang = this.judulBukuTemp;

        this.defaultItem.listOrder.push(bukuset);

        this.dialogAddBuku = false;
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Buku Sudah Ada Bro..!!!";
      }
    },
    async deletelistitembuku(item) {
      this.editedIndex = await this.defaultItem.listOrder.indexOf(item);
      this.editedItem = await Object.assign({}, item);
      // console.log(this.editedIndex);
      // console.log(this.editedItem);
      this.defaultItem.listOrder.splice(this.editedIndex, 1);
    },
    closeAddBuku() {
      this.dialogAddBuku = false;
    },

    getStatusShow(val) {
      if (val == 0) {
        return "Yes";
      } else {
        return "No";
      }
    },
    getRupiah(val) {
      var bilangan = val;

      if (bilangan) {
        var number_string = bilangan.toString(),
          sisa = number_string.length % 3,
          rupiah = number_string.substr(0, sisa),
          ribuan = number_string.substr(sisa).match(/\d{3}/g);

        if (ribuan) {
          var separator = sisa ? "." : "";
          rupiah += separator + ribuan.join(".");
        }

        return "Rp. " + rupiah;
      }
    },
    gettanggal(val) {
      var bulanIndo = [
        "",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Juni",
        "Juli",
        "Agust",
        "Sept",
        "Okt",
        "Nov",
        "Des",
      ];

      var date = val.split(" ")[0];

      var tanggal = date.split("-")[2];
      var bulan = date.split("-")[1];
      var tahun = date.split("-")[0];

      return tanggal + " " + bulanIndo[Math.abs(bulan)] + " " + tahun;
    },
  },
};
</script>



