<template>
  <div>
    <v-breadcrumbs
      class="breadcrumbsmain fontall"
      :items="itemsbr"
      large
      color="black"
      style="font-weight: bold"
      ><template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template></v-breadcrumbs
    >

    <v-data-table
      :headers="headers"
      :items="dataobject"
      :search="search"
      class="rounded elevation-6 mx-3 pa-1 fontall"
    >
      <template v-slot:item.typeTransaksi="{ item }">
        <v-chip :color="getColorStatus(item.typeTransaksi)" dark>
          {{ item.typeTransaksi }}
        </v-chip>
      </template>

      <template v-slot:item.updated_at="{ item }">
        <tr>
          {{
            gettanggal(item.updated_at)
          }}
        </tr>
        <tr>
          {{
            getjam(item.updated_at)
          }}
        </tr>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <!-- Modal Detail -->
          <v-dialog v-model="dialogDetail" max-width="800px">
            <v-card>
              <v-card-title class="mb-1 headermodalstyle">
                <span>Detail Transaksi</span>
              </v-card-title>

              <v-card-text class="fontall pa-1">
                <v-container class="pa-1">
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 150px; font-size: 14px"
                          >
                            No Invoice
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.NoInvoice }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Customer
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.Customer }} ({{
                              defaultItem.TelpCust
                            }})
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Data Pengirim
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.Pengirim }} ({{
                              defaultItem.NoPengirim
                            }})
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Data Tujuan
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.NamaPenerima }} ({{
                              defaultItem.NoPenerima
                            }})
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Alamat Tujuan
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.Alamat }}
                          </th>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <v-data-table
                    :headers="headersBarang"
                    :items="defaultItem.listOrder"
                    :search="search"
                    class="rounded elevation-1 mx-1 pa-1 my-2"
                    hide-default-footer
                    disable-pagination
                  >
                    <template v-slot:item.subtotal="{ item }">
                      {{ getRupiah(item.subtotal) }}
                    </template>
                  </v-data-table>
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Sub Total
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ getRupiah(defaultItem.SubTotal) }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Diskon
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ getRupiah(defaultItem.Diskon) }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Diskon Kode Unik
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ getRupiah(defaultItem.DiskonKodeUnik) }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Expedisi ({{ defaultItem.Expedisi }})
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ getRupiah(defaultItem.BiayaExpedisi) }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Total Harga
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ getRupiah(defaultItem.TotalHarga) }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Type Transaksi
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.typeTransaksi }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Bank
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.Bank }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Komentar
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.Komentar }}
                          </th>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-container>
                    <v-row justify="center">
                      <div v-if="selectedImage" max-width="85vw">
                        <img
                          :src="selectedImage"
                          alt=""
                          width="100%"
                          @click.stop="selectedImage = null"
                        />
                        <hr />
                      </div>
                      <v-col v-if="selectedImage == null" cols="12" md="12">
                        <v-card
                          class="d-flex align-center"
                          elevation="2"
                          height="450"
                          width="450"
                        >
                          <v-img
                            v-bind:src="defaultItem.Gambar"
                            class="my-2 mx-1 mb-4"
                            max-height="450"
                            contain
                            v-on:click="zoom(defaultItem.Gambar)"
                          ></v-img>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-container>
              </v-card-text>
              <v-card-text v-if="verifikasi == true" class="fontall py-1 px-5">
                <v-row>
                  <v-col class="pb-1" cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="defaultItem.Komentar"
                      label="Komentar"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="mx-4"></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="verifikasi == true"
                  color="red"
                  elevation="1"
                  outlined
                  @click="hold()"
                >
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Hold
                </v-btn>
                <v-btn
                  v-if="verifikasi == true"
                  color="green"
                  elevation="1"
                  outlined
                  @click="verifikasiTrx()"
                >
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  Verifikasi
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-1" @click="detailItem(item)" color="primary">
          mdi-information
        </v-icon>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
      class="fontall"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "TransaksiBaru",
  data: () => ({
    itemsbr: [
      {
        text: "Transaksi",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Transaksi Baru",
        disabled: true,
        href: "breadcrumbs_link_1",
      },
    ],

    formTitle: "Add Item",
    textheader: "",
    value: "add",
    dialog: false,
    dialogDelete: false,
    dialogDetail: false,
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    headers: [
      { text: "No Inv", value: "id", width: "15%" },
      { text: "Pembeli", value: "nama" },
      { text: "Penerima", value: "nama_penerima" },
      { text: "Tipe Trx", value: "typeTransaksi" },
      { text: "Status", value: "status" },
      { text: "Tgl Update", value: "updated_at", width: "13%" },
      //   { text: "User ID", value: "username" },
      { text: "Actions", value: "actions", sortable: false, width: "7%" },
    ],
    headersBarang: [
      { text: "Nama Buku", value: "namaBarang" },
      { text: "Jumlah", value: "jumlah" },
      { text: "Diskon", value: "diskon" },
      { text: "Sub total", value: "subtotal", width: "25%" },
    ],
    headerListBuku: [
      { text: "Nama Buku", value: "namaBarang", width: "35%" },
      { text: "Berat", value: "berat", width: "12%" },
      { text: "Harga", value: "harga", width: "18%" },
      { text: "Diskon", value: "diskon", width: "12%" },
      { text: "Jumlah", value: "jumlah", width: "13%" },
      { text: "Actions", value: "actions", sortable: false, width: "10%" },
    ],
    dataobject: [],

    defaultItem: {
      id: "",
      nama: "",
      nama_penerima: "",

      gambar: "",

      Admin: "",
      Alamat: "",
      BiayaExpedisi: "",
      typeTransaksi: "",
      customerCombobox: "",
      kabROCombobox: "",
      id_alamatkabROCombobox: "",
      textkabROCombobox: "",
      kab_kota_ro_kode: "",
      Customer: "",
      diskonCustomer: "",
      Diskon: "",
      DiskonKodeUnik: "",
      Expedisi: "",
      NamaPenerima: "",
      NoInvoice: "",
      NoPenerima: "",
      NoPengirim: "",
      Pengirim: "",
      SubTotal: "",
      Tanggal: "",
      TelpCust: "",
      TotalHarga: "",
      codeGudang: "",
      itemsTypePromo: "",
      Bank: "",
      Komentar: "",
      Gambar: "",
      listOrder: [],
    },

    itemsStatus: [
      { text: "Reseller", value: "1" },
      { text: "Cust Reguler", value: "0" },
    ],
    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,

    verifikasi: true,
    selectedImage: null,

    diskonReset: "",
    jenisResellerReset: "",
    kodeAdminTrx: "",
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.kodeAdminTrx = this.User.kodeAdminTrx;
    }
    this.initialize();
  },

  methods: {
    async initialize() {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetTransaksiBosman?kodeAdminTrx=" +
            this.kodeAdminTrx +
            "&typetransaksi=TransaksiBaru",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataobject = [];
        }
      }
    },
    async detailData(val) {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetTransaksiBosmanDetail?idOrder=" +
            this.defaultItem.id,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.defaultItem = response.data.data.result;
          if (val == "Edit") {
            this.defaultItem.customerCombobox = {
              idCust: response.data.data.result.idCust,
              nama: response.data.data.result.Customer,
            };
            this.discSelectedCustomer =
              response.data.data.result.diskonCustomer;
            this.nameTextKabROSelected =
              response.data.data.result.kecamatan +
              "," +
              response.data.data.result.kab_kota +
              "," +
              response.data.data.result.propinsi;
            if (response.data.data.result.kab_kota_ro_kode == "-") {
              await this.getKabKotaROSelected();
              this.defaultItem.kabROCombobox = {
                id_alamat: this.defaultItem.id_alamatkabROCombobox,
                text: this.defaultItem.textkabROCombobox,
              };
            } else {
              this.defaultItem.kabROCombobox = {
                id_alamat: response.data.data.result.kab_kota_ro_kode,
                text: this.nameTextKabROSelected,
              };
            }
          }
        } else {
          console.log("Kosong");
          //   this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          //   this.dataobject = [];
        }
      }
    },
    async apiVerifikasi(val) {
      const datapost = {
        status: val,
        kodeAdminTrx: this.kodeAdminTrx,
        no_invoice: this.defaultItem.NoInvoice,
        keterangan: this.defaultItem.Komentar,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "VerifikasiTrxBaru",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogDetail = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses ubah data";
          this.initialize();
        } else {
          console.log("Kosong");
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },

    detailItem(item) {
      this.defaultItem = Object.assign({}, item);
      this.dialogDetail = true;
      this.detailData("Detail");
    },

    close() {
      this.dialogDetail = false;
    },
    verifikasiTrx() {
      console.log("test");
      console.log(this.defaultItem.NoInvoice);
      this.apiVerifikasi("Approve");
    },
    hold() {
      this.apiVerifikasi("Hold");
    },

    zoom(url) {
      // console.log("zooom");
      console.log("Zoom", url);
      this.selectedImage = url;
    },

    getStatus(status) {
      if (status == "0") return "Customer Reg";
      else return "Reseller";
    },
    getColorStatus(status) {
      if (status == "regular") return "green";
      else if (status == "preOrder") return "blue";
      else if (status == "tempo") return "orange";
      else return "red";
    },
    getTotalHarga(a, b, c, d) {
      var result = 0;
      result = parseInt(a) - parseInt(b) + parseInt(c) - parseInt(d);

      return this.getRupiah(result);
      //
    },

    getRupiah(val) {
      var bilangan = val;

      if (bilangan) {
        var number_string = bilangan.toString(),
          sisa = number_string.length % 3,
          rupiah = number_string.substr(0, sisa),
          ribuan = number_string.substr(sisa).match(/\d{3}/g);

        if (ribuan) {
          var separator = sisa ? "." : "";
          rupiah += separator + ribuan.join(".");
        }

        return "Rp. " + rupiah;
      }
    },
    gettanggal(val) {
      var bulanIndo = [
        "",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Juni",
        "Juli",
        "Agust",
        "Sept",
        "Okt",
        "Nov",
        "Des",
      ];

      var date = val.split(" ")[0];

      var tanggal = date.split("-")[2];
      var bulan = date.split("-")[1];
      var tahun = date.split("-")[0];

      return tanggal + " " + bulanIndo[Math.abs(bulan)] + " " + tahun;
    },
    getjam(val) {
      var time = val.split(" ")[1];

      var jam = time.split(":")[0];
      var menit = time.split(":")[1];

      return jam + ":" + menit;
    },
  },
};
</script>



