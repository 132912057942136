<template>
  <div>
    <v-breadcrumbs
      class="breadcrumbsmain fontall"
      :items="itemsbr"
      large
      color="black"
      style="font-weight: bold"
      ><template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template></v-breadcrumbs
    >

    <v-data-table
      :headers="headers"
      :items="dataobject"
      :search="search"
      class="rounded elevation-6 mx-3 pa-1 fontall"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn dark class="mb-2" @click="showAddModal()" color="green">
            <v-icon small>mdi-plus</v-icon> Add Item
          </v-btn>
          <v-dialog v-model="dialog" max-width="600px">
            <v-card>
              <v-card-title class="headermodalstyle">
                <span>{{ formTitle }}</span>
              </v-card-title>
              <v-card-text class="pa-0 fontall">
                <v-container>
                  <v-row>
                    <v-col class="pb-1 pt-0" cols="12" sm="3" md="3">
                      <v-text-field
                        v-model="defaultItem.id"
                        label="Id Buku"
                        outlined
                        :disabled="disableid == true"
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-1 pt-0" cols="12" sm="9" md="9">
                      <v-text-field
                        v-model="defaultItem.judul_buku"
                        label="Nama Buku"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-1 pt-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.barcode"
                        label="Barcode"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-1 pt-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.isbn"
                        label="Isbn"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-1 pt-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.harga"
                        label="Harga"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-1 pt-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.hpp"
                        label="Hpp"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-1 pt-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.berat"
                        label="Berat"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-1 pt-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.cover"
                        label="Cover"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-1 pt-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.halaman"
                        label="Halaman"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-1 pt-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.jenis_kertas"
                        label="Jenis Kertas"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-1 pt-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.penulis"
                        label="Penulis"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-1 pt-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.status"
                        label="Status"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-1 pt-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.tahun"
                        label="Tahun"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-1 pt-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.ukuran"
                        label="ukuran"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-1 pt-0" cols="12" sm="6" md="6">
                      <v-select
                        v-model="defaultItem.store"
                        :items="itemsStore"
                        item-value="value"
                        item-text="text"
                        label="Tampilkan di Store"
                        clearable
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn color="success" elevation="1" outlined @click="save">
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDetail" max-width="600px">
            <v-card>
              <v-card-title
                class="headermodalstyle"
                style="margin-bottom: -2px !important"
              >
                <span>Detail Item</span>
              </v-card-title>
              <v-card-text class="pa-0 fontall">
                <v-container>
                  <v-divider
                    style="background-color: black !important"
                  ></v-divider>
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 200px; font-size: 14px"
                          >
                            ID Buku
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.id }}
                          </th>
                        </tr>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 200px; font-size: 14px"
                          >
                            Nama Buku
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.judul_buku }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Barcode
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.barcode }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Berat
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.berat }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Cover
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.cover }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Halaman
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.halaman }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Harga
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.harga }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">Hpp</th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.hpp }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Isbn
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.isbn }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Jenis Kertas
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.jenis_kertas }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Penulis
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.penulis }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Status
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.status }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Tahun
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.tahun }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Ukuran
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.ukuran }}
                          </th>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Tampilkan di Store
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ defaultItem.store }}
                          </th>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <v-divider
                    style="background-color: black !important"
                  ></v-divider>

                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 200px; font-size: 14px"
                          >
                            Penerbit
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ penerbitText }}
                          </th>
                        </tr>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 200px; font-size: 14px"
                          >
                            Kategori
                          </th>
                          <th class="text-left" style="font-size: 14px">
                            {{ kategoriText }}
                          </th>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-divider
                    style="background-color: black !important"
                  ></v-divider>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card class="fontall">
              <v-card-title class="headline"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="closeDelete">
                  <v-icon left> mdi-close-circle-outline </v-icon> Cancel</v-btn
                >
                <v-btn
                  color="success"
                  elevation="1"
                  outlined
                  @click="deleteItemConfirm"
                >
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-1" @click="detailItem(item)" color="primary">
          mdi-information-outline
        </v-icon>
        <v-icon class="mr-1" @click="editItem(item)" color="warning">
          mdi-pencil
        </v-icon>
        <v-icon @click="deleteItem(item)" color="red"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
      class="fontall"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DataBuku",
  data: () => ({
    itemsbr: [
      {
        text: "Main Data",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Data Buku",
        disabled: true,
        href: "breadcrumbs_link_1",
      },
    ],

    formTitle: "Add Item",
    value: "add",
    disableid: false,
    dialog: false,
    dialogDelete: false,
    dialogReset: false,
    dialogDetail: false,
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    headers: [
      { text: "Kode", value: "id" },
      { text: "Nama Buku", value: "judul_buku", width: "35%" },
      { text: "Barcode", value: "barcode" },
      { text: "Harga", value: "harga" },
      { text: "berat", value: "berat" },
      { text: "store", value: "store" },
      //   { text: "User ID", value: "username" },
      { text: "Actions", value: "actions", sortable: false, width: "15%" },
    ],
    headerskategori: [
      { text: "Id", value: "id_kategori" },
      { text: "Nama Kategori", value: "nama_kategori" },
    ],
    dataobject: [],

    defaultItem: {
      barcode: "",
      berat: "",
      cover: "",
      diskripsi: "",
      gambar_buku: "",
      halaman: "",
      harga: "",
      hpp: "",
      id: "",
      isbn: "",
      jenis_kertas: "",
      jenis_pre_order_buku: "",
      judul_buku: "",
      kategori: "",
      penerbit: "",
      penulis: "",
      status: "",
      store: "",
      tahun: "",
      ukuran: "",
    },
    penerbitText: "",
    kategoriText: "",
    inprint: { id_inprint: "", nama_inprint: "" },
    kategori: [],

    itemsStore: [
      { text: "Tampilkan", value: "Y" },
      { text: "Tidak Tampilkan", value: "N" },
    ],
    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,

    diskonReset: "",
    jenisResellerReset: "",
    kodeAdminTrx: "",
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.kodeAdminTrx = this.User.kodeAdminTrx;
    }
    this.initialize();
  },

  methods: {
    async initialize() {
      try {
        const response = await axios.get(this.BaseUrlGet + "GetAllBukuBosman", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataobject = [];
        }
      }
    },
    async getDetail() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetBukuDetailBosman?id=" + this.defaultItem.id,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.defaultItem = response.data.data.result.data;
          this.inprint = response.data.data.result.inprint;
          this.kategori = response.data.data.result.kategori;
          this.penerbitText = response.data.data.result.penerbitText;
          this.kategoriText = response.data.data.result.kategoriText;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.dataobject = [];
        }
      }
    },
    async verifDelete() {
      const datapost = {
        id: this.defaultItem.id,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "DeleteBukuBosman",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result.data);
        if (response.data.data.result == "success") {
          this.dialogDelete = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses hapus data";
          this.initialize();
        } else {
          this.dialogDelete = false;
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },
    async addData() {
      const datapost = {
        id: this.defaultItem.id,
        barcode: this.defaultItem.barcode,
        berat: this.defaultItem.berat,
        kategori: "-",
        status: this.defaultItem.status,
        judul_buku: this.defaultItem.judul_buku,
        harga: this.defaultItem.harga,
        hpp: this.defaultItem.hpp,
        halaman: this.defaultItem.halaman,
        ukuran: this.defaultItem.ukuran,
        cover: this.defaultItem.cover,
        penulis: this.defaultItem.penulis,
        isbn: this.defaultItem.isbn,
        tahun: this.defaultItem.tahun,
        jenis_kertas: this.defaultItem.jenis_kertas,
        diskripsi: "-",
        store: this.defaultItem.store,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "AddBukuBosman",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async updateData() {
      const datapost = {
        id: this.defaultItem.id,
        barcode: this.defaultItem.barcode,
        berat: this.defaultItem.berat,
        kategori: "-",
        status: this.defaultItem.status,
        judul_buku: this.defaultItem.judul_buku,
        harga: this.defaultItem.harga,
        hpp: this.defaultItem.hpp,
        halaman: this.defaultItem.halaman,
        ukuran: this.defaultItem.ukuran,
        cover: this.defaultItem.cover,
        penulis: this.defaultItem.penulis,
        isbn: this.defaultItem.isbn,
        tahun: this.defaultItem.tahun,
        jenis_kertas: this.defaultItem.jenis_kertas,
        diskripsi: this.defaultItem.diskripsi,
        store: this.defaultItem.store,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "EditBukuBosman",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses ubah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          this.dialog = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },

    showResetModal() {
      this.dialogReset = true;
    },
    showAddModal() {
      this.value = "add";
      this.disableid = false;
      this.formTitle = "Add Item";
      this.defaultItem.barcode = "";
      this.defaultItem.berat = "";
      this.defaultItem.cover = "";
      this.defaultItem.diskripsi = "";
      this.defaultItem.halaman = "";
      this.defaultItem.harga = "";
      this.defaultItem.hpp = "";
      this.defaultItem.id = "";
      this.defaultItem.isbn = "";
      this.defaultItem.jenis_kertas = "";
      this.defaultItem.judul_buku = "";
      this.defaultItem.penulis = "";
      this.defaultItem.status = "";
      this.defaultItem.store = "";
      this.defaultItem.tahun = "";
      this.defaultItem.ukuran = "";
      this.dialog = true;
    },
    detailItem(item) {
      console.log(item);
      this.defaultItem = Object.assign({}, item);
      this.getDetail();
      this.dialogDetail = true;
    },
    editItem(item) {
      console.log(item);
      this.formTitle = "Edit Item";
      this.defaultItem = Object.assign({}, item);
      this.value = "edit";
      this.disableid = true;
      this.getDetail();
      this.dialog = true;
    },
    deleteItem(item) {
      this.defaultItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();
      this.verifDelete();
    },
    close() {
      this.dialog = false;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    save() {
      if (
        this.defaultItem.barcode.length != 0 &&
        this.defaultItem.berat.length != 0 &&
        this.defaultItem.cover.length != 0 &&
        this.defaultItem.halaman.length != 0 &&
        this.defaultItem.harga.length != 0 &&
        this.defaultItem.hpp.length != 0 &&
        this.defaultItem.id.length != 0 &&
        this.defaultItem.isbn.length != 0 &&
        this.defaultItem.jenis_kertas.length != 0 &&
        this.defaultItem.judul_buku.length != 0 &&
        this.defaultItem.penulis.length != 0 &&
        this.defaultItem.status.length != 0 &&
        this.defaultItem.tahun.length != 0 &&
        this.defaultItem.ukuran.length != 0 &&
        this.defaultItem.store.length != 0
      ) {
        if (this.value == "edit") {
          console.log("edit");
          this.updateData();
        } else {
          console.log("add");
          this.addData();
        }
        this.close();
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Gagal Menambah, Kolom tidak boleh ada yang kosong";
      }
    },
    reset() {
      console.log(this.jenisResellerReset);
      console.log(this.diskonReset);
      this.resetDiskon();
    },

    getStatus(status) {
      if (status == "0") return "Customer Reg";
      else return "Reseller";
    },
    getColorStatus(status) {
      if (status == "0") return "orange";
      else return "green";
    },
  },
};
</script>



