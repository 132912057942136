<template>
  <div>
    <v-breadcrumbs
      class="breadcrumbsmain"
      :items="itemsbr"
      divider=">"
      large
    ></v-breadcrumbs>

    <v-data-table
      :headers="headers"
      :items="dataobject"
      :search="search"
      class="rounded elevation-6 mx-3 pa-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-select
            v-model="selectGudang"
            :items="itemsGudang"
            item-value="codeGudang"
            item-text="namaGudang"
            v-on:change="selectedGudang"
            label="Pilih Gudang"
            clearable
            class="mx-3 mt-7"
            style="max-width: 225px"
          ></v-select>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="d-none d-md-block"
          ></v-text-field>
          
          
          
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DaftarStock",
  data: () => ({
    itemsbr: [
      {
        text: "Persediaan",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Daftar Stock",
        disabled: true,
        href: "breadcrumbs_link_1",
      },
    ],
    
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    headers: [
      {
        text: "Kode Buku",
        value: "code_barang",
      },
      { text: "Nama Buku", value: "judul_buku" },
      { text: "Stock", value: "stock" },
    //   { text: "Actions", value: "actions", sortable: false },
    ],
    dataobject: [],

    itemsGudang: [],
    gudang:"Gd_001",
    selectGudang: "",
    defaultItem: { id: "", namaKlasifikasi: "" },
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.initialize();
    this.getGudang();
  },

  methods: {
    async initialize() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetStockGudang?codeGudang="+this.gudang,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataobject = [];
        }
      }
    },

    async getGudang() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "DropdownGudangStock",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsGudang = response.data.data.result;
        } else {
          console.log("Kosong");
          this.itemsGudang = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsGudang = [];
        }
      }
    },

    selectedGudang(val){
        console.log(val);
        if(val != null){
          this.gudang = val;
        }else{
            this.gudang = 'Gd_001';
        }
        this.initialize();
    }

  },
};
</script>