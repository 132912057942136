<template>
  <div>
    <v-breadcrumbs
      class="breadcrumbsmain fontall"
      :items="itemsbr"
      large
      color="black"
      style="font-weight: bold"
      ><template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template></v-breadcrumbs
    >

    <v-data-table
      :headers="headers"
      :items="dataobject"
      :search="search"
      class="rounded elevation-6 mx-3 pa-1 fontall"
    >
      <!-- <template v-slot:item.jenis_reseller="{ item }">
        <v-chip :color="getColorStatus(item.jenis_reseller)" dark>
          {{ getStatus(item.jenis_reseller) }}
        </v-chip>
      </template> -->

      <template v-slot:item.job_position="{ item }">
        <tr>
          {{
            item.job_position
          }}
          <h4 style="font-weight: bold">({{ item.status_jabatan }})</h4>
        </tr>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn dark class="mb-2" @click="showAddModal()" color="green">
            <v-icon small>mdi-plus</v-icon> Add Item
          </v-btn>

          <v-dialog v-model="dialog" max-width="600px">
            <v-card>
              <v-card-title class="headermodalstyle">
                <span>{{ formTitle }}</span>
              </v-card-title>
              <v-card-text class="pa-0 fontall">
                <v-container>
                  <v-row>
                    <v-col class="pb-1" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.nama"
                        label="Nama Admin"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-1" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.no_telp"
                        label="No Telephone"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-1" cols="12" sm="6" md="6">
                      <v-select
                        v-model="defaultItem.code_jabatan"
                        :items="itemsJabatan"
                        item-value="value"
                        item-text="text"
                        label="Job Position"
                        clearable
                        outlined
                        v-on:change="changecodejabatan"
                      ></v-select>
                    </v-col>
                    <v-col class="pb-1" cols="12" sm="6" md="6">
                      <v-select
                        v-model="defaultItem.status_jabatan"
                        :items="itemsStatusJabatan"
                        item-value="value"
                        item-text="text"
                        label="Status Job"
                        clearable
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col class="pb-1" cols="12" sm="4" md="4">
                      <v-select
                        v-model="defaultItem.code_perusahaan"
                        :items="itemsLocation"
                        item-value="value"
                        item-text="text"
                        label="Job Location"
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col class="pb-1" cols="12" sm="8" md="8">
                      <v-text-field
                        v-model="defaultItem.kodeAdminTrx"
                        label="Kode Admin Trx"
                        outlined
                        :hint="hinttext"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn color="success" elevation="1" outlined @click="save">
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-1" @click="editItem(item)" color="warning">
          mdi-pencil
        </v-icon>
        <!-- <v-icon class="mr-1" @click="deleteItem(item)" color="red">
          mdi-delete
        </v-icon> -->
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
      class="fontall"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Admin",
  data: () => ({
    itemsbr: [
      {
        text: "Main Data",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Admin",
        disabled: true,
        href: "breadcrumbs_link_1",
      },
    ],

    formTitle: "Add Item",
    value: "add",
    dialog: false,
    dialogDelete: false,
    dialogReset: false,
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    headers: [
      {
        text: "Id",
        value: "id",
      },
      { text: "Nama", value: "nama" },
      { text: "kodeAdminTrx", value: "kodeAdminTrx" },
      { text: "job_position", value: "job_position" },
      { text: "Lokasi", value: "location" },
      //   { text: "User ID", value: "username" },
      { text: "Actions", value: "actions", sortable: false, width: "15%" },
    ],
    itemsJabatan: [
      { text: "Marketing", value: "2" },
      { text: "Fincon", value: "3" },
      { text: "Warehouse", value: "4" },
    ],
    itemsStatusJabatan: [
      { text: "Manager", value: "Manager" },
      { text: "Staff", value: "Staff" },
    ],
    itemsLocation: [
      { text: "Aqw Solo", value: "1" },
      { text: "Aqw Jakarta", value: "2" },
    ],
    dataobject: [],

    defaultItem: {
      id: "",
      code_perusahaan: "",
      username: "",
      nama: "",
      no_telp: "",
      code_jabatan: "",
      status_jabatan: "",
      kodeAdminTrx: "",
    },

    diskonReset: "",
    jenisResellerReset: "",
    kodeAdminTrx: "",
    hinttext: "contoh: xxxx",

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.kodeAdminTrx = this.User.kodeAdminTrx;
    }
    this.initialize();
  },

  methods: {
    async initialize() {
      try {
        const response = await axios.get(this.BaseUrlGet + "GetAllAdmin", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataobject = [];
        }
      }
    },
    async getAdminJabatan(val) {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetAllAdminbyJabatan?code_jabatan=" + val,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          var texthint = "";
          for (let i = 0; i < 3; i++) {
            texthint += response.data.data.result.data[i].kodeAdminTrx + ", ";
          }
          this.hinttext = "contoh: " + texthint + " dll..";
          // this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.dataobject = [];
        }
      }
    },
    async addData() {
      const datapost = {
        code_perusahaan: this.defaultItem.code_perusahaan,
        username: this.defaultItem.nama,
        nama: this.defaultItem.nama,
        no_telp: this.defaultItem.no_telp,
        code_jabatan: this.defaultItem.code_jabatan,
        status_jabatan: this.defaultItem.status_jabatan,
        kodeAdminTrx: this.defaultItem.kodeAdminTrx,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "AddAdmin",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async updateData() {
      const datapost = {
        id: this.defaultItem.id,
        code_perusahaan: this.defaultItem.code_perusahaan,
        username: this.defaultItem.nama,
        nama: this.defaultItem.nama,
        no_telp: this.defaultItem.no_telp,
        code_jabatan: this.defaultItem.code_jabatan,
        status_jabatan: this.defaultItem.status_jabatan,
        kodeAdminTrx: this.defaultItem.kodeAdminTrx,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "EditAdmin",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses ubah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          this.dialog = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },

    showAddModal() {
      this.value = "add";
      this.formTitle = "Add Item";
      this.defaultItem.id = "";
      this.defaultItem.nama = "";
      this.defaultItem.code_perusahaan = "";
      this.defaultItem.username = "";
      this.defaultItem.no_telp = "";
      this.defaultItem.code_jabatan = "";
      this.defaultItem.status_jabatan = "";
      this.defaultItem.kodeAdminTrx = "";
      this.dialog = true;
    },
    editItem(item) {
      console.log(item);
      this.formTitle = "Edit Item";
      this.defaultItem = Object.assign({}, item);
      this.value = "edit";
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },

    save() {
      if (
        this.defaultItem.nama.length != 0 &&
        this.defaultItem.code_perusahaan != null &&
        this.defaultItem.code_jabatan != null &&
        this.defaultItem.status_jabatan != null &&
        this.defaultItem.no_telp.length != 0 &&
        this.defaultItem.kodeAdminTrx.length != 0
      ) {
        if (this.defaultItem.id) {
          console.log("edit");
          this.updateData();
        } else {
          console.log("add");
          this.addData();
        }
        this.close();
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Gagal Menambah, Kolom tidak boleh ada yang kosong";
      }
    },

    changecodejabatan(val) {
      console.log(val);
      this.getAdminJabatan(val);
    },
  },
};
</script>



